import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '12',
  height: '12',
  fill: '#758F9A',
  viewBox: '0 0 12 12',
  focusable: false,
};

const PinDrop: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C3.82871 0 2.06149 1.73002 2.00157 3.88682C2.00073 3.91702 2.00023 3.9473 2.00006 3.97767L2 4C2 4.48632 2.08679 4.95236 2.24572 5.3835C2.80036 7.21996 4.20126 9.64883 5.48464 11.3264L6 12L6.51536 11.3264C7.79874 9.64883 9.19964 7.21996 9.75428 5.3835C9.91321 4.95236 10 4.48632 10 4C10 3.98274 9.99989 3.96551 9.99967 3.9483C9.99941 3.92777 9.999 3.90727 9.99843 3.88682C9.93851 1.73002 8.17129 0 6 0ZM6 5C6.55228 5 7 4.55228 7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5Z"
      />
    </svg>
  );
};

export default PinDrop;
