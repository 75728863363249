import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '14',
  height: '14',
  fill: 'none',
  viewBox: '0 0 14 14',
  focusable: false,
};

const CrossReset: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.4387 0.43934C12.0245 -0.146447 12.9742 -0.146447 13.56 0.43934C14.1458 1.02513 14.1458 1.97487 13.56 2.56066L9.12066 7L13.5593 11.4387C14.1451 12.0245 14.1451 12.9742 13.5593 13.56C12.9735 14.1458 12.0238 14.1458 11.438 13.56L6.99934 9.12132L2.56132 13.5593C1.97553 14.1451 1.02579 14.1451 0.440001 13.5593C-0.145787 12.9735 -0.145787 12.0238 0.440001 11.438L4.87802 7L0.439341 2.56132C-0.146447 1.97554 -0.146447 1.02579 0.43934 0.440002C1.02513 -0.145785 1.97487 -0.145785 2.56066 0.440002L6.99934 4.87868L11.4387 0.43934Z" />
    </svg>
  );
};

export default CrossReset;
