import React from 'react';
import Cross from '../Icons/Cross';
import intl from 'react-intl-universal';

interface Props {
  open: boolean;
  toggleOpen: () => void;
  clearValue: () => void;
  label: string;
  value: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

const DropDownButtonWithValue: React.FC<Props> = (props: Props) => {
  return (
    <div className="dropdown-switch-with-value">
      <button
        className="btn switch btn-darker btn-with-value"
        aria-haspopup="true"
        aria-expanded={props.open ? 'true' : 'false'}
        onClick={props.toggleOpen}
        onKeyDown={props.onKeyDown}
      >
        <span className="sr-only">{intl.get('plp.filter.select.value').d('Select value')}</span>
        <label>{props.label}:</label>
        <span>{props.value}</span>
      </button>
      <button className="btn btn-darker btn-clear" onClick={props.clearValue}>
        <span className="sr-only">{`${props.label}: ${intl.get('plp.filter.clear.value').d('Clear value')} ${
          props.value
        }`}</span>
        <Cross width="9" height="8" fill="#fff" />
      </button>
    </div>
  );
};

export default DropDownButtonWithValue;
