import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '18',
  height: '18',
  fill: 'none',
  viewBox: '0 0 18 18',
  focusable: false,
};

const SearchIcon: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7.5 3C5.01472 3 3 5.01472 3 7.5C3 9.98528 5.01472 12 7.5 12C9.98528 12 12 9.98528 12 7.5C12 5.01472 9.98528 3 7.5 3ZM13.441 13.4377C14.0275 12.8539 14.9764 12.855 15.5615 13.4402L17.5615 15.4402C18.1479 16.0266 18.1468 16.9773 17.559 17.5623C16.9725 18.1461 16.0236 18.1449 15.4385 17.5598L13.4385 15.5598C12.8521 14.9734 12.8532 14.0227 13.441 13.4377Z"
      />
    </svg>
  );
};

export default SearchIcon;
