import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '330',
  height: '198',
  fill: 'none',
  viewBox: '0 0 330 198',
  focusable: false,
};

const NotFound: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.855 114.313H118.702V130.309H103.855V156.887H83.9211V130.309H32.3235L31.7493 118.168L83.2649 37.4497H103.855V114.313ZM52.6672 114.313H83.9211V64.438L82.4446 67.063L52.6672 114.313Z"
        fill="#0B3653"
      />
      <path
        d="M283.403 114.313H298.251V130.309H283.403V156.887H263.47V130.309H211.872L211.298 118.168L262.813 37.4497H283.403V114.313ZM232.216 114.313H263.47V64.438L261.993 67.063L232.216 114.313Z"
        fill="#0B3653"
      />
      <g filter="url(#filter0_d)">
        <path
          d="M163.604 162.168C198.008 165.074 228.317 146.405 235.913 119.264C236.196 119.32 236.485 119.36 236.779 119.385C241.467 119.781 245.618 115.956 246.05 110.842C246.482 105.728 243.032 101.26 238.343 100.864C238.05 100.84 237.758 100.831 237.469 100.839C234.534 72.8076 207.787 49.3189 173.383 46.4126C140.994 43.6764 112.234 60.0626 102.639 84.6386C102.077 84.4569 101.488 84.3365 100.879 84.285C96.1904 83.889 92.0396 87.7139 91.6075 92.8283C91.1774 97.9197 94.595 102.37 99.2511 102.8C99.3634 132.967 127.197 159.092 163.604 162.168Z"
          fill="url(#paint0_linear)"
        />
        <ellipse
          opacity="0.52"
          cx="151.475"
          cy="84.3895"
          rx="1.22276"
          ry="0.833641"
          transform="rotate(4.82877 151.475 84.3895)"
          fill="#A5BAC2"
        />
        <ellipse
          opacity="0.52"
          cx="156.457"
          cy="82.9364"
          rx="2.68747"
          ry="1.86742"
          transform="rotate(4.82877 156.457 82.9364)"
          fill="#A5BAC2"
        />
        <ellipse
          opacity="0.52"
          cx="173.317"
          cy="81.6677"
          rx="1.55481"
          ry="0.952574"
          transform="rotate(4.82877 173.317 81.6677)"
          fill="#A5BAC2"
        />
        <ellipse
          opacity="0.52"
          cx="175.561"
          cy="85.9055"
          rx="1.74267"
          ry="1.35052"
          transform="rotate(4.82877 175.561 85.9055)"
          fill="#A5BAC2"
        />
        <ellipse
          opacity="0.52"
          cx="163.862"
          cy="88.2107"
          rx="3.17415"
          ry="2.68192"
          transform="rotate(4.82877 163.862 88.2107)"
          fill="#A5BAC2"
        />
        <ellipse
          opacity="0.52"
          cx="154.152"
          cy="86.993"
          rx="0.97438"
          ry="0.656988"
          transform="rotate(4.82877 154.152 86.993)"
          fill="#A5BAC2"
        />
        <ellipse
          opacity="0.52"
          cx="171.798"
          cy="90.9132"
          rx="0.97438"
          ry="0.656988"
          transform="rotate(4.82877 171.798 90.9132)"
          fill="#A5BAC2"
        />
        <ellipse
          opacity="0.52"
          cx="172.714"
          cy="87.3205"
          rx="0.97438"
          ry="0.656988"
          transform="rotate(4.82877 172.714 87.3205)"
          fill="#A5BAC2"
        />
        <ellipse
          opacity="0.52"
          cx="181.894"
          cy="85.7446"
          rx="0.97438"
          ry="0.656988"
          transform="rotate(4.82877 181.894 85.7446)"
          fill="#A5BAC2"
        />
        <ellipse
          opacity="0.52"
          cx="165.504"
          cy="82.4859"
          rx="0.97438"
          ry="0.656988"
          transform="rotate(4.82877 165.504 82.4859)"
          fill="#A5BAC2"
        />
        <path
          d="M195.77 135.299C182.449 160.057 187.419 126.739 170.512 125.31C153.604 123.882 138.049 150.086 134.543 130.126C130.711 108.312 151.423 104.843 168.33 106.271C185.238 107.7 199.878 121.095 195.77 135.299Z"
          fill="#758F9A"
        />
        <path
          d="M150.963 109.487C150.664 115.147 157.486 120.107 166.198 120.566C174.911 121.026 182.216 116.81 182.515 111.15C182.523 110.995 182.526 110.841 182.523 110.687C178.293 108.391 173.407 106.876 168.315 106.445C162.8 105.98 156.879 106.035 151.538 107.078C151.205 107.842 151.007 108.649 150.963 109.487Z"
          fill="#3C4952"
        />
        <path
          d="M180.205 109.334L178.574 113.506C178.373 114.02 177.793 114.274 177.279 114.073L171.946 111.989C171.432 111.788 171.178 111.208 171.379 110.693L172.811 107.028C175.385 107.54 177.87 108.323 180.205 109.334Z"
          fill="#ECF2F5"
        />
        <ellipse
          cx="205.872"
          cy="107.601"
          rx="32.9293"
          ry="26.1886"
          transform="rotate(4.82877 205.872 107.601)"
          fill="url(#paint1_radial)"
        />
        <ellipse
          cx="128.408"
          cy="101.566"
          rx="32.085"
          ry="22.7379"
          transform="rotate(-0.0488526 128.408 101.566)"
          fill="url(#paint2_radial)"
        />
        <g filter="url(#filter1_d)">
          <path
            d="M204.042 155.139C208.542 152.947 212.716 150.326 216.483 147.337C216.343 146.926 216.195 146.548 216.042 146.209C215.568 145.158 215.02 144.223 214.509 143.353C213.128 140.999 212.025 139.12 213.444 136.699C213.62 136.399 213.784 136.124 213.937 135.867C215.436 133.358 215.914 132.557 215.727 127.689C215.594 124.213 216.532 123.03 217.419 121.912C217.902 121.303 218.37 120.714 218.641 119.784C219.01 118.523 218.353 116.29 217.612 113.771C216.803 111.018 215.893 107.923 216.109 105.379C216.814 97.0898 212.524 92.2594 210.216 89.6603C210.17 89.6089 210.125 89.5584 210.081 89.5087L189.732 82.7995C188.085 85.0204 190.904 88.2293 194.365 92.1681C198.839 97.2587 204.383 103.569 202.74 110.541C202.15 113.047 202.873 116.119 203.603 119.22C204.526 123.143 205.46 127.111 203.758 130.035C200.709 135.27 200.71 136.55 201.353 142.257C201.593 144.387 202.326 145.978 203.017 147.479C204.053 149.731 204.997 151.779 204.042 155.139Z"
            fill="#3399FF"
          />
        </g>
        <g filter="url(#filter2_d)">
          <path
            d="M185.754 152.97C188.219 147.929 185.987 142.851 187.616 142.851C189.479 142.56 188.315 147.25 188.482 149.844C188.63 152.154 191.575 155.065 188.881 156.495C187.171 157.403 184.796 154.931 185.754 152.97Z"
            fill="#3399FF"
          />
        </g>
        <g filter="url(#filter3_d)">
          <path
            d="M117.611 142.452C113.81 138.896 110.509 134.971 107.795 130.761C107.984 130.502 108.172 130.263 108.357 130.046C109.114 129.155 109.912 128.399 110.655 127.694C112.665 125.788 114.269 124.267 113.557 121.515C113.2 120.138 112.352 119.173 111.523 118.23C110.148 116.666 108.825 115.161 109.886 111.938C110.682 109.52 111.462 108.149 112.065 107.09C112.75 105.886 113.206 105.084 113.197 103.603C113.19 102.571 113.413 101.699 113.654 100.762C114.061 99.1732 114.516 97.396 113.98 94.3284C113.298 90.4271 120.975 82.7333 123.913 80.8743L143.815 79.3827C144.759 81.8754 141.929 84.1993 138.58 86.9489C133.858 90.8266 128.105 95.5509 130.452 102.789C131.365 105.602 129.589 107.854 127.78 110.148C125.977 112.434 124.141 114.763 124.9 117.73C126.421 123.675 126.062 124.92 123.838 130.296C123.008 132.302 121.846 133.646 120.751 134.914C119.051 136.881 117.509 138.664 117.611 142.452Z"
            fill="#3399FF"
          />
        </g>
        <path
          d="M160.426 51.6736C157.936 51.2104 147.791 50.0366 145.599 46.0583C143.845 42.8756 144.23 38.8417 144.455 37.1748C145.074 38.8287 146.847 42.384 148.989 43.3741C151.13 44.3642 152.894 44.0412 153.508 43.7559C150.846 41.3394 147.472 39.0295 146.483 35.5961C144.607 29.083 147.653 26.2545 150.58 24.5427C149.672 25.3089 147.742 32.2308 151.717 33.4802C156.973 35.1322 158.811 36.8867 159.445 37.8107C158.493 35.6789 158.533 33.5417 159.833 31.8514C160.685 30.7444 163.463 29.3841 165.451 28.7935C163.775 30.6749 165.033 33.8177 166.739 35.1756C168.872 36.8731 173.617 39.5274 172.699 45.3771C172.004 49.806 167.669 52.2855 164.443 52.013L160.426 51.6736Z"
          fill="#FFB127"
        />
        <ellipse
          cx="132.911"
          cy="79.5495"
          rx="18.3596"
          ry="4.17194"
          transform="rotate(-4.63292 132.911 79.5495)"
          fill="url(#paint3_radial)"
        />
        <ellipse
          rx="18.3596"
          ry="4.17194"
          transform="matrix(-0.969057 -0.246838 -0.246838 0.969057 196.656 84.9346)"
          fill="url(#paint4_radial)"
        />
        <path
          d="M154.014 138.126C153.134 141.411 143.979 156.225 160.645 158.913C174.443 163.572 180.014 147.683 180.894 144.398C181.774 141.112 176.234 133.126 170.088 131.479C163.941 129.832 154.895 134.84 154.014 138.126Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M185.332 86.0408C187.346 88.0543 195.625 99.5189 199.949 93.1625C205.12 89.0909 195.586 78.7501 193.573 76.7365C191.559 74.723 185.483 73.365 183.46 75.3884C181.436 77.4119 183.319 84.0273 185.332 86.0408Z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M148.046 85.1623C145.329 86.8981 133.322 97.4619 130.294 89.7008C125.745 84.2163 138.82 75.1293 141.537 73.3935C144.253 71.6577 151.212 71.4948 152.878 74.1037C154.545 76.7125 150.762 83.4265 148.046 85.1623Z"
          fill="url(#paint7_linear)"
        />
        <path d="M119.967 80.6611L147.236 78.6181" stroke="#3C4952" stroke-linecap="round" />
        <path d="M187.306 81.6145L213.279 90.1693" stroke="#3C4952" stroke-linecap="round" />
      </g>
      <ellipse cx="170.917" cy="178.8" rx="33.1152" ry="4.15762" fill="#3399FF" />
      <ellipse cx="144.797" cy="176.117" rx="11.881" ry="1.4747" fill="#3399FF" />
      <ellipse cx="155.796" cy="185.249" rx="6.81314" ry="0.916267" fill="#3399FF" />
      <ellipse cx="187.306" cy="171.681" rx="2.30982" ry="0.514435" fill="#3399FF" />
      <ellipse cx="219.465" cy="180.086" rx="5.19199" ry="0.514435" fill="#3399FF" />
      <defs>
        <filter
          id="filter0_d"
          x="80.3015"
          y="18.116"
          width="184.638"
          height="164.604"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0431373 0 0 0 0 0.211765 0 0 0 0 0.32549 0 0 0 0.53 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          x="175.326"
          y="82.7531"
          width="55.3854"
          height="78.4524"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter2_d"
          x="181.92"
          y="141.65"
          width="12.7716"
          height="18.5554"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter3_d"
          x="105.884"
          y="76.7253"
          width="41.2063"
          height="71.5005"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="104.736"
          y1="91.0741"
          x2="212.403"
          y2="160.019"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.250959" stop-color="#ECF2F5" />
          <stop offset="1" stop-color="#C8D4D9" />
        </linearGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(205.872 107.601) rotate(90) scale(26.1886 32.9293)"
        >
          <stop stop-color="#E64500" stop-opacity="0.37" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(128.408 101.566) rotate(90) scale(22.7379 32.085)"
        >
          <stop stop-color="#E64500" stop-opacity="0.37" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(132.911 79.5495) rotate(90) scale(4.17194 18.3596)"
        >
          <stop stop-color="#566B75" stop-opacity="0.81" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.3596 4.17194) rotate(90) scale(4.17194 18.3596)"
        >
          <stop stop-color="#566B75" stop-opacity="0.81" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <linearGradient
          id="paint5_linear"
          x1="175.198"
          y1="119.797"
          x2="165.006"
          y2="147.51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#566B75" stop-opacity="0.81" />
          <stop offset="1" stop-color="#ECF2F5" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="176.606"
          y1="67.318"
          x2="190.783"
          y2="87.2652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#566B75" stop-opacity="0.81" />
          <stop offset="1" stop-color="#ECF2F5" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="162.446"
          y1="66.8866"
          x2="142.082"
          y2="85.6193"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#566B75" stop-opacity="0.81" />
          <stop offset="1" stop-color="#ECF2F5" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NotFound;
