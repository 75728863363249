import React, {useRef, useState} from 'react';
import {useObserver} from 'mobx-react-lite';
import useCloseMenuEffect from '../../hooks/useCloseMenuEffect';
import SelectBase, {Option} from './SelectBase';
import DropDownButton from '../DropDownButton/DropDownButton';
import {v4 as uuidv4} from 'uuid';
import ModalDialog from '../ModalDialog/ModalDialog';
import './css/single-select.scss';

export interface SingleSelectProps {
  buttonLabel: string;
  buttonIcon?: JSX.Element;
  buttonLabelEl?: JSX.Element;
  innerLabel?: string;
  accessibleLabel?: string;
  options: Array<Option>;
  selected: string;
  onChange: (selectedValue: string) => void;
  isMobileWidth: boolean;
  closeOnTab?: boolean;
  dropDownBtnCls?: string;
  dropDownContainerCls?: string;
  dropDownBtnId?: string;
  onOptionFocus?: (opt: Option) => void;
  onOptionBlur?: (opt: Option) => void;
}

const SingleSelect: React.FC<SingleSelectProps> = (props: SingleSelectProps) => {
  const selectContainer: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [htmlId] = useState(uuidv4());

  const [open, setOpen] = useState<boolean>(false);

  useCloseMenuEffect(selectContainer, open, setOpen);

  return useObserver(() => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    const onChange = (opt: Option) => {
      props.onChange(opt.value);
      close();
    };

    //Expand the filter on arrow-down
    const onDropDownButtonKeyDown = (e: React.KeyboardEvent) => {
      switch (e.keyCode) {
        //arrow down
        case 40:
          if (!open) {
            e.preventDefault();
            setOpen(true);
            break;
          }
      }
    };

    const close = () => {
      setOpen(false);
    };

    const dropdownPane = (
      <SelectBase
        options={props.options}
        selected={props.selected}
        name={props.buttonLabel}
        open={open}
        close={close}
        onChange={onChange}
        closeOnTab={props.closeOnTab !== undefined ? props.closeOnTab : !props.isMobileWidth}
        innerLabel={props.innerLabel}
        onOptionFocus={props.onOptionFocus}
        onOptionBlur={props.onOptionBlur}
      />
    );

    return (
      <div className={`dropdown ${open ? 'is-shown' : ''} ${props.dropDownContainerCls}`} ref={selectContainer}>
        <DropDownButton
          id={props.dropDownBtnId ? props.dropDownBtnId : `btn-${htmlId}`}
          open={open}
          toggleOpen={toggleOpen}
          label={props.buttonLabel}
          icon={props.buttonIcon}
          labelEl={props.buttonLabelEl}
          accessibleLabel={props.accessibleLabel}
          className={props.dropDownBtnCls}
          onKeyDown={onDropDownButtonKeyDown}
        />
        {props.isMobileWidth ? (
          <ModalDialog show={open} close={close} title={props.accessibleLabel || props.buttonLabel} hideHeader={true}>
            <button className="sr-only" aria-hidden={true}>
              Hack
            </button>
            {dropdownPane}
          </ModalDialog>
        ) : (
          dropdownPane
        )}
      </div>
    );
  });
};

export default SingleSelect;
