import React from 'react';
import intl from 'react-intl-universal';
import SearchIconSmall from '../Icons/SearchIconSmall';

interface Props {
  searchCriteria: string;
  onSearchCriteriaChangeClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  visible: boolean;
}

const SearchTooltip: React.FC<Props> = (props: Props) => {
  return (
    <div className={`search-tooltip ${props.visible ? 'open' : ''}`}>
      <div className="tooltip" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        <SearchIconSmall />
        <span>{intl.get('plp.search.bar.tooltip.choose.search.criteria').d('Search criteria') + ' '}</span>
        <span>{props.searchCriteria}</span>
        <button
          tabIndex={-1}
          className="btn btn-link"
          onClick={e => {
            e.preventDefault();
            props.onSearchCriteriaChangeClick();
          }}
        >
          {intl.get('plp.search.bar.sr.change').d('Change')}
        </button>
      </div>
    </div>
  );
};

export default SearchTooltip;
