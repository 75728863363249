import React, {useState} from 'react';
import intl from 'react-intl-universal';
import {v4 as uuidv4} from 'uuid';
import {useObserver} from 'mobx-react-lite';
import {RequestDocumentsFields} from '../../stores/RequestAccessFormStore';
import FormField, {InputType} from '../Form/FormField';
import {useRootStore} from '../../stores';
import {IBasicRecord, IMeetingRecord, IRegistryEntry} from '../../stores/RecordStore';
import {DTOFileTypes, DTORecordTypes} from '../../types/DTOEntityTypes';
import './css/request-documents-form.scss';

interface Props {
  record: IRegistryEntry | IBasicRecord | IMeetingRecord;
  close: () => void;
  setSubmitStatus: (status: boolean) => void;
  afterFormSuccessSubmit: () => void;
}

interface FieldMeta {
  label: string;
  required: boolean;
  type: InputType;
  validationRegex?: RegExp;
  className?: string;
  attribs?: {[key: string]: string};
}

const requestAccessFormFieldsList: (keyof RequestDocumentsFields)[] = ['name', 'email', 'comment', 'phone'];

const RequestAccessForm: React.FC<Props> = ({close, afterFormSuccessSubmit, setSubmitStatus, record}) => {
  const rootStore = useRootStore();
  const [htmlId] = useState(uuidv4());
  const [fieldInFocus, setFieldInFocus] = useState<string>();

  const fieldsMetaData: {[key: string]: FieldMeta} = {
    name: {
      label: intl.get('plp.request.access.form.name.label').d('Name'),
      required: true,
      type: InputType.Text,
    },
    email: {
      label: intl.get('plp.request.access.form.email.label').d('Email'),
      required: true,
      type: InputType.Text,
      validationRegex: new RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      ),
    },
    phone: {
      label: intl.get('plp.request.access.form.phone.label').d('Phone'),
      required: false,
      type: InputType.Text,
    },
    comment: {
      label: intl.get('plp.request.access.form.comment.label').d('Comment'),
      required: false,
      type: InputType.TextArea,
    },
    /* Honeypot field*/
    verifyEmail: {
      label: '',
      required: false,
      className: 'verify-email',
      type: InputType.Text,
      attribs: {
        'aria-hidden': 'true',
        tabindex: '-1',
      },
    },
  };

  const validateField = (fieldName: keyof RequestDocumentsFields) => {
    const required = fieldsMetaData[fieldName].required;
    const regex = fieldsMetaData[fieldName].validationRegex;
    const fieldLabel = fieldsMetaData[fieldName].label;
    const fieldValue = rootStore.requestAccessFormStore.getFieldValue(fieldName)?.trim();
    let hasError = false;

    if (required) {
      if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
        rootStore.requestAccessFormStore.setFieldError(
          fieldName,
          intl.get('plp.request.access.form.required.field', {field: fieldLabel}).d(`${fieldLabel} is required.`),
        );

        hasError = true;
      }
    }

    if (!hasError && regex && !fieldValue?.match(regex)) {
      rootStore.requestAccessFormStore.setFieldError(
        fieldName,
        intl.get('plp.request.access.form.value.error', {field: fieldLabel}).d(`${fieldLabel} is malformed.`),
      );

      hasError = true;
    }

    if (!hasError) {
      rootStore.requestAccessFormStore.setFieldError(fieldName, '');
    }
  };

  const validateForm = () => {
    requestAccessFormFieldsList.forEach(fieldName => validateField(fieldName));
  };

  const isFormValid = () => {
    return requestAccessFormFieldsList
      .map(fieldName => rootStore.requestAccessFormStore.getFieldError(fieldName) === '')
      .reduce((formValid, fieldValid) => formValid && fieldValid, true);
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    validateForm();
    if (isFormValid()) {
      const success = await rootStore.requestAccessFormStore.doRequest(record);
      if (success) {
        afterFormSuccessSubmit();
        setSubmitStatus(true);
      } else {
        setSubmitStatus(false);
      }
    }
  };

  return useObserver(() => {
    return (
      <form className="request-access" onSubmit={onSubmit}>
        <div className="form-content">
          <dl>
            <dt>{intl.get('plp.request.access.form.record.id').d('Record Id')}</dt>
            <dd>{record.recordId}</dd>
            {record.type !== DTORecordTypes.MeetingRecord && record.file.type === DTOFileTypes.CaseFile && (
              <dt>{intl.get('plp.request.access.form.case.file.title').d('Case file title')}</dt>
            )}
            {record.type === DTORecordTypes.MeetingRecord && (
              <dt>{intl.get('plp.request.access.form.meeting.folder.title').d('Meeting folder')}</dt>
            )}
            {record.type !== DTORecordTypes.MeetingRecord && record.file.type === DTOFileTypes.Folder && (
              <dt>{intl.get('plp.request.access.form.folder.title').d('Folder')}</dt>
            )}
            {record.type === DTORecordTypes.MeetingRecord && <dd>{record.meetingFolder.title}</dd>}
            {record.type !== DTORecordTypes.MeetingRecord && <dd>{record.file.title}</dd>}
            <dt>{intl.get('plp.request.access.form.record.title').d('Record title')}</dt>
            <dd>{record.title}</dd>
            {record.type !== DTORecordTypes.MeetingRecord && record.file.type === DTOFileTypes.CaseFile && (
              <>
                <dt>{intl.get('plp.request.access.form.case.responsible').d('Case responsible')}</dt>
                <dd>{record.file.caseResponsible}</dd>
              </>
            )}
          </dl>
          <div className="form-fields">
            {Object.keys(fieldsMetaData).map(name => {
              const fieldName = name as keyof RequestDocumentsFields;
              const meta = fieldsMetaData[fieldName];
              const inputId = `${fieldName.replace(' ', '').toLocaleLowerCase()}-${htmlId}`;
              const inputValue = rootStore.requestAccessFormStore.getFieldValue(fieldName);
              const error = rootStore.requestAccessFormStore.getFieldError(fieldName);

              return (
                <div key={fieldName} className={`form-field-row ${meta.className || ''}`}>
                  <FormField
                    inputType={meta.type}
                    attribs={meta.attribs}
                    inputId={inputId}
                    inputName={fieldName}
                    inputValue={inputValue || ''}
                    label={meta.label}
                    fieldInFocus={fieldInFocus}
                    setFocusOn={setFieldInFocus}
                    onChange={value => {
                      rootStore.requestAccessFormStore.setFieldValue(fieldName, value);
                    }}
                    validationError={error}
                    validateOnBlur={() => {
                      validateField(fieldName);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="form-buttons">
          <button type="button" className="btn btn-lg btn-secondary" onClick={close}>
            {intl.get('plp.parent.item.header.button.close').d('Close')}
          </button>
          <button
            className="btn btn-lg btn-primary"
            type="submit"
            disabled={rootStore.requestAccessFormStore.isSubmitting}
          >
            {intl.get('plp.request.access.form.submit').d('Send request')}
          </button>
        </div>
      </form>
    );
  });
};

export default RequestAccessForm;
