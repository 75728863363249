import React from 'react';
import CardContent from './CardContent';
import {useRootStore} from 'stores/index';
import {IMeetingFolder} from 'stores/FileStore';
import {BaseCardProps} from '../Card/Card';
import {FileTypes} from '../../types/EntityTypes';

interface Props extends BaseCardProps {
  meetingFolder: IMeetingFolder;
}

const MeetingFolder: React.FC<Props> = (props: Props) => {
  const {meetingFolder, cardType, isOpen} = props;

  const rootStore = useRootStore();
  const recordStore = rootStore.recordStore;
  const navigationStore = rootStore.navigationStore;

  const itemLink = navigationStore.getBranchRecordsUrl(
    FileTypes.MeetingFolder,
    props.meetingFolder.id,
    1,
    recordStore.pageSize,
  );

  return (
    <CardContent
      meetingFolder={meetingFolder}
      cardType={cardType}
      itemLink={!isOpen ? itemLink : undefined}
    />
  );
};

export default MeetingFolder;
