import {FilterCriteria, SearchableCriteria} from 'stores/SearchStore';
import {DEFAULT_SEARCH_PAGE_SIZE, VALID_SEARCH_PAGE_SIZES} from 'stores/PaginatableStore';
import {
  FileSubsetFilterCriteria,
  FileSubsetSearchCriteria,
  MeetingFolderFilterCriteria,
  MeetingFolderSearchCriteria,
} from 'stores/FileStore';
import {RecordSubsetFilterCriteria, RecordSubsetSearchCriteria} from 'stores/RecordStore';
import {ParsedQuery} from 'query-string';

export const queryPage = (query: ParsedQuery): number => {
  let qPage: string | undefined;
  if (query.page && !Array.isArray(query.page)) {
    qPage = query.page;
  }
  return qPage && parseInt(qPage, 10) > 0 ? parseInt(qPage, 10) : 1;
};

export const queryPageSize = (query: ParsedQuery): number => {
  let qPageSize: string | undefined;
  if (query.pageSize && !Array.isArray(query.pageSize)) {
    qPageSize = query.pageSize;
  }
  const parsedPageSize: number = qPageSize ? parseInt(qPageSize, 10) : DEFAULT_SEARCH_PAGE_SIZE;
  return VALID_SEARCH_PAGE_SIZES.includes(parsedPageSize) ? parsedPageSize : DEFAULT_SEARCH_PAGE_SIZE;
};

export const querySearchCriterion = (query: ParsedQuery): SearchableCriteria => {
  let qSearchCriterion: string | undefined;
  if (query.searchCriterion && !Array.isArray(query.searchCriterion)) {
    qSearchCriterion = query.searchCriterion;
  }
  let searchCriterion: SearchableCriteria = FileSubsetSearchCriteria.title;
  switch (qSearchCriterion) {
    case FileSubsetSearchCriteria.title:
    case FileSubsetSearchCriteria.fileId:
    case MeetingFolderSearchCriteria.title:
    case RecordSubsetSearchCriteria.title:
    case RecordSubsetSearchCriteria.recordId:
    case RecordSubsetSearchCriteria.correspondenceParty:
      searchCriterion = qSearchCriterion;
      break;
    default:
      break;
  }
  return searchCriterion;
};

export const querySearchValue = (query: ParsedQuery): string => {
  let qSearchValue: string | undefined;
  if (query.searchValue && !Array.isArray(query.searchValue)) {
    qSearchValue = query.searchValue;
  }
  return (qSearchValue ? qSearchValue : '');
};

export const queryFilters = (query: ParsedQuery): Map<FilterCriteria, string> => {
  const filters: Map<FilterCriteria, string> = new Map<FilterCriteria, string>();
  Object.keys(query).forEach((queryKey: string) => {
    let queryValue: string | string[] | null | undefined = query[queryKey];
    if (queryValue && !Array.isArray(queryValue)) {
      switch (queryKey) {
        case FileSubsetFilterCriteria.dateFrom:
        case FileSubsetFilterCriteria.dateTo:
        case FileSubsetFilterCriteria.knr:
        case FileSubsetFilterCriteria.gnr:
        case FileSubsetFilterCriteria.bnr:
        case FileSubsetFilterCriteria.fnr:
        case FileSubsetFilterCriteria.snr:
        case FileSubsetFilterCriteria.seriesType:
        case MeetingFolderFilterCriteria.meetingDateFrom:
        case MeetingFolderFilterCriteria.meetingDateTo:
        case MeetingFolderFilterCriteria.board:
        case MeetingFolderFilterCriteria.seriesType:
        case RecordSubsetFilterCriteria.dateFrom:
        case RecordSubsetFilterCriteria.dateTo:
        case RecordSubsetFilterCriteria.recordType:
          filters.set(queryKey, queryValue);
          break;
        default:
          break;
      }
    }
  });
  return filters;
};