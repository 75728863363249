import React, {useEffect, useRef, useState} from 'react';
import MagnifyingGlass from '../Icons/MagnifyingGlass';
import Cross from '../Icons/Cross';
import intl from 'react-intl-universal';
import throttle from 'lodash/debounce';

export interface SearchResult {
  value: string;
  label: string;
}

interface Props {
  id: string;
  onSearch: (term: string) => void;
  onLoadMoreResults?: () => void;
  results: Array<SearchResult>;
  hasMoreResults: boolean;
  selectedValue: SearchResult | undefined;
  setSelectedValue: (value: SearchResult | undefined) => void;
  placeholder?: string;
  onKeyDownOfFirstElement?: (e: React.KeyboardEvent) => void;
  visible?: boolean;
}

const SearchField: React.FC<Props> = (props: Props) => {
  const searchResultsContainerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const inputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const [screenReaderMessage, setScreenReaderMessage] = useState('');

  //To update a screen reader message with the search results on a regular basis (but not too often) we need to throttle the call.
  //However, closures such as the callbacks of setTimeout or throttle/debounce use the values of state/props at the time of call.
  //To update the message with the latest values we need to keep ref's of the state values.
  const [searchTerm, setSearchTerm] = useState<string>('');
  const searchTermRef = useRef(searchTerm);
  searchTermRef.current = searchTerm;

  const resultsCountRef = useRef(props.results.length);
  resultsCountRef.current = props.results.length;

  useEffect(() => {
    if (props.visible) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
        setSearchTerm('');
      }
    }
  }, [props.visible]);

  const selectValue = (result: SearchResult) => {
    props.setSelectedValue(result);
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchTerm(value);
    props.onSearch(value);
    throttleUpdateScreenReaderMessage();
  };

  const updateScreenReaderMessage = () => {
    if (resultsCountRef.current < 1 && searchTermRef.current) {
      setScreenReaderMessage(`${intl.get('plp.search.no.results').d('No results for')} ${searchTermRef.current}`);
    } else if (resultsCountRef.current > 0 && searchTermRef.current) {
      setScreenReaderMessage(
        `${intl.get('plp.search.result.set.for.value').d('Showing results for')} ${searchTermRef.current}`,
      );
    } else if (!searchTermRef.current) {
      setScreenReaderMessage(intl.get('plp.filter.board.show.all').d('Showing all boards'));
    }
  };

  const throttleUpdateScreenReaderMessage = throttle(updateScreenReaderMessage, 2000);

  const onSearchInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 40) {
      //down arrow or tab -> select first search result
      if (searchResultsContainerRef && searchResultsContainerRef.current) {
        let results = searchResultsContainerRef.current.getElementsByTagName('label');
        if (results.length > 0) {
          results[0].click();
          e.preventDefault();
        }
      }
    }
    if (props.onKeyDownOfFirstElement && !props.selectedValue) {
      props.onKeyDownOfFirstElement(e);
    }
  };

  const showNoSearchResultsMessage = props.results.length === 0 && searchTerm;
  const noSearchResultsMessage = (
    <>
      {intl.get('plp.search.no.results').d('No search results for')} <b>{searchTerm}.</b>
    </>
  );

  return (
    <div className="search-field">
      {props.selectedValue && (
        <section className="selected-results">
          <button
            className="btn btn-secondary"
            onClick={() => {
              props.setSelectedValue(undefined);
              if (inputRef && inputRef.current) {
                inputRef.current.focus();
              }
            }}
            onKeyDown={props.onKeyDownOfFirstElement}
          >
            {props.selectedValue.label}
            <Cross width="9" height="8" />
            <span className="sr-only">{`${intl.get('plp.filter.clear.value').d('Clear value')} ${
              props.selectedValue.label
            }`}</span>
          </button>
        </section>
      )}
      <div className="input-container">
        <input
          ref={inputRef}
          placeholder={props.placeholder}
          value={searchTerm}
          onChange={onSearchInputChange}
          onKeyDown={onSearchInputKeyDown}
          type="search"
        />
        <span>
          <MagnifyingGlass />
        </span>
      </div>
      {props.results.length > 0 && (
        <div className="search-results" ref={searchResultsContainerRef}>
          {props.results.map((result: SearchResult) => {
            const inputId = `radio-id-${props.id}-${result.value.replace(' ', '').toLocaleLowerCase()}`;
            const checked = props.selectedValue && props.selectedValue.value === result.value ? true : false;
            return (
              <label
                key={result.value}
                htmlFor={inputId}
                id={`label-${inputId}`}
                onClick={() => {
                  selectValue(result);
                }}
                className={checked ? 'selected' : ''}
              >
                <input
                  id={inputId}
                  type="radio"
                  name={`radio-name-${props.id}`}
                  aria-checked={checked}
                  checked={checked}
                  onChange={e => {
                    if (e.currentTarget.checked) {
                      selectValue(result);
                    }
                  }}
                />
                {result.label}
              </label>
            );
          })}
          {props.hasMoreResults && props.onLoadMoreResults && (
            <div className="load-more">
              <button className="btn btn-link" onClick={props.onLoadMoreResults}>
                {intl.get('plp.load.more').d('Load more')}
              </button>
            </div>
          )}
        </div>
      )}
      {showNoSearchResultsMessage && <div className="no-results">{noSearchResultsMessage}</div>}
      <span className="sr-only" aria-live="polite">
        {screenReaderMessage}
      </span>
    </div>
  );
};

export default SearchField;
