import React from 'react';
import intl from 'react-intl-universal';
import {IMeetingFolder} from 'stores/FileStore';
import CardTemplate from '../Card/CardTemplate';
import {CardType} from '../Card/Card';
import {CardFieldCategory} from '../Card/Footer/CardTooltips';

interface Props {
  meetingFolder: IMeetingFolder;
  itemLink?: string;
  cardType: CardType;
}

const CardContent: React.FC<Props> = (props: Props) => {
  const {meetingFolder, cardType} = props;

  const fieldDefinitions = [];

  if (meetingFolder.board) {
    fieldDefinitions.push({name: intl.get('plp.grid.item.field.board').d('Board'), value: meetingFolder.board});
  }

  let footerFields = [];

  if (props.meetingFolder.meetingDate) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.date').d('Date'),
      value: props.meetingFolder.meetingDate,
      category: CardFieldCategory.Date,
    });
  }

  if (props.meetingFolder.meetingLocation) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.meeting.location').d('Meeting location'),
      value: props.meetingFolder.meetingLocation,
      category: CardFieldCategory.Location,
    });
  }

  if (props.meetingFolder.screening) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.screened').d('Screened'),
      value: props.meetingFolder.screening.name,
      category: CardFieldCategory.Screening,
    });
  }

  return (
    <CardTemplate
      entityIdFieldTitle={intl.get('plp.grid.item.field.common.file.ident')}
      entityTitle={intl.get('plp.grid.item.field.entity.type.meeting.folder').d('Meeting')}
      entityTypeCls="meeting-folder"
      abbreviation={intl.get('plp.grid.item.field.entity.type.abbrev.meeting.folder').d('MF')}
      itemNumber={meetingFolder.fileId}
      title={meetingFolder.title}
      footerFields={footerFields}
      fieldDefinitions={fieldDefinitions}
      itemLink={props.itemLink}
      cardType={cardType}
      screened={!!props.meetingFolder.screening}
    />
  );
};

export default CardContent;
