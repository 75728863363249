import React, {createRef, useEffect, useState} from 'react';
import ModalSearchForm from './ModalSearchForm';
import EntityTypeFilter from '../Filters/EntityTypeFilter';
import intl from 'react-intl-universal';
import SearchIcon from '../Icons/SearchIcon';
import CrossReset from '../Icons/CrossReset';
import {ExtendedSearchProps} from './SearchBar';
import './css/compact-search.scss';

const CompactSearch: React.FC<ExtendedSearchProps> = (props: ExtendedSearchProps) => {
  const openSearchModalBtn: React.RefObject<HTMLButtonElement> = createRef<HTMLButtonElement>();
  const [openModalButtonOnFocus, setOpenModalButtonOnFocus] = useState(false);
  const [modalSearchFormVisible, setModalSearchFormVisible] = useState<boolean | undefined>(undefined);

  const executeSearch = () => {
    props.executeSearch();
    setModalSearchFormVisible(false);
  };

  const closeModal = () => {
    setModalSearchFormVisible(false);
  };

  const onOpenModalBtnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setModalSearchFormVisible(true);
  };

  const onClearSearchValueClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    props.setSearchCriterionValue('');
    focusOpenSearchModalBtn();
  };

  const focusOpenSearchModalBtn = () => {
    if (openSearchModalBtn && openSearchModalBtn.current) {
      openSearchModalBtn.current.focus();
    }
  };

  useEffect(() => {
    if (modalSearchFormVisible === false) {
      focusOpenSearchModalBtn();
    }
  }, [modalSearchFormVisible]);

  useEffect(() => {
    if (!props.searchCriterionValue) {
      if (openModalButtonOnFocus) {
        props.setParentHasFocusRing(true);
      } else {
        props.setParentHasFocusRing(false);
      }
    }
  }, [openModalButtonOnFocus, props.searchCriterionValue]);

  return (
    <>
      {modalSearchFormVisible && (
        <ModalSearchForm
          executeSearch={executeSearch}
          close={closeModal}
          disableClear={props.disableClear}
          selectedSearchType={props.selectedSearchType}
          searchCriterionValue={props.searchCriterionValue}
          setSearchCriterionValue={props.setSearchCriterionValue}
          selectedSearchCriterion={props.selectedSearchCriterion}
          setSelectedSearchCriterion={props.setSelectedSearchCriterion}
        />
      )}
      {!modalSearchFormVisible && (
        <>
          <EntityTypeFilter
            onOptionFocus={() => props.setFocusIsInDropdown(true)}
            onOptionBlur={() => props.setFocusIsInDropdown(false)}
          />
          <div className="open-mobile-search">
            <button
              ref={openSearchModalBtn}
              className="open-search-btn"
              aria-label={intl.get('plp.search.bar.sr.open.form').d('Open search form')}
              onClick={onOpenModalBtnClick}
              onFocus={() => setOpenModalButtonOnFocus(true)}
              onBlur={() => setOpenModalButtonOnFocus(false)}
            >
              {!props.searchCriterionValue && (
                <div>
                  <SearchIcon />
                </div>
              )}
            </button>
            {/*The disabled input below allows us to better display the search term.
             * We use the attribute aria-disabled, instead of disabled, because on iPhones the input
             * is preventing the ability to open the search button.
             * Alternatively, the search term can be part of the label of the button,
             * but it is hard to style and it destroys the responsive design!
             */}
            <input
              type="text"
              tabIndex={-1}
              placeholder={intl.get('plp.search.bar.sr.search').d('Search')}
              value={props.searchCriterionValue}
              aria-disabled={true}
              onChange={() => {}} /*prevents a React warning*/
            />
          </div>

          {props.searchCriterionValue && (
            <button
              className="btn-icon clear-search"
              disabled={props.disableClear}
              onClick={onClearSearchValueClick}
              aria-label={intl.get('plp.search.bar.sr.clear').d('Clear')}
            >
              <CrossReset fill={props.disableClear ? '#E5E5E5' : '#808080'} />
            </button>
          )}
        </>
      )}
    </>
  );
};

export default CompactSearch;
