import React, {useState, useEffect} from 'react';
import useDefaultProps from 'hooks/useDefaultProps';

interface Props {
  src: string;
  fallBackSrc?: string;
  isLogo?: boolean;
  alt: string
}

const propDefaults: Partial<Props> = {
  isLogo: false,
};

const Image: React.FC<Props> = (props: Props) => {
  props = useDefaultProps(props, propDefaults);

  const [hasSrcLoadingError, setHasSrcLoadingError] = useState<boolean>(false);
  const [src, setSrc] = useState<string>(props.src);

  const onError = () => {
    setHasSrcLoadingError(true);
  };

  useEffect(() => {
    if (hasSrcLoadingError) {
      if (props.fallBackSrc) {
        setSrc(props.fallBackSrc);
      }
      if (props.isLogo) {
        console.log('Misconfigured organization logo image file.');
      }
    }
  }, [hasSrcLoadingError, props.fallBackSrc]);

  return <img src={src} onError={onError} alt={props.alt}/>;
};

export default Image;
