import {action, observable} from 'mobx';
import client from 'client/client';
import {FilterCriteria, SearchStore} from './SearchStore';
import {PaginatableStore} from './PaginatableStore';
import {TreeStore, Nodes} from './TreeStore';
import IScreening from 'types/IScreening';
import {NavigationStore} from './NavigationStore';
import {FileTypes} from 'types/EntityTypes';
import {DTOFileTypes} from 'types/DTOEntityTypes';
import {IEntitiesList} from '../types/IEntitiesList';
import {IType} from '../types/IType';
import {IChangeType} from '../types/IChangeType';
import {ISelectable} from '../types/ISelectable';
import {INoarkEntity} from '../types/INoarkEntity';
import {UIBlockerStore} from './UIBlockerStore';
import {isNotFound} from '../utils/errorUtils';

export interface IProperty {
  knr: string; // municipality
  gnr: string; // cadastral unit number
  bnr: string; // property unit number
  fnr: string | null; // leasehold unit number
  snr: string | null; // section unit number
}

export interface IFile extends INoarkEntity<DTOFileTypes> {
  fileId: string;
  screening: IScreening | null;
}

export interface IFolder extends IFile {
  type: DTOFileTypes.Folder;
  createdDate: string;
  properties: Array<IProperty> | null;
  fileType: string | null;
}

export interface ICaseFile extends IFile {
  type: DTOFileTypes.CaseFile;
  caseDate: string;
  properties: Array<IProperty> | null;
  caseResponsible: string;
  caseStatus: string;
  fileType: string | null;
}

export interface IMeetingFolder extends IFile {
  type: DTOFileTypes.MeetingFolder;
  meetingDate: string;
  board: string;
  meetingLocation: string | null;
}

export interface IFilesResponse<T extends IFile> {
  hasMore: boolean;
  page: number;
  pageSize: number;
  results: Array<T>;
}

export enum FileSubsetSearchCriteria {
  title = 'title',
  fileId = 'fileId',
}

export enum FileSubsetFilterCriteria {
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
  knr = 'knr',
  gnr = 'gnr',
  bnr = 'bnr',
  fnr = 'fnr',
  snr = 'snr',
  seriesType = 'seriesType',
}

export enum MeetingFolderSearchCriteria {
  title = 'title',
}

export enum MeetingFolderFilterCriteria {
  board = 'board',
  meetingDateFrom = 'meetingDateFrom',
  meetingDateTo = 'meetingDateTo',
  seriesType = 'seriesType',
}

export class FileStore<T extends IFile> extends PaginatableStore
  implements IEntitiesList<FileTypes, T>, IType<FileTypes>, IChangeType<FileTypes>, ISelectable<T> {
  private readonly treeStore: TreeStore;
  private readonly navigationStore: NavigationStore;
  private readonly searchStore: SearchStore;
  private readonly uiBlockStore: UIBlockerStore;

  constructor(
    treeStore: TreeStore,
    navigationStore: NavigationStore,
    searchStore: SearchStore,
    uiBlockStore: UIBlockerStore,
  ) {
    super();
    this.treeStore = treeStore;
    this.navigationStore = navigationStore;
    this.searchStore = searchStore;
    this.uiBlockStore = uiBlockStore;
  }

  @observable
  type: FileTypes = FileTypes.FileSubset;

  @action.bound
  public setType(type: FileTypes): void {
    this.type = type;
  }

  @action.bound
  public changeType(type: FileTypes): void {
    this.navigationStore.goToRootFiles(
      type,
      1,
      this.pageSize,
      this.searchStore.getSearchQuery(),
      this.searchStore.selectedFilters,
    );
  }

  @action.bound
  public changePage(page: number): void {
    this.navigationStore.goToRootFiles(
      this.type,
      page,
      this.pageSize,
      this.searchStore.getSearchQuery(),
      this.searchStore.selectedFilters,
    );
  }

  public getUrlForPage(page: number): string {
    return this.navigationStore.getRootFilesUrl(
      this.type,
      page,
      this.pageSize,
      this.searchStore.getSearchQuery(),
      this.searchStore.selectedFilters,
    );
  }

  @observable
  entitiesList: Array<T> = [];

  @action.bound
  public async fetchEntities(page: number, type?: FileTypes): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.setIsLoading(true);
    const actionId = this.uiBlockStore.notifyActionStarted();

    try {
      const typeToFetch = type || this.type;

      let searchCriterion:
        | FileSubsetSearchCriteria
        | MeetingFolderSearchCriteria
        | undefined = undefined;
      let searchCriterionValue: string | undefined = undefined;

      if (this.searchStore.searchCriterionValue) {
        searchCriterion = this.searchStore.selectedSearchCriterion as
          | FileSubsetSearchCriteria
          | MeetingFolderSearchCriteria;
        searchCriterionValue = this.searchStore.searchCriterionValue;
      }

      let filters: Map<FileSubsetFilterCriteria | MeetingFolderFilterCriteria, string> = new Map();
      this.searchStore.selectedFilters.forEach((value: string, key: FilterCriteria) => {
        let filterType = key as FileSubsetFilterCriteria | MeetingFolderFilterCriteria;
        if (filterType) {
          filters.set(filterType, value);
        }
      });

      const archiveEntitiesResponse = await client.fetchFiles<T>(
        typeToFetch,
        page,
        this.pageSize,
        searchCriterion,
        searchCriterionValue,
        filters,
      );

      this.page = page;
      this.type = typeToFetch;
      this.entitiesList = archiveEntitiesResponse.results;
      this.hasMore = archiveEntitiesResponse ? archiveEntitiesResponse.hasMore : false;

      this.treeStore.setRootNode(Nodes.File);
      this.treeStore.setCurrentNode(Nodes.File);

      this.searchStore.setValueLastSearched(searchCriterionValue);
    } catch (error) {
      if (isNotFound(error)) {
        this.navigationStore.redirectToNotFound();
      }
    } finally {
      this.uiBlockStore.notifyActionCompleted(actionId);
      this.setIsLoading(false);
    }
  }

  @action.bound
  public clearEntities(): void {
    this.entitiesList = [];
  }

  @observable
  selectedEntityId: string | null = null;

  @action.bound
  public setSelectedEntityId(fileId: string | null): void {
    this.selectedEntityId = fileId;
  }

  @observable
  selectedEntity: T | null = null;

  @action.bound
  public async fetchSelectedEntity(): Promise<void> {
    if (this.selectedEntityId) {
      this.setIsLoading(true);
      const actionId = this.uiBlockStore.notifyActionStarted();

      try {
        const selectedEntity: IFilesResponse<T> = await client.fetchSelectedFile<T>(this.type, this.selectedEntityId);

        this.selectedEntity = selectedEntity.results[0] || null;
      } catch (error) {
        if (isNotFound(error)) {
          this.navigationStore.redirectToNotFound();
        }
      } finally {
        this.uiBlockStore.notifyActionCompleted(actionId);
        this.setIsLoading(false);
      }
    }
  }
}
