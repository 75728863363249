import React, {useEffect, useRef, useState} from 'react';
import Header from './Header/Header';
import ParentItemHeader from './Header/ParentItemHeader';
import GridWrapper from './Grid/GridWrapper';
import Footer from './Footer/Footer';
import useGridProps, {GridProps} from 'hooks/useGridProps';
import AppProps from '../types/AppProps';
import intl from 'react-intl-universal';
import useCalculateWindowWidthEffect from '../hooks/useCalculateWindowWidthEffect';
import {useRootStore} from '../stores';
import {useLocation} from 'react-router';
import {observer} from 'mobx-react';
import {FileTypes} from '../types/EntityTypes';
import {MeetingRightSidebar} from './MeetingFolder/MeetingRightSidebar';
import {Nodes} from '../stores/TreeStore';

type Props = AppProps;

const PublicLookupPortal: React.FC<Props> = observer((props: Props) => {
  const rootStore = useRootStore();
  const navigationStore = rootStore.navigationStore;
  const uiStore = rootStore.uiStore;
  const fileStore = rootStore.fileStore;
  const treeStore = rootStore.treeStore;

  const [portalClass, setPortalClass] = useState<string>('');
  const [childrenOpen, setChildrenOpen] = useState<boolean>(false);
  const gridProps: GridProps = useGridProps();
  const [enableGoBack, setEnableGoBack] = useState<boolean>(false);
  const location = useLocation();
  const initialLocation = useRef<string>(location.pathname + location.search);
  const selectedLocale = props.selectedLocale;

  useEffect(() => {
    document.documentElement.setAttribute('lang', selectedLocale);
  }, [selectedLocale])

  useCalculateWindowWidthEffect();

  useEffect(() => {
    navigationStore.setPageNotFound(false);
  }, []);

  useEffect(() => {
    setEnableGoBack(initialLocation.current !== location.pathname + location.search);

    if (initialLocation.current === '') {
      initialLocation.current = location.pathname + location.search;
    }
  }, [location]);

  const onCloseAll = () => {
    initialLocation.current = '';

    navigationStore.goToRoot(gridProps.rootPortalRouteProps);
  };

  if (!gridProps.initialized) return null;

  return (
    <React.Fragment>
      <a id="pagetop" className="go-to-link" href="#maincontent">
        {`${intl.get('plp.go.to').d('Go to')}  ${intl.get('plp.main.content').d('main content')}`}
      </a>
      <a id="contactinfo-link" className="go-to-link" href="#contactinfo">
        {`${intl.get('plp.go.to').d('Go to')}  ${intl.get('plp.contact.info').d('contact info')}`}
      </a>
      <div
        id="grid-container"
        className={`grid-container ${portalClass} ${uiStore.gridContainerHasScroll ? '' : 'no-scroll'}`}
      >
        {childrenOpen ? (
          <ParentItemHeader enableGoBack={enableGoBack} onCloseAll={onCloseAll} />
        ) : (
          <Header {...props} isSmallScreen={uiStore.isSmallScreen} isMobile={uiStore.isMobile} />
        )}
        <div id="grid-content" className="content">
          <GridWrapper
            portalRouteProps={gridProps.portalRouteProps}
            setRootPortalRouteProps={gridProps.setRootPortalRouteProps}
            setChildrenOpen={setChildrenOpen}
            setPortalClass={setPortalClass}
          />
          {fileStore.type === FileTypes.MeetingFolder &&
          !!fileStore.selectedEntityId &&
          treeStore.currentNode === Nodes.Record && <MeetingRightSidebar />}
          {props.organizationAssets && <Footer selectedLocale={props.selectedLocale} organizationAssets={props.organizationAssets} />}
        </div>
        <div className="left-sidebar" />
      </div>
    </React.Fragment>
  );
});

export default PublicLookupPortal;
