import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '9',
  height: '16',
  fill: 'none',
  viewBox: '0 0 9 16',
  focusable: false,
};

const ChevronRight: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.70695 0.292891C1.31646 -0.0976304 0.683353 -0.0976304 0.292866 0.292891C-0.097622 0.683412 -0.097622 1.31657 0.292866 1.70709L6.58565 8.00043L0.29372 14.2929C-0.0967676 14.6834 -0.0967667 15.3166 0.293719 15.7071C0.684207 16.0976 1.31731 16.0976 1.7078 15.7071L8.70713 8.70716C9.09762 8.31664 9.09762 7.68348 8.70713 7.29296C8.6995 7.28532 8.69175 7.27782 8.68391 7.27046L1.70695 0.292891ZM7.99982 7.9998L8.0001 8.00006L7.99973 8.00043L7.99148 7.99218L7.99982 7.9998Z" />
    </svg>
  );
};

export default ChevronRight;
