import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';

/**
 * Generic client factory
 * @param baseURL
 * @param token
 * @param successInterceptor
 * @param rejectInterceptor
 * @param options
 */
export default (
  baseURL: string,
  token?: string,
  successInterceptor?: (response: AxiosResponse) => AxiosResponse,
  rejectInterceptor?: (response: AxiosResponse) => Promise<any>,
  options?: AxiosRequestConfig,
): AxiosInstance => {
  let headers:{[key: string]: string} = {};
  if (token && token.length) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  options = options || {};

  if (options && options.headers) {
    options.headers = {...options.headers, ...headers};
  } else {
    options.headers = headers;
  }

  const client = axios.create({
    // all axios can be used, shown in axios documentation
    baseURL,
    ...options,
  });

  client.interceptors.response.use(
    function(response) {
      if (successInterceptor) {
        return successInterceptor(response);
      }

      return response;
    },
    function(error) {
      if (rejectInterceptor) {
        return rejectInterceptor(error);
      }

      if(error.code === "ECONNABORTED") {
        //todo: show notification
      }

      // Do something with response error
      return Promise.reject(error);
    },
  );

  return client;
};
