import React, {useEffect} from 'react';
import SearchFilter from './BaseFilterComponents/SearchFilter';
import {useRootStore} from '../../stores';
import {MeetingFolderFilterCriteria} from '../../stores/FileStore';
import {SearchableType} from '../../stores/SearchStore';
import {useObserver} from 'mobx-react-lite';
import debounce from 'lodash/debounce';
import {FileTypes} from '../../types/EntityTypes';
import intl from 'react-intl-universal';
import {BaseFilterProps} from './types';
import './css/board-fiter.scss';

const BoardFilter: React.FC<BaseFilterProps> = (props: BaseFilterProps) => {
  const rootStore = useRootStore();
  const fileStore = rootStore.fileStore;
  const searchStore = rootStore.searchStore;
  const meetingBoardStore = rootStore.meetingBoardStore;

  const resetSearch = () => {
    searchStore.setMeetingBoardTerm('');
    meetingBoardStore.fetchFirstPage();
  };

  useEffect(() => {
    resetSearch();
  }, []);

  return useObserver(() => {
    const searchForBoard = (searchTerm: string) => {
      if (searchTerm) {
        searchStore.setMeetingBoardTerm(searchTerm);
      } else {
        searchStore.setMeetingBoardTerm('');
      }
      meetingBoardStore.fetchFirstPage();
    };

    const loadMoreResults = () => {
      meetingBoardStore.loadNextPage();
    };

    const searchForBoardWithDelay = debounce(searchForBoard, 800);

    const selectBoard = (board: string) => {
      if (board) {
        searchStore.setFilter(MeetingFolderFilterCriteria.board, board);
      } else {
        if (searchStore.selectedFilters.has(MeetingFolderFilterCriteria.board)) {
          searchStore.clearFilter(MeetingFolderFilterCriteria.board);
        }
      }

      const type: SearchableType = searchStore.selectedSearchType;
      if (type === FileTypes.MeetingFolder) {
        fileStore.changePage(1);
      }
    };

    return (
      <SearchFilter
        filterButtonLabel={intl.get('plp.filter.board').d('Board')}
        searchFieldPlaceholder={intl.get('plp.filter.board.search.placeholder').d('Search for boards')}
        searchResults={meetingBoardStore.entitiesList}
        hasMoreResults={meetingBoardStore.hasMore}
        loadMoreResults={loadMoreResults}
        search={searchForBoardWithDelay}
        resetSearch={resetSearch}
        currentFilterValue={searchStore.selectedFilters.get(MeetingFolderFilterCriteria.board) || ''}
        applyFilterValue={selectBoard}
        className="board-filter"
        filterViewMode={props.filterViewMode}
      />
    );
  });
};

export default BoardFilter;
