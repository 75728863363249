import React, {createRef, useEffect, useState} from 'react';
import SearchIcon from '../Icons/SearchIcon';
import intl from 'react-intl-universal';
import CrossReset from '../Icons/CrossReset';
import './css/modal-search-form.scss';
import FocusTrap from 'focus-trap-react';
import {SharedSearchProps} from './SearchBar';
import CustomRadioGroup from '../CustomRadioGroup/CustomRadioGroup';
import {
  getCriteriaForType,
  getCriterionLabel,
  getCriterionType,
  getSearchPlaceholderForCriterion,
} from './SearchOptions';

interface Props extends SharedSearchProps {
  close: () => void;
}

const ModalSearchForm: React.FC<Props> = (props: Props) => {
  const searchInput: React.RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
  const [inputOnFocus, setInputOnFocus] = useState(false);
  const [inputPlaceholder, setInputPlaceholder] = useState(
    getSearchPlaceholderForCriterion(props.selectedSearchCriterion),
  );
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [selectedCriterion, setSelectedCriterion] = useState<string>(props.selectedSearchCriterion);

  useEffect(() => {
    if (inputOnFocus) {
      setClearButtonVisible(true);
    }

    if (!inputOnFocus && !props.searchCriterionValue) {
      setClearButtonVisible(false);
    }
  }, [inputOnFocus, props.searchCriterionValue]);

  useEffect(() => {
    updateInputPlaceholder(selectedCriterion);
  }, [selectedCriterion]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearchCriterionValue(e.currentTarget.value);
  };

  const onInputKeyDown = (e: React.KeyboardEvent) => {
    switch (e.keyCode) {
      //Enter
      case 13:
        props.setSelectedSearchCriterion(selectedCriterion);
        props.executeSearch();
        break;
      case 27:
        props.close();
        break;
      default:
        break;
    }
  };

  const onBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      props.close();
    }
  };

  const onClearClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    props.setSearchCriterionValue('');
    if (searchInput && searchInput.current) {
      searchInput.current.focus();
    }
  };

  const selectCriterionAndSearch = (criterion: string) => {
    props.setSelectedSearchCriterion(criterion);
    props.close();
  };

  const updateInputPlaceholder = (selectedRadio: string) => {
    const criterion = getCriterionType(props.selectedSearchType, selectedRadio);
    setInputPlaceholder(getSearchPlaceholderForCriterion(criterion));
  };

  return (
    <FocusTrap>
      <div>
        <div className="modal-search-backdrop" onClick={onBackdropClick}></div>
        <div role="search" className="modal-search-form">
          <div className="input-container">
            <SearchIcon />
            <input
              ref={searchInput}
              placeholder={inputPlaceholder}
              type="search"
              aria-label={intl.get('plp.search.bar.sr.search').d('Search')}
              value={props.searchCriterionValue}
              onChange={onInputChange}
              onKeyDown={onInputKeyDown}
              onFocus={() => setInputOnFocus(true)}
              onBlur={() => setInputOnFocus(false)}
            />
            {clearButtonVisible && (
              <button
                disabled={props.disableClear}
                className="btn-icon clear-search"
                onClick={onClearClick}
                aria-label={intl.get('plp.search.bar.sr.clear').d('Clear')}
              >
                <CrossReset />
              </button>
            )}
          </div>
          <CustomRadioGroup
            containerClassName="search-criteria"
            radioGroupClassName="search-criteria-radiogroup"
            groupLabel={intl.get('plp.search.bar.tooltip.choose.search.criteria').d('Choose search criteria')}
            options={getCriteriaForType(props.selectedSearchType).map(opt => {
              return {value: opt, label: getCriterionLabel(props.selectedSearchType, opt)};
            })}
            onEnter={selectCriterionAndSearch}
            onEsc={props.close}
            selectedOption={selectedCriterion}
            setSelectedOption={setSelectedCriterion}
          />
        </div>
      </div>
    </FocusTrap>
  );
};
export default ModalSearchForm;
