import {FileTypes, RecordTypes} from 'types/EntityTypes';
import React from 'react';
import {FilterCriteria, SearchableType} from 'stores/SearchStore';
import {FileSubsetFilterCriteria, MeetingFolderFilterCriteria} from 'stores/FileStore';
import {RecordSubsetFilterCriteria} from 'stores/RecordStore';
import {useRootStore} from 'stores';
import {useObserver} from 'mobx-react-lite';
import BaseDateRangeFilter from './BaseFilterComponents/BaseDateRangeFilter';
import {BaseFilterProps} from './types';
import './css/date-range.scss';

type FilterMap = {
  [key: string]: {
    from: FilterCriteria;
    to: FilterCriteria;
  };
};

export const dateRangeFilterCriteriaMap: FilterMap = {
  [FileTypes.FileSubset]: {
    from: FileSubsetFilterCriteria.dateFrom,
    to: FileSubsetFilterCriteria.dateTo,
  },
  [FileTypes.MeetingFolder]: {
    from: MeetingFolderFilterCriteria.meetingDateFrom,
    to: MeetingFolderFilterCriteria.meetingDateTo,
  },
  [RecordTypes.RecordSubset]: {
    from: RecordSubsetFilterCriteria.dateFrom,
    to: RecordSubsetFilterCriteria.dateTo,
  },
};

interface Props extends BaseFilterProps {
  type: SearchableType;
}

const DateRangeFilter: React.FC<Props> = (props: Props) => {
  const rootStore = useRootStore();
  const fileStore = rootStore.fileStore;
  const recordStore = rootStore.recordStore;
  const searchStore = rootStore.searchStore;

  return useObserver(() => {
    const applyDatesFilter = (from: string, to: string) => {
      if (props.type !== searchStore.selectedSearchType) return;

      let filterCriteriaFrom = dateRangeFilterCriteriaMap[props.type].from;
      let filterCriteriaTo = dateRangeFilterCriteriaMap[props.type].to;

      if (from) {
        searchStore.setFilter(filterCriteriaFrom, from);
      } else {
        if (searchStore.selectedFilters.has(filterCriteriaFrom)) {
          searchStore.clearFilter(filterCriteriaFrom);
        }
      }

      if (to) {
        searchStore.setFilter(filterCriteriaTo, to);
      } else {
        if (searchStore.selectedFilters.has(filterCriteriaTo)) {
          searchStore.clearFilter(filterCriteriaTo);
        }
      }

      if (
        props.type === FileTypes.FileSubset ||
        props.type === FileTypes.MeetingFolder
      ) {
        fileStore.changePage(1);
      } else if (props.type === RecordTypes.RecordSubset) {
        recordStore.changePage(1);
      }
    };

    const getCurrentFromDateFilter = () => {
      const filterCriteriaFrom = dateRangeFilterCriteriaMap[props.type].from;
      return searchStore.selectedFilters.get(filterCriteriaFrom) || '';
    };

    const getCurrentToDateFilter = () => {
      const filterCriteriaTo = dateRangeFilterCriteriaMap[props.type].to;
      return searchStore.selectedFilters.get(filterCriteriaTo) || '';
    };

    return (
      <BaseDateRangeFilter
        currentFromFilter={getCurrentFromDateFilter()}
        currentToFilter={getCurrentToDateFilter()}
        applyFilter={applyDatesFilter}
        filterViewMode={props.filterViewMode}
      />
    );
  });
};

export default DateRangeFilter;
