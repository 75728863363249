import React, {useEffect} from 'react';
import intl from 'react-intl-universal';
import {useObserver} from 'mobx-react-lite';
import SelectFilter from './BaseFilterComponents/SelectFilter';
import {autorun} from 'mobx';
import {useRootStore} from '../../stores';
import {RecordSubsetFilterCriteria} from '../../stores/RecordStore';
import {SearchableType} from '../../stores/SearchStore';
import {RecordTypes} from '../../types/EntityTypes';
import {BaseFilterProps} from './types';

const RecordTypeFilter: React.FC<BaseFilterProps> = (props: BaseFilterProps) => {
  const rootStore = useRootStore();
  const recordStore = rootStore.recordStore;
  const searchStore = rootStore.searchStore;
  const registryEntryTypesStore = rootStore.registryEntryTypesStore;
  const uiStore = rootStore.uiStore;

  useEffect(
    () =>
      autorun(() => {
        const selectedSearchType: SearchableType = searchStore.selectedSearchType;
        if (selectedSearchType === RecordTypes.RecordSubset) {
          registryEntryTypesStore.fetchEntities(1);
        }
      }),
    [],
  );

  return useObserver(() => {
    const currentRecordTypeFilter = searchStore.selectedFilters.get(RecordSubsetFilterCriteria.recordType) || '';

    const selectRecordType = (recordType: string) => {
      if (recordType) {
        searchStore.setFilter(RecordSubsetFilterCriteria.recordType, recordType);
      } else {
        if (searchStore.selectedFilters.has(RecordSubsetFilterCriteria.recordType)) {
          searchStore.clearFilter(RecordSubsetFilterCriteria.recordType);
        }
      }

      const type: SearchableType = searchStore.selectedSearchType;
      if (type === RecordTypes.RecordSubset) {
        recordStore.changePage(1);
      }
    };

    return (
      <SelectFilter
        buttonLabel={intl.get('plp.filter.record.type').d('Record type')}
        options={registryEntryTypesStore.entitiesList.map(codeValue => {
          return {
            value: codeValue.name,
            label: codeValue.name,
          };
        })}
        selected={currentRecordTypeFilter}
        onChange={selectRecordType}
        closeOnTab={true}
        isMobileWidth={uiStore.isMobile}
        dropDownBtnCls="btn-secondary"
        filterViewMode={props.filterViewMode}
      />
    );
  });
};

export default RecordTypeFilter;
