export const getFileExtension = (documentName: string): string => {
  let extensionPattern = /\.[0-9a-z]+$/i;
  let extensionResult: RegExpMatchArray | null = documentName.match(extensionPattern);
  let extension = '';

  if (extensionResult) {
    extension = extensionResult[0].replace('.', '');
  }

  if (extension === 'gz' && documentName.indexOf('.tar.gz') !== -1) {
    extension = 'tar.gz';
  }

  return extension;
};
