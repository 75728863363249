import React from 'react';
import intl from 'react-intl-universal';
import {useRootStore} from 'stores';
import {FileTypes, RecordTypes} from 'types/EntityTypes';
import {CustomLink, IOrganizationAssets, IOrganizationDetails} from '../../hooks/useOrganizationAssets';
import Image from '../Image/Image';
import {Link} from 'react-router-dom';
import './css/footer.scss';
import {SupportedLocales} from "../../hooks/useTranslations";

interface Props {
  organizationAssets: IOrganizationAssets;
  selectedLocale: SupportedLocales;
}

const Footer: React.FC<Props> = (props: Props) => {
  const rootStore = useRootStore();
  const fileStore = rootStore.fileStore;
  const navigationStore = rootStore.navigationStore;

  const caseFilesLink = navigationStore.getRootFilesUrl(FileTypes.FileSubset, 1, fileStore.pageSize);
  const meetingFoldersLink = navigationStore.getRootFilesUrl(FileTypes.MeetingFolder, 1, fileStore.pageSize);
  const recordSubsetLink = navigationStore.getRootRecordsUrl(RecordTypes.RecordSubset, 1, fileStore.pageSize);
  const linkClass = "btn btn-link";

  const organizationDetails: IOrganizationDetails | null = props.organizationAssets.details;

  let links:  CustomLink[] = [];

  if (
      Array.isArray(props.organizationAssets.links[props.selectedLocale]) &&
      props.organizationAssets.links[props.selectedLocale].length > 0
  ) {
    links =  props.organizationAssets.links[props.selectedLocale];
  }

  return (
    <footer className="main-footer">
      <ul className="footer-nav">
        <li>
          <Link className={linkClass} to={caseFilesLink}>
            {intl.get('plp.filter.entity.type.file.subset').d('Case files')}
          </Link>
        </li>

        <li>
          <Link className={linkClass} to={meetingFoldersLink}>
            {intl.get('plp.filter.entity.type.meeting.folders').d('Meetings')}
          </Link>
        </li>

        <li>
          <Link className={linkClass} to={recordSubsetLink}>
            {intl.get('plp.filter.entity.type.public.records').d('Public records')}
          </Link>
        </li>
        {links && links.length > 0 && links.map((link, index) => {
          return <li key={"link-" + index}>
            <a className={linkClass} href={`${link.url}`}>{link.label}</a>
          </li>
        })}
      </ul>

      <address id="contactinfo" tabIndex={-1}>
        <div className="logo-wrapper">
          <Image
            src={props.organizationAssets.logo.src}
            fallBackSrc={props.organizationAssets.logo.fallbackSrc}
            alt={`${intl.get('plp.logo.of').d('Logo of')} ${props.organizationAssets.name}`}
            isLogo={true}
          />
        </div>
        {props.organizationAssets.name}
        <br />
        {organizationDetails && (
          <>
            {organizationDetails.city}
            <br />
            {organizationDetails.address}
            <br />
            {`${organizationDetails.postalCode} ${organizationDetails.city}, ${organizationDetails.country}`}
            <br />
            {organizationDetails.email}
            <br />
            {organizationDetails.phone}
          </>
        )}
      </address>

      <a className="go-to-link" href="#pagetop">
        {`${intl.get('plp.go.to').d('Go to')}  ${intl.get('plp.top').d('top')}`}
      </a>
    </footer>
  );
};

export default Footer;
