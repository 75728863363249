import React from 'react';
import {useRootStore} from 'stores';
import RecordTypeFilter from './RecordTypeFilter';
import {useObserver} from 'mobx-react-lite';
import {FileTypes, RecordTypes} from '../../types/EntityTypes';
import './css/filters.scss';
import BoardFilter from './BoardFilter';
import NationalIdentifiersFilter from './NatioanalIdentifiersFilter';
import DateRangeFilter from './DateRangeFilter';
import StackedFilters from './StackedFilters';
import {FilterViewMode} from './types';
import intl from 'react-intl-universal';
import {Nodes} from '../../stores/TreeStore';
import CrossClear from '../Icons/CrossClear';
import SeriesTypeFilter from "./SeriesTypeFilter";

const Filters: React.FC = () => {
  const rootStore = useRootStore();
  const treeStore = rootStore.treeStore;
  const searchStore = rootStore.searchStore;
  const fileStore = rootStore.fileStore;
  const recordStore = rootStore.recordStore;
  const uiStore = rootStore.uiStore;

  return useObserver(() => {
    const filterViewMode = uiStore.isMobileOrTablet ? FilterViewMode.Stacked : FilterViewMode.Default;

    const searchType = searchStore.selectedSearchType;

    const filters = (
      <>
        {' '}
        {searchType === FileTypes.FileSubset && (
          <React.Fragment>
            <NationalIdentifiersFilter fileType={FileTypes.FileSubset} filterViewMode={filterViewMode} />
            <SeriesTypeFilter filterViewMode={filterViewMode} />
            <DateRangeFilter type={FileTypes.FileSubset} filterViewMode={filterViewMode} />
          </React.Fragment>
        )}
        {searchType === FileTypes.MeetingFolder && (
          <React.Fragment>
            <BoardFilter filterViewMode={filterViewMode} />
            <SeriesTypeFilter filterViewMode={filterViewMode} />
            <DateRangeFilter type={FileTypes.MeetingFolder} filterViewMode={filterViewMode} />
          </React.Fragment>
        )}
        {searchType === RecordTypes.RecordSubset && (
          <React.Fragment>
            <RecordTypeFilter filterViewMode={filterViewMode} />
            <DateRangeFilter type={RecordTypes.RecordSubset} filterViewMode={filterViewMode} />
          </React.Fragment>
        )}
      </>
    );

    const clearFilters = () => {
      searchStore.clearAllFilters();

      switch (treeStore.rootNode) {
        case Nodes.File:
          fileStore.changePage(1);
          break;
        case Nodes.Record:
          recordStore.changePage(1);
          break;
        default:
          break;
      }
    };

    const onClearFiltersKeyDown = (e: React.KeyboardEvent) => {
      if (e.keyCode === 13) {
        clearFilters();
      }
    };

    return (
      <div className="filter">
        {!uiStore.isMobileOrTablet ? (
          filters
        ) : (
          <StackedFilters
            filtersCount={searchStore.filtersWithSelectedValuesCount}
            onToggleOpen={open => {
              if (open) {
                uiStore.setGridContainerHasScroll(false);
              } else {
                uiStore.setGridContainerHasScroll(true);
              }
            }}
          >
            {searchStore.selectedFilters.size > 0 && (
              <button className="btn btn-icon clear-filters" onClick={clearFilters} onKeyDown={onClearFiltersKeyDown}>
                <CrossClear />
                {intl.get('plp.filters.clear').d('Clear filters')}
              </button>
            )}
            {filters}
          </StackedFilters>
        )}
      </div>
    );
  });
};

export default Filters;
