import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '8',
  height: '14',
  fill: '#344249',
  viewBox: '0 0 8 14',
  focusable: false,
};

const ShortArrowRight: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M0.646447 12.6464C0.451184 12.8417 0.451184 13.1583 0.646447 13.3536C0.841709 13.5488 1.15829 13.5488 1.35355 13.3536L0.646447 12.6464ZM7 7L7.35355 7.35355L7.70711 7L7.35355 6.64645L7 7ZM1.35355 0.646446C1.15829 0.451184 0.841709 0.451184 0.646447 0.646446C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646446ZM1.35355 13.3536L7.35355 7.35355L6.64645 6.64645L0.646447 12.6464L1.35355 13.3536ZM7.35355 6.64645L1.35355 0.646446L0.646447 1.35355L6.64645 7.35355L7.35355 6.64645Z"
      />
    </svg>
  );
};

export default ShortArrowRight;
