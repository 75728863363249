import React from 'react';
import {useRootStore} from 'stores';
import {FilterCriteria} from 'stores/SearchStore';
import {useObserver} from 'mobx-react-lite';
import BaseNationalIdentifiersFilter from './BaseFilterComponents/BaseNationalIdentifiersFilter';
import {FileTypes} from 'types/EntityTypes';
import {BaseFilterProps} from './types';
import './css/national-identifier-filter.scss';
import {FileSubsetFilterCriteria} from '../../stores/FileStore';

export const nationalIdentifiers = ['knr', 'gnr', 'bnr', 'fnr', 'snr'];

export const filterNameToCriteriaMap: {[key: string]: FilterCriteria} = {
    knr: FileSubsetFilterCriteria.knr,
    gnr: FileSubsetFilterCriteria.gnr,
    bnr: FileSubsetFilterCriteria.bnr,
    fnr: FileSubsetFilterCriteria.fnr,
    snr: FileSubsetFilterCriteria.snr,
};

interface Props extends BaseFilterProps {
  fileType: FileTypes.FileSubset;
}

const NationalIdentifiersFilter: React.FC<Props> = (props: Props) => {
  const rootStore = useRootStore();
  const fileStore = rootStore.fileStore;
  const searchStore = rootStore.searchStore;

  return useObserver(() => {
    const getCurrentNationalIdentifierFilters = (): {[filterName: string]: string} => {
      let currentFilters: {[filterName: string]: string} = {};
      nationalIdentifiers.forEach(
        filterName => (currentFilters[filterName] = searchStore.selectedFilters.get(filterNameToCriteriaMap[filterName]) || ''),
      );
      return currentFilters;
    };

    const applyNationalIdentifierFilters = (filterValues: {[filterName: string]: string}) => {
      if (props.fileType !== searchStore.selectedSearchType) return;

      nationalIdentifiers.forEach(filterName => setNationalIdentifierFilter(filterName, filterValues[filterName]));

      fileStore.changePage(1);
    };

    const setNationalIdentifierFilter = (filterName: string, value: string | undefined) => {
      const filterCriteria = filterNameToCriteriaMap[filterName];
      if (filterCriteria) {
        if (value) {
          searchStore.setFilter(filterCriteria, value);
        } else {
          searchStore.clearFilter(filterCriteria);
        }
      }
    };

    return (
      <BaseNationalIdentifiersFilter
        currentFilters={getCurrentNationalIdentifierFilters()}
        applyFilters={applyNationalIdentifierFilters}
        filterViewMode={props.filterViewMode}
      />
    );
  });
};

export default NationalIdentifiersFilter;
