import React from 'react';
import intl from 'react-intl-universal';
import {IBasicRecord} from 'stores/RecordStore';
import CardTemplate from '../Card/CardTemplate';
import {DTOFileTypes} from '../../types/DTOEntityTypes';
import ParentLink from '../Card/ParentLink';
import {CardType} from '../Card/Card';
import {CardFieldCategory} from '../Card/Footer/CardTooltips';

interface Props {
  basicRecord: IBasicRecord;
  showFile: boolean;
  itemLink?: string;
  parentItemLink?: string;
  cardType: CardType;
  headLineButton?: JSX.Element
}

const CardContent: React.FC<Props> = (props: Props) => {
  let parentLink;

  if (props.showFile) {
    let parentLinkType = '';
    switch (props.basicRecord.file.type) {
      case DTOFileTypes.CaseFile:
        parentLinkType = intl.get('plp.grid.item.field.entity.type.case.file').d('Case File');
        break;
      case DTOFileTypes.Folder:
        parentLinkType = intl.get('plp.grid.item.field.entity.type.folder').d('Folder');
        break;
      case DTOFileTypes.MeetingFolder:
        parentLinkType = intl.get('plp.grid.item.field.entity.type.meeting.folder').d('Meeting');
        break;
      default:
        break;
    }

    parentLink = (
      <ParentLink
        type={parentLinkType}
        number={props.basicRecord.file.fileId}
        title={props.basicRecord.file.title}
        itemLink={props.parentItemLink}
        parentCardType={props.cardType}
      />
    );
  }

  let footerFields = [];

  if (props.basicRecord.createdDate) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.created.date').d('Created date'),
      value: props.basicRecord.createdDate,
      category: CardFieldCategory.Date,
    });
  }

  if (props.basicRecord.recordDate) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.record.date').d('Record date'),
      value: props.basicRecord.recordDate,
      category: CardFieldCategory.Date,
    });
  }

  if (props.basicRecord.documentCount !== null) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.documents').d('Documents'),
      value: props.basicRecord.documentCount,
      category: CardFieldCategory.Document,
    });
  }

  if (props.basicRecord.screening) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.screened').d('Screened'),
      value: props.basicRecord.screening.name,
      category: CardFieldCategory.Screening,
    });
  }

  return (
    <CardTemplate
      entityIdFieldTitle={intl.get('plp.grid.item.field.common.record.ident')}
      entityTitle={intl.get('plp.grid.item.field.entity.type.basic.record').d('Record')}
      entityTypeCls="basic-record"
      abbreviation={intl.get('plp.grid.item.field.entity.type.abbrev.basic.record').d('R')}
      itemNumber={props.basicRecord.recordId}
      title={props.basicRecord.title}
      parentLink={parentLink}
      footerFields={footerFields}
      itemLink={props.itemLink}
      cardType={props.cardType}
      screened={!!props.basicRecord.screening}
      headLineButton={props.headLineButton}
    />
  );
};

export default CardContent;
