import {action, observable} from 'mobx';
import client from 'client/client';
import {PaginatableStore} from './PaginatableStore';
import {TreeStore, Nodes} from './TreeStore';
import {IRecord, RecordStore} from './RecordStore';
import {NavigationStore} from './NavigationStore';
import {FileStore, IFile} from './FileStore';
import {DocumentTypes, RecordTypes} from 'types/EntityTypes';
import {DTODocumentTypes} from 'types/DTOEntityTypes';
import {IEntitiesList} from '../types/IEntitiesList';
import {IType} from '../types/IType';
import {INoarkEntity} from '../types/INoarkEntity';
import {UIBlockerStore} from './UIBlockerStore';
import {SearchStore} from './SearchStore';
import {isNotFound} from '../utils/errorUtils';

export interface IDocument extends INoarkEntity<DTODocumentTypes> {
  type: DTODocumentTypes.Document;
  documentType: string | null;
  recordAssociation: string;
  fileContentId: string | null;
  contentType: string | null;
  fileSize: string | null;
}

export interface IDocumentsResponse {
  hasMore: boolean;
  page: number;
  pageSize: number;
  results: Array<IDocument>;
}

export class DocumentStore extends PaginatableStore
  implements IEntitiesList<DocumentTypes, IDocument>, IType<DocumentTypes> {
  private readonly treeStore: TreeStore;
  private readonly navigationStore: NavigationStore;
  private readonly searchStore: SearchStore;
  private readonly fileStore: FileStore<IFile>;
  private readonly recordStore: RecordStore<IRecord, FileStore<IFile>>;
  private readonly uiBlockerStore: UIBlockerStore;

  constructor(
    treeStore: TreeStore,
    navigationStore: NavigationStore,
    searchStore: SearchStore,
    fileStore: FileStore<IFile>,
    recordStore: RecordStore<IRecord, FileStore<IFile>>,
    uiBlockerStore: UIBlockerStore,
  ) {
    super();
    this.treeStore = treeStore;
    this.navigationStore = navigationStore;
    this.searchStore = searchStore;
    this.fileStore = fileStore;
    this.recordStore = recordStore;
    this.uiBlockerStore = uiBlockerStore;
  }

  @observable
  type: DocumentTypes = DocumentTypes.Document;

  @action.bound
  public setType(type: DocumentTypes): void {
    this.type = type;
  }

  @action.bound
  public changePage(page: number): void {
    if (this.recordStore.selectedEntityId) {
      if (this.treeStore.rootNode === Nodes.File) {
        this.navigationStore.goToLeafDocuments(
          this.recordStore.type,
          this.recordStore.selectedEntityId,
          this.type,
          page,
          this.pageSize,
        );
      } else {
        this.navigationStore.goToBranchDocuments(
          this.recordStore.type as RecordTypes.RegistryEntry,
          this.recordStore.selectedEntityId,
          this.type,
          page,
          this.pageSize,
        );
      }
    }
  }

  public getUrlForPage(page: number): string {
    if (this.recordStore.selectedEntityId) {
      if (this.treeStore.rootNode === Nodes.File) {
        return this.navigationStore.getLeafDocumentsUrl(
          this.recordStore.type,
          this.recordStore.selectedEntityId,
          this.type,
          page,
          this.pageSize,
        );
      } else {
        return this.navigationStore.getBranchDocumentsUrl(
          this.recordStore.type as RecordTypes.RegistryEntry,
          this.recordStore.selectedEntityId,
          this.type,
          page,
          this.pageSize,
        );
      }
    } else return '/';
  }

  @observable
  entitiesList: Array<IDocument> = [];

  @action.bound
  public async fetchEntities(page: number): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.setIsLoading(true);

    const actionId = this.uiBlockerStore.notifyActionStarted();

    try {
      const recordType: RecordTypes = this.recordStore.type;
      const recordId: string | null = this.recordStore.selectedEntityId;

      if (!recordId) {
        this.setIsLoading(false);
        return;
      }

      let archiveEntitiesResponse: IDocumentsResponse;

      archiveEntitiesResponse = await client.fetchDocumentsByRecordId(
        recordType,
        recordId,
        this.type,
        page,
        this.pageSize,
      );

      this.page = page;
      this.entitiesList = archiveEntitiesResponse.results;
      this.hasMore = archiveEntitiesResponse ? archiveEntitiesResponse.hasMore : false;

      this.treeStore.setCurrentNode(Nodes.Document);
    } catch (error) {
      if (isNotFound(error)) {
        this.navigationStore.redirectToNotFound();
      }
    } finally {
      this.setIsLoading(false);
      this.uiBlockerStore.notifyActionCompleted(actionId);
    }
  }

  @action.bound
  public clearEntities(): void {
    this.entitiesList = [];
  }
}
