import {AxiosError} from 'axios';
import {IErrorResponse, instanceOfIErrorResponse} from '../types/IErrorResponse';

export function isNotFound(error: AxiosError): boolean {
  return getStatusCode(error) === 404;
}

export function getStatusCode(error: AxiosError): number | null {
  const errorResponse: IErrorResponse | null = getErrorResponse(error);
  if (errorResponse) {
    return errorResponse.statusCode;
  }
  return null;
}

export function getErrorResponse(error: AxiosError): IErrorResponse | null {
  if (error.response && error.response.data && instanceOfIErrorResponse(error.response.data)) {
    return error.response.data;
  }
  return null;
}