import React from 'react';
import CardContent from './CardContent';
import {useRootStore} from 'stores/index';
import {IBasicRecord} from 'stores/RecordStore';
import useDefaultProps from 'hooks/useDefaultProps';
import {DocumentTypes, RecordTypes} from 'types/EntityTypes';
import {BaseCardProps} from '../Card/Card';
import {canOpen} from 'utils/recordUtils';
import {Nodes} from '../../stores/TreeStore';
import {convertFromDTOFileType} from '../../utils/entityTypeConversion';

interface Props extends BaseCardProps {
  basicRecord: IBasicRecord;
  showFile?: boolean;
  headLineButton?: JSX.Element;
}

const propDefaults: Partial<Props> = {
  showFile: false,
};

const BasicRecord: React.FC<Props> = (props: Props) => {
  props = useDefaultProps(props, propDefaults);

  const rootStore = useRootStore();
  const documentStore = rootStore.documentStore;
  const navigationStore = rootStore.navigationStore;
  const treeStore = rootStore.treeStore;
  const recordStore = rootStore.recordStore;

  const recordRoot: boolean = treeStore.rootNode === Nodes.Record;

  const itemLink = recordRoot
    ? navigationStore.getBranchDocumentsUrl(
        RecordTypes.BasicRecord,
        props.basicRecord.id,
        DocumentTypes.Document,
        1,
        documentStore.pageSize,
      )
    : navigationStore.getLeafDocumentsUrl(
        RecordTypes.BasicRecord,
        props.basicRecord.id,
        DocumentTypes.Document,
        1,
        documentStore.pageSize,
      );

  const parentItemLink = navigationStore.getBranchRecordsUrl(
    convertFromDTOFileType(props.basicRecord.file.type),
    props.basicRecord.file.id,
    1,
    recordStore.pageSize,
  );

  return (
    <CardContent
      basicRecord={props.basicRecord}
      showFile={!!props.showFile}
      itemLink={!props.isOpen && canOpen(props.basicRecord) ? itemLink : undefined}
      parentItemLink={recordRoot ? parentItemLink : undefined}
      cardType={props.cardType}
      headLineButton={props.headLineButton}
    />
  );
};

export default BasicRecord;
