import React, {useRef} from 'react';
import intl from 'react-intl-universal';
import ChevronRightParentLink from '../Icons/ChevronRightParentLink';
import {Link} from 'react-router-dom';
import {CardType} from './Card';
import './css/parent-link.scss';

interface Props {
  type: string;
  number: string;
  title: string | null;
  itemLink?: string;
  parentCardType: CardType;
}

const ParentLink = (props: Props) => {
  const linkRef: React.RefObject<HTMLAnchorElement> = useRef<HTMLAnchorElement>(null);

  let downTime = 0,
    upTime = 0;

  const onMouseDown = (e: React.MouseEvent) => {
    downTime = +new Date();
  };

  const onMouseUp = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (props.itemLink) {
      upTime = +new Date();
      if (upTime - downTime < 200 && linkRef && linkRef.current) {
        linkRef.current.click();
      } else {
        //the user might be taking longer to select a text in the card
      }
    }
  };

  const title = props.title !== null ?
    props.title : intl.get('plp.grid.item.no.public.title.placeholder').d('(no public title)');

  const linkContent = props.itemLink ? (
    <Link
      to={props.itemLink}
      onMouseUp={e => {
        e.stopPropagation();
      }}
      innerRef={linkRef}
    >
      <span className="parent-id">{props.number} </span>
      <span className="parent-title">{title}</span>
    </Link>
  ) : (
    <>
      <span className="parent-id">{props.number} </span>
      <span className="parent-title">{title}</span>
    </>
  );

  return (
    <section
      className={`item-parent-wrapper ${props.itemLink ? 'item-link' : ''}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <p className="item-parent-type">{props.type}</p>
      {props.parentCardType === CardType.MainItem ? (
        <h2 className="item-parent-id-title">{linkContent}</h2>
      ) : props.parentCardType === CardType.ListItem ? (
        <h3 className="item-parent-id-title">{linkContent}</h3>
      ) : (
        <h4 className="item-parent-id-title">{linkContent}</h4>
      )}
      {props.itemLink && <ChevronRightParentLink />}
    </section>
  );
};

export default ParentLink;
