import React, {useEffect} from 'react';

export default function useCloseMenuEffect(
  menu: React.RefObject<HTMLElement | undefined>,
  showMenu: boolean,
  setShowMenu: (showMenu: boolean) => void,
  disableEffect: boolean = false,
) {
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (!showMenu) {
        return;
      }
      if (menu && menu.current) {
        if (!menu.current.contains(e.target)) {
          setShowMenu(false);
        }
      }
    };

    const handleEscape = (e: any) => {
      if (!showMenu) {
        return;
      }
      if (e.keyCode === 27) {
        setShowMenu(false);
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (!disableEffect) {
      document.addEventListener('mousedown', handleOutsideClick, false);
      document.addEventListener('keydown', handleEscape, false);

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick, false);
        document.removeEventListener('keydown', handleEscape, false);
      };
    }
  }, [showMenu, menu]);
}
