import React from 'react';
import {useRootStore} from '../../stores';
import {useObserver} from 'mobx-react-lite';
import './css/ui-blocker.scss';

const UIBlocker: React.FC = ({children}) => {
  const rootStore = useRootStore();
  const uibBlockerStore = rootStore.uiBlockerStore;

  return useObserver(() => (
    <div className={uibBlockerStore.blocked ? 'ui-blocker blocked' : 'ui-blocker unblocked'}>
      <div className="progress-bar">
        <div className="indeterminate"></div>
      </div>
      {children}
    </div>
  ));
};

export default UIBlocker;
