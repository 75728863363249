import React from 'react';
import CardContent from './CardContent';
import {useRootStore} from 'stores/index';
import {IFolder} from 'stores/FileStore';
import {BaseCardProps} from '../Card/Card';
import {FileTypes} from '../../types/EntityTypes';

interface Props extends BaseCardProps {
  folder: IFolder;
}

const Folder: React.FC<Props> = (props: Props) => {
  const {folder, cardType, isOpen} = props;

  const rootStore = useRootStore();
  const recordStore = rootStore.recordStore;
  const navigationStore = rootStore.navigationStore;

  const itemLink = navigationStore.getBranchRecordsUrl(FileTypes.Folder, props.folder.id, 1, recordStore.pageSize);

  return (
    <CardContent
      folder={folder}
      cardType={cardType}
      itemLink={!isOpen ? itemLink : undefined}
    />
  );
};

export default Folder;
