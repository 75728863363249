import React, {useState} from 'react';
import {v4 as uuidv4} from 'uuid';

interface RadioOption {
  label: string;
  value: string;
}

interface Props {
  groupLabel: string;
  containerClassName: string;
  radioGroupClassName: string;
  options: RadioOption[];
  selectedOption: string;
  setSelectedOption: (selectedOption: string) => void;
  onEnter: (selectedRadio: string) => void;
  onEsc: () => void;
}

const CustomRadioGroup: React.FC<Props> = (props: Props) => {
  const [htmlId] = useState(uuidv4());

  const radioGroupLabelId = `radio-group-label-${htmlId}`;

  const onRadioKeyDown = (e: React.KeyboardEvent, option: string, index: number) => {
    switch (e.keyCode) {
      //Enter
      case 13:
        props.onEnter(props.selectedOption);
        break;
      //Esc
      case 27:
        props.onEsc();
        break;
      //Arrow down/right -> uncheck option, move focus to the next option and check it
      case 39:
      case 40: {
        let nextIndex;
        if (index === props.options.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex = index + 1;
        }
        processSelectedRadio(nextIndex);
        break;
      }
      //Arrow up/left -> uncheck option, move focus to the previous option and check it
      case 37:
      case 38: {
        let prevIndex;
        if (index === 0) {
          prevIndex = props.options.length - 1;
        } else {
          prevIndex = index - 1;
        }
        processSelectedRadio(prevIndex);
        break;
      }
      default:
        break;
    }
  };

  const processSelectedRadio = (index: number) => {
    const option = props.options[index];
    focusSearchCriterion(index);
    props.setSelectedOption(option.value);
  };

  const focusSearchCriterion = (index: number) => {
    const el = document.getElementById(`radio-${htmlId}-${index}`);
    if (el) {
      el.focus();
    }
  };

  return (
    <>
      {props.options.length > 0 && (
        <div role="radiogroup" aria-labelledby={radioGroupLabelId} className={props.containerClassName}>
          <label id={radioGroupLabelId}>{props.groupLabel}</label>
          <div className={props.radioGroupClassName}>
            {props.options.map((opt, index) => {
              const checked = opt.value === props.selectedOption;
              const optionLabelId = `radio-label-${htmlId}-${index}`;
              return (
                <div
                  key={opt.value}
                  id={`radio-${htmlId}-${index}`}
                  role="radio"
                  aria-checked={checked}
                  aria-labelledby={optionLabelId}
                  tabIndex={checked ? 0 : -1}
                  onClick={() => {
                    props.setSelectedOption(opt.value);
                  }}
                  onKeyDown={e => onRadioKeyDown(e, opt.value, index)}
                  className={checked ? 'focused' : ''}
                >
                  <span id={optionLabelId}>{opt.label}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomRadioGroup;
