import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {useObserver} from 'mobx-react-lite';
import {FileTypes, FileUnion, RecordTypes, DocumentTypes} from 'types/EntityTypes';
import PublicLookupPortal from './PublicLookupPortal';
import NotFound from './NotFound/NotFound';
import useOrganizationAssets from '../hooks/useOrganizationAssets';
import {useRootStore} from '../stores';
import useTranslations from '../hooks/useTranslations';
import {autorun} from 'mobx';
import {setDocumentTitle} from '../utils/documentTitle';

const Routing: React.FC = () => {
  const baseUrl: string = `${process.env.PUBLIC_URL}`;
  const {organizationAssets, organizationAssetsLoaded} = useOrganizationAssets();
  const rootStore = useRootStore();
  const treeStore = rootStore.treeStore;
  const navigationStore = rootStore.navigationStore;
  const searchStore = rootStore.searchStore;
  const uibBlockerStore = rootStore.uiBlockerStore;

  const {loadedLocale, selectedLocale, setSelectedLocale} = useTranslations(
    uibBlockerStore.notifyActionStarted,
    uibBlockerStore.notifyActionCompleted,
    organizationAssetsLoaded,
    organizationAssets?.defaultLocale
  );


  return useObserver(() => {
    //In case of first time render or error in translation loading, return nothing:
    if (!loadedLocale || !organizationAssetsLoaded || !selectedLocale) {
      return null;
    }

    autorun(() => {
      setDocumentTitle(
        navigationStore.pageNotFound,
        treeStore.isEntitySelected,
        searchStore.selectedSearchType,
        rootStore.parentEntity,
        organizationAssets?.name,
      );
    });

    return (
      <Router history={navigationStore.history}>
        <Switch>
          <Route
            exact={true}
            path={[
              // Root files...
              `${baseUrl}`,

              `${baseUrl}/${FileTypes.FileSubset}`,
              `${baseUrl}/${FileTypes.MeetingFolder}`,

              //...with modal records
              `${baseUrl}/${FileTypes.CaseFile}/:id/${RecordTypes.RecordUnion}`,
              `${baseUrl}/${FileTypes.Folder}/:id/${RecordTypes.RecordUnion}`,
              `${baseUrl}/${FileTypes.MeetingFolder}/:id/${RecordTypes.RecordUnion}`,

              //...with modal documents
              `${baseUrl}/${FileUnion}/${RecordTypes.RegistryEntry}/:id/${DocumentTypes.Document}`,
              `${baseUrl}/${FileUnion}/${RecordTypes.BasicRecord}/:id/${DocumentTypes.Document}`,
              `${baseUrl}/${FileUnion}/${RecordTypes.MeetingRecord}/:id/${DocumentTypes.Document}`,

              // Root records...
              `${baseUrl}/${RecordTypes.RecordSubset}`,

              //...with modal documents
              `${baseUrl}/${RecordTypes.RegistryEntry}/:id/${DocumentTypes.Document}`,
              `${baseUrl}/${RecordTypes.BasicRecord}/:id/${DocumentTypes.Document}`,
            ]}
            render={() => (
              <PublicLookupPortal
                selectedLocale={selectedLocale}
                loadedLocale={loadedLocale}
                setSelectedLocale={setSelectedLocale}
                organizationAssets={organizationAssets}
              />
            )}
          />
          <Route
            exact={true}
            path={`${baseUrl}/not-found`}
            render={() => <NotFound organizationAssets={organizationAssets} />}
          />
          <Route render={() => <NotFound organizationAssets={organizationAssets} />} />
        </Switch>
      </Router>
    );
  });
};

export default Routing;
