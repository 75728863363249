import {observable, action} from 'mobx';
import IPaginatable from 'types/IPaginatable';

export const DEFAULT_SEARCH_PAGE_SIZE: number = 10;
export const VALID_SEARCH_PAGE_SIZES: number[] = [DEFAULT_SEARCH_PAGE_SIZE, 20, 50];

export abstract class PaginatableStore implements IPaginatable {
  @observable
  isLoading: boolean = false;

  @action.bound
  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @observable
  pageSize: number = DEFAULT_SEARCH_PAGE_SIZE;

  @action.bound
  public setPageSize(pageSize: number): void {
    if (pageSize > 0 && pageSize !== this.pageSize) {
      this.pageSize = pageSize;
    }
  }

  @observable
  page: number = 1;

  @action.bound
  public nextPage(): void {
    if (this.hasMore && !this.isLoading) {
      this.changePage(this.page + 1);
    }
  }

  @action.bound
  public prevPage(): void {
    if (this.page > 1 && !this.isLoading) {
      this.changePage(Math.max(this.page - 1, 1));
    }
  }

  @action.bound
  public goBackTwoPages(): void {
    if (this.page > 2 && !this.isLoading) {
      this.changePage(Math.max(this.page - 2, 1));
    }
  }

  @action.bound
  public goToFirstPage(): void {
    if (this.page !== 1 && !this.isLoading) {
      this.changePage(1);
    }
  }

  @observable
  hasMore: boolean = false;

  public abstract changePage(page: number): void;

  public abstract getUrlForPage(page: number): string;
}
