import {DateRangePickerPhrases} from 'react-dates';
import intl from 'react-intl-universal';

interface LocalizedDate {
  date: string;
}

export const getDateRangePickerPhrases = (): DateRangePickerPhrases => {
  return {
    calendarLabel: intl.get('plp.filter.calendar.label').d('Calendar'),
    closeDatePicker: intl.get('plp.filter.calendar.close.datepicker').d('Close'),
    clearDates: intl.get('plp.filter.calendar.clear.dates').d('Clear dates'),
    focusStartDate: intl
      .get('plp.filter.calendar.focus.start.date')
      .d('Interact with the calendar and select start and/or end date for your date range.'),
    jumpToPrevMonth: intl
      .get('plp.filter.calendar.jump.to.prev.month')
      .d('Move backward to switch to the previous month.'),
    jumpToNextMonth: intl.get('plp.filter.calendar.jump.to.next.month').d('Move forward to switch to the next month.'),
    keyboardShortcuts: intl.get('plp.filter.calendar.keyboard.shortcuts').d('Keyboard Shortcuts'),
    showKeyboardShortcutsPanel: intl
      .get('plp.filter.calendar.show.keyboard.shortcuts.panel')
      .d('Open the keyboard shortcuts panel.'),
    hideKeyboardShortcutsPanel: intl
      .get('plp.filter.calendar.hide.keyboard.shortcuts.panel')
      .d('Close the shortcuts panel.'),
    openThisPanel: intl.get('plp.filter.calendar.open.this.panel').d('Open this panel.'),
    enterKey: intl.get('plp.filter.calendar.enter.key').d('Enter key'),
    leftArrowRightArrow: intl.get('plp.filter.calendar.left.arrow.right.arrow').d('Right and left arrow keys'),
    upArrowDownArrow: intl.get('plp.filter.calendar.up.arrow.down.arrow').d('Up and down arrow keys'),
    pageUpPageDown: intl.get('plp.filter.calendar.page.up.page.down').d('Page up and page down keys'),
    homeEnd: intl.get('plp.filter.calendar.home.end').d('Home and end keys'),
    escape: intl.get('plp.filter.calendar.escape').d('Escape key'),
    questionMark: intl.get('plp.filter.calendar.question.mark').d('Question mark'),
    selectFocusedDate: intl.get('plp.filter.calendar.select.focused.date').d('Select the date in focus.'),
    moveFocusByOneDay: intl
      .get('plp.filter.calendar.move.focus.by.ene.day')
      .d('Move backward (left) and forward (right) by one day.'),
    moveFocusByOneWeek: intl
      .get('plp.filter.calendar.move.focus.by.one.week')
      .d('Move backward (up) and forward (down) by one week.'),
    moveFocusByOneMonth: intl.get('plp.filter.calendar.move.focus.by.one.month').d('Switch months.'),
    moveFocustoStartAndEndOfWeek: intl
      .get('plp.filter.calendar.move.focus.to.start.and.end.of.week')
      .d('Go to the first or last day of a week.'),
    returnFocusToInput: intl.get('plp.filter.calendar.return.focus.to.input').d('Return to the date input field.'),
    keyboardNavigationInstructions: intl
      .get('plp.filter.calendar.keyboard.navigation.instructions')
      .d('Navigate forward to interact with the calendar and select a date.'),
    chooseAvailableStartDate: (date: string) => {
      let ld: LocalizedDate = (date as unknown) as LocalizedDate;
      let dateStr = ld ? ld.date : date;
      return intl
        .get('plp.filter.calendar.choose.available.start.date', {date: dateStr})
        .d('Choose this date as start date.');
    },
    chooseAvailableEndDate: (date: string) => {
      let ld: LocalizedDate = (date as unknown) as LocalizedDate;
      let dateStr = ld ? ld.date : date;
      return intl.get('plp.filter.calendar.choose.available.end.date', {date: dateStr}).d('Choose this as end date.');
    },
    dateIsSelected: (date: string) => {
      let ld: LocalizedDate = (date as unknown) as LocalizedDate;
      let dateStr = ld ? ld.date : date;
      return intl.get('plp.filter.calendar.date.is.selected', {date: dateStr}).d('Date selected');
    },
  };
};
