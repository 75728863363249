import React from 'react';
import intl from 'react-intl-universal';
import {IMeetingRecord} from 'stores/RecordStore';
import CardTemplate from '../Card/CardTemplate';
import ParentLink from '../Card/ParentLink';
import {CardType} from '../Card/Card';
import {CardFieldCategory} from '../Card/Footer/CardTooltips';

interface Props {
  meetingRecord: IMeetingRecord;
  showFile: boolean;
  itemLink?: string;
  cardType: CardType;
  headLineButton?: JSX.Element
}

const CardContent: React.FC<Props> = (props: Props) => {
  let parentLink;
  if (props.showFile) {
    parentLink = (
      <ParentLink
        number={props.meetingRecord.meetingFolder.fileId}
        title={props.meetingRecord.meetingFolder.title}
        type={intl.get('plp.grid.item.field.entity.type.meeting.folder').d('Meeting')}
        parentCardType={props.cardType}
      />
    );
  }

  let footerFields = [];

  if (props.meetingRecord.meetingRecordStatus) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.status').d('Status'),
      value: props.meetingRecord.meetingRecordStatus,
      className: 'status',
      category: CardFieldCategory.TypeAndStatus,
    });
  }
  if (props.meetingRecord.meetingRecordType) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.type').d('Type'),
      value: props.meetingRecord.meetingRecordType,
      className: 'status',
      category: CardFieldCategory.TypeAndStatus,
    });
  }

  footerFields.push({
    name: intl.get('plp.grid.item.field.common.created.date').d('Created date'),
    value: props.meetingRecord.createdDate,
    category: CardFieldCategory.Date,
  });

  if (props.meetingRecord.documentCount !== null) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.documents').d('Documents'),
      value: props.meetingRecord.documentCount,
      category: CardFieldCategory.Document,
    });
  }

  if (props.meetingRecord.screening) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.screened').d('Screened'),
      value: props.meetingRecord.screening.name,
      category: CardFieldCategory.Screening,
    });
  }

  return (
    <CardTemplate
      entityIdFieldTitle={intl.get('plp.grid.item.field.common.record.ident')}
      entityTitle={intl.get('plp.grid.item.field.entity.type.meeting.record').d('Meeting record')}
      entityTypeCls="meeting-record"
      abbreviation={intl.get('plp.grid.item.field.entity.type.abbrev.meeting.record').d('MR')}
      itemNumber={props.meetingRecord.caseNumber || props.meetingRecord.recordId}
      title={props.meetingRecord.title}
      parentLink={parentLink}
      footerFields={footerFields}
      itemLink={props.itemLink}
      cardType={props.cardType}
      screened={!!props.meetingRecord.screening}
      headLineButton={props.headLineButton}
    />
  );
};

export default CardContent;
