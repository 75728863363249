import {SearchableType} from '../stores/SearchStore';
import intl from 'react-intl-universal';
import {getSearchableTypeLabel} from './entityTypeLabels';
import {INoarkEntity} from '../types/INoarkEntity';

export function setDocumentTitle(
  pageNotFound: boolean,
  isEntitySelected: boolean,
  selectedSearchableType: SearchableType,
  parentEntity: INoarkEntity | null,
  organizationName: string | undefined,
): void {
  if (pageNotFound) {
    document.title = intl.get('plp.page.not.found.msg').d('OOPS! Page not found');
  } else {
    let title = document.title;

    if (!isEntitySelected) {
      title = getSearchableTypeLabel(selectedSearchableType) + `${organizationName ? ' - ' + organizationName : ''}`;
    } else {
      const parentEntityTitle = parentEntity?.title;

      if (parentEntityTitle) {
        title = parentEntityTitle + `${organizationName ? ' - ' + organizationName : ''}`;
      }
    }

    if (title !== document.title) {
      document.title = title;
    }
  }
}
