import {IProperty} from 'stores/FileStore';

export const identifiersToString = (properties: Array<IProperty>): string => {

  const distinctKnr: Set<string> = new Set(properties.map(item => item.knr));
  const showKnr: boolean = distinctKnr.size > 1;

  const identifierStrings: Array<string> = [];

  for (let i = 0; i < properties.length; i++) {

    const property: IProperty = properties[i];

    let identifierString: string = `${property.gnr}/${property.bnr}`;

    if (property.fnr || property.snr) {
      identifierString = `${identifierString}/${property.fnr || ''}/${property.snr || ''}`;
    }

    if (showKnr) {
      identifierString = `${property.knr} - ${identifierString}`;
    }

    identifierStrings.push(identifierString);
  }

  return identifierStrings.join(', ');
};
