import {SearchableType} from '../stores/SearchStore';
import {FileTypes, RecordTypes} from '../types/EntityTypes';
import intl from "react-intl-universal";
import {Nodes} from '../stores/TreeStore';

export function getSearchableTypeLabel(type: SearchableType) {
  switch (type) {
    case FileTypes.FileSubset:
      return intl.get('plp.filter.entity.type.file.subset').d('Case files');
    case FileTypes.MeetingFolder:
      return intl.get('plp.filter.entity.type.meeting.folders').d('Meetings');
    case RecordTypes.RecordSubset:
      return intl.get('plp.filter.entity.type.public.records').d('Public records');
    default: return '';
  }
}

export function getNodeLabel(node: Nodes) {
  switch (node) {
    case Nodes.File: return intl.get('plp.grid.header.files').d('Files');
    case Nodes.Record: return intl.get('plp.grid.header.records').d('Records');
    case Nodes.Document:
      return intl.get('plp.grid.header.documents').d('Documents');
    default:
      return '';
  }
}