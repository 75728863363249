import PLPClient from './PLPClient';
import ClientProvider from './ClientProvider';
import {AxiosRequestConfig} from 'axios';

const timeout = parseInt(process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS || '0');

const options: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'Application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: timeout ? timeout * 1000 : 0
};

const client = new PLPClient(
  ClientProvider(process.env.REACT_APP_PLP_URI as string, '', undefined, undefined, options),
);

export default client;
