import {useEffect} from 'react';
import {PortalRouteProps} from '../types/PortalRouteProps';
import {DocumentTypes, FileTypes, RecordTypes} from '../types/EntityTypes';
import {Nodes} from '../stores/TreeStore';
import {useRootStore} from '../stores';
import {IBasicRecord, IMeetingRecord, IRecord, IRegistryEntry} from '../stores/RecordStore';
import {FilterCriteria} from '../stores/SearchStore';
import {DTOFileTypes} from '../types/DTOEntityTypes';
import {convertFromDTOFileType} from '../utils/entityTypeConversion';
import useOrganizationAssets from './useOrganizationAssets';

export default function useInitializeTree(
  portalRouteProps: PortalRouteProps,
  setRootPortalRouteProps: (rootRouteProps: PortalRouteProps) => void,
  setChildrenOpen: (childrenOpen: boolean) => void,
  setPortalClass: (portalClass: string) => void,
) {
  const {page, pageSize, rootNode, type, searchCriterion, searchValue, filters, id, childrenType} = portalRouteProps;
  const organizationAssets = useOrganizationAssets();

  const rootStore = useRootStore();

  const treeStore = rootStore.treeStore;
  const searchStore = rootStore.searchStore;
  const fileStore = rootStore.fileStore;
  const recordStore = rootStore.recordStore;
  const documentStore = rootStore.documentStore;

  async function initializeTree() {
    const childrenOpen: boolean = !!(id && childrenType);
    const portalClass = childrenOpen ? 'parent-grid-container' : '';

    setChildrenOpen(childrenOpen);
    setPortalClass(portalClass);

    fileStore.setSelectedEntityId(null);
    recordStore.setSelectedEntityId(null);

    fileStore.clearEntities();
    recordStore.clearEntities();
    documentStore.clearEntities();

    switch (type) {
      case FileTypes.CaseFile:
      case FileTypes.MeetingFolder:
      case FileTypes.Folder:
      case FileTypes.FileSubset:
        if (id && childrenType) {
          // Branch
          recordStore.setType(childrenType as RecordTypes);
          recordStore.setPageSize(pageSize);

          // Root
          fileStore.setType(type as FileTypes);
          fileStore.setSelectedEntityId(id);
          await fileStore.fetchSelectedEntity();

          treeStore.setCurrentNode(Nodes.Record);
        } else {
          // Root
          fileStore.setType(type as FileTypes);
          fileStore.setPageSize(pageSize);

          treeStore.setCurrentNode(Nodes.File);
        }

        break;
      case RecordTypes.RegistryEntry:
      case RecordTypes.MeetingRecord:
      case RecordTypes.BasicRecord:
      case RecordTypes.RecordSubset:
        if (id && childrenType) {
          // Branch
          documentStore.setType(childrenType as DocumentTypes);
          documentStore.setPageSize(pageSize);

          // Root
          recordStore.setType(type as RecordTypes);
          recordStore.setSelectedEntityId(id);
          await recordStore.fetchSelectedEntity();

          let selectedRecord: IRecord | null = recordStore.selectedEntity;
          let parentId: string | null = null;
          let parentType: DTOFileTypes | null = null;

          if (selectedRecord !== null) {
            switch (type) {
              case RecordTypes.RegistryEntry:
                parentId = (selectedRecord as IRegistryEntry).file.id;
                parentType = (selectedRecord as IRegistryEntry).file.type;
                break;
              case RecordTypes.MeetingRecord:
                parentId = (selectedRecord as IMeetingRecord).meetingFolder.id;
                parentType = (selectedRecord as IMeetingRecord).meetingFolder.type;
                break;
              case RecordTypes.BasicRecord:
                parentId = (selectedRecord as IBasicRecord).file.id;
                parentType = (selectedRecord as IBasicRecord).file.type;
                break;
              default:
                break;
            }
          }

          if (parentId && parentType) {
            fileStore.setSelectedEntityId(parentId);
            fileStore.setType(convertFromDTOFileType(parentType));
            await fileStore.fetchSelectedEntity();
          }

          treeStore.setCurrentNode(Nodes.Document);
        } else {
          // Root
          recordStore.setType(type as RecordTypes);
          recordStore.setPageSize(pageSize);

          treeStore.setCurrentNode(Nodes.Record);
        }

        break;
      default:
        break;
    }

    treeStore.setRootNode(rootNode);

    searchStore.setSelectedSearchCriterion(searchCriterion);
    searchStore.setSearchCriterionValue(searchValue);
    searchStore.setValueLastSearched(searchValue);

    searchStore.clearAllFilters();
    filters.forEach((filterValue: string, filterKey: FilterCriteria) => {
      searchStore.setFilter(filterKey, filterValue);
    });

    switch (treeStore.currentNode) {
      case Nodes.File:
        fileStore.fetchEntities(page);
        break;
      case Nodes.Record:
        const excludedMeetingTypes = organizationAssets.getAllMeetingTypesForExclusion();
        recordStore.fetchEntities(page, undefined, excludedMeetingTypes);
        break;
      case Nodes.Document:
        documentStore.fetchEntities(page);
        break;
      default:
        break;
    }

    if (rootNode === treeStore.currentNode) {
      setRootPortalRouteProps(portalRouteProps);
    }
  }

  const assetsLoaded = organizationAssets?.organizationAssetsLoaded;
  useEffect(() => {
    if (assetsLoaded) {
      initializeTree();
    }
  }, [
    page,
    pageSize,
    rootNode,
    type,
    id,
    childrenType,
    searchCriterion,
    searchValue,
    filters,
    assetsLoaded,
  ]);
}
