import React, {useEffect} from 'react';
import intl from 'react-intl-universal';
import {autorun} from 'mobx';
import {useObserver} from 'mobx-react-lite';
import {useRootStore} from 'stores/index';
import {default as ChevronLeftIcon} from 'components/Icons/ChevronLeft';
import {default as ChevronRightIcon} from 'components/Icons/ChevronRight';
import {Link} from 'react-router-dom';
import './css/pagination.scss';

const Pagination: React.FC = () => {
  const rootGrid: HTMLElement | null = document.getElementById('grid-content');

  const rootStore = useRootStore();

  useEffect(
    () =>
      autorun(() => {
        const store = rootStore.paginatableNodeStore;
        const page: number = store.page;

        if (rootGrid) rootGrid.scrollTop = 0;
      }),
    [rootGrid], // note no mobx observables are in dep array, we use autorun instead
  );

  return useObserver(() => {
    const store = rootStore.paginatableNodeStore;

    if (store.page === 1 && !store.hasMore) return null;

    const goToPageLabel = intl.get('plp.grid.pagination.go.to').d('Go to page');

    return (
      <nav aria-label={`${intl.get('plp.grid.pagination.title').d('Page navigation')}`} className="card pagination">
        {store.page === 1 ? (
          <span className="btn btn-icon disabled">
            <ChevronLeftIcon />
          </span>
        ) : (
          <Link to={store.getUrlForPage(store.page - 1)} className="btn btn-icon">
            <span className="sr-only">{intl.get('plp.grid.pagination.previous').d('Previous')}</span>
            <ChevronLeftIcon />
          </Link>
        )}
        <div>
          {store.page > 3 && (
            <>
              <Link to={store.getUrlForPage(1)} aria-label={`${goToPageLabel} 1`} className="btn">
                1
              </Link>
              {store.page > 4 && (
                <span className="triple-dot">...</span>
              )}
            </>
          )}
          {store.page > 2 && (
            <Link
              to={store.getUrlForPage(store.page - 2)}
              aria-label={`${goToPageLabel} ${store.page - 2}`}
              className="btn"
            >
              {store.page - 2}
            </Link>
          )}
          {store.page > 1 && (
            <Link
              to={store.getUrlForPage(store.page - 1)}
              aria-label={`${goToPageLabel} ${store.page - 1}`}
              className="btn"
            >
              {store.page - 1}
            </Link>
          )}
          <span className="current-page">{store.page}</span>
          {store.hasMore && (
            <Link
              to={store.getUrlForPage(store.page + 1)}
              aria-label={`${goToPageLabel} ${store.page + 1}`}
              className="btn"
            >
              {store.page + 1}
            </Link>
          )}
        </div>
        {!store.hasMore ? (
          <span className="btn btn-icon disabled">
            <ChevronRightIcon />
          </span>
        ) : (
          <Link to={store.getUrlForPage(store.page + 1)} className="btn btn-icon">
            <span className="sr-only">{intl.get('plp.grid.pagination.next').d('Next')}</span>
            <ChevronRightIcon />
          </Link>
        )}
      </nav>
    );
  });
};

export default Pagination;
