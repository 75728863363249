import {FileSubsetSearchCriteria, MeetingFolderSearchCriteria} from 'stores/FileStore';
import {RecordSubsetSearchCriteria} from 'stores/RecordStore';
import {FilterCriteria} from '../stores/SearchStore';

interface IGetParameters {
  [key: string]: string | number;
}

export const getParametersString = (page: number, pageSize: number): string => {
  const getParameters: IGetParameters = {
    page,
    pageSize,
  };
  return getQueryStringForParameters(getParameters);
};

export const getSearchParametersString = (
  page: number,
  pageSize: number,
  searchKey?: FileSubsetSearchCriteria | MeetingFolderSearchCriteria | RecordSubsetSearchCriteria,
  searchValue?: string,
  filters?: Map<FilterCriteria, string>,
): string => {
  const getParameters: IGetParameters = {
    page,
    pageSize,
  };

  if (searchKey && searchValue) {
    getParameters[searchKey] = searchValue;
  }

  if (filters) {
    filters.forEach((value: string, key: FilterCriteria) => {
      getParameters[key] = value;
    });
  }

  return getQueryStringForParameters(getParameters);
};

export const getMeetingBoardParametersString = (page: number, pageSize: number, term: string): string => {
  const getParameters: IGetParameters = {
    page,
    pageSize,
  };

  if (term) {
    getParameters['boardName'] = term;
  }

  return getQueryStringForParameters(getParameters);
};

const getQueryStringForParameters = (parameters: IGetParameters): string => {
  return Object.keys(parameters)
    .map((key: string) => `${key}=${parameters[key]}`)
    .join('&');
};
