import React from 'react';
import Back from '../Icons/Back';
import Cross from '../Icons/Cross';
import {useObserver} from 'mobx-react-lite';
import {useRootStore} from 'stores/index';
import Card, {CardType} from '../Card/Card';
import intl from 'react-intl-universal';
import {ICaseFile, IFolder, IMeetingFolder} from '../../stores/FileStore';
import {IDocument} from '../../stores/DocumentStore';
import {IBasicRecord, IMeetingRecord, IRegistryEntry} from '../../stores/RecordStore';
import './css/parent-item-header.scss';

type Props = {
  enableGoBack: boolean;
  onCloseAll: () => void;
};

const ParentItemHeader: React.FC<Props> = (props: Props) => {
  const rootStore = useRootStore();
  const navigationStore = rootStore.navigationStore;

  return useObserver(() => {
    const onCloseCurrent = () => {
      if (!props.enableGoBack) {
        return;
      }

      navigationStore.goBack();
    };

    let parentEntity:
      | ICaseFile
      | IFolder
      | IDocument
      | IRegistryEntry
      | IBasicRecord
      | IMeetingRecord
      | IMeetingFolder
      | null = rootStore.parentEntity;

    const parentCard = (): JSX.Element => {
      if (parentEntity) {
        return <Card archiveEntity={parentEntity} isOpen={true} cardType={CardType.MainItem} />;
      } else {
        return <></>;
      }
    };

    return (
      <header className="top-bar-parent-item">
        {props.enableGoBack && (
          <button className="btn btn-icon back" onClick={onCloseCurrent}>
            <span className="sr-only">{intl.get('plp.parent.item.header.button.back').d('Back')}</span>
            <Back />
          </button>
        )}
        {parentCard()}
        <button className="btn btn-icon close" onClick={props.onCloseAll}>
          <span className="sr-only">{intl.get('plp.parent.item.header.button.close').d('Close')}</span>
          <Cross />
        </button>
      </header>
    );
  });
};

export default ParentItemHeader;
