import React from 'react';
import {useObserver} from 'mobx-react-lite';
import CardContent from './CardContent';
import {useRootStore} from 'stores/index';
import {Nodes} from 'stores/TreeStore';
import {IRegistryEntry} from 'stores/RecordStore';
import useDefaultProps from 'hooks/useDefaultProps';
import {DocumentTypes, FileTypes, RecordTypes} from 'types/EntityTypes';
import {BaseCardProps} from '../Card/Card';
import {canOpen} from 'utils/recordUtils';

interface Props extends BaseCardProps {
  registryEntry: IRegistryEntry;
  showFile?: boolean;
  headLineButton?: JSX.Element;
}

const propDefaults: Partial<Props> = {
  showFile: false,
};

const RegistryEntry: React.FC<Props> = (props: Props) => {
  props = useDefaultProps(props, propDefaults);

  const rootStore = useRootStore();
  const treeStore = rootStore.treeStore;
  const recordStore = rootStore.recordStore;
  const documentStore = rootStore.documentStore;
  const navigationStore = rootStore.navigationStore;

  return useObserver(() => {
    const recordRoot: boolean = treeStore.rootNode === Nodes.Record;

    const itemLink = recordRoot
      ? navigationStore.getBranchDocumentsUrl(
          RecordTypes.RegistryEntry,
          props.registryEntry.id,
          DocumentTypes.Document,
          1,
          documentStore.pageSize,
        )
      : navigationStore.getLeafDocumentsUrl(
          RecordTypes.RegistryEntry,
          props.registryEntry.id,
          DocumentTypes.Document,
          1,
          documentStore.pageSize,
        );

    const parentItemLink = navigationStore.getBranchRecordsUrl(
      FileTypes.CaseFile,
      props.registryEntry.file.id,
      1,
      recordStore.pageSize,
    );

    return (
      <CardContent
        registryEntry={props.registryEntry}
        showFile={!!props.showFile}
        itemLink={(!props.isOpen && canOpen(props.registryEntry)) ? itemLink : undefined}
        parentItemLink={recordRoot ? parentItemLink : undefined}
        cardType={props.cardType}
        headLineButton={props.headLineButton}
      />
    );
  });
};

export default RegistryEntry;
