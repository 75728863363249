import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '12',
  height: '12',
  fill: '#666666',
  viewBox: '0 0 12 12',
  focusable: true,
};

const Download: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      focusable={props.focusable}
    >
      <path d="M9.74095 5.13314C10.1837 4.73463 9.90184 4 9.30613 4H7.25C7.11193 4 7 3.88807 7 3.75V0.25C7 0.111929 6.88807 0 6.75 0H5.25C5.11193 0 5 0.111929 5 0.25V3.75C5 3.88807 4.88807 4 4.75 4H2.69387C2.09816 4 1.81625 4.73463 2.25905 5.13314L5.56517 8.10865C5.81237 8.33113 6.18763 8.33113 6.43483 8.10866L9.74095 5.13314ZM1 10C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12H11C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10H1Z" />
    </svg>
  );
};

export default Download;
