import React, {useEffect} from 'react';
import {default as NotFoundIcon} from 'components/Icons/NotFound';
import intl from 'react-intl-universal';
import Home from '../NavigationBar/Home';
import {IOrganizationAssets} from 'hooks/useOrganizationAssets';
import {useRootStore} from '../../stores';
import './css/page-not-found.scss';

interface Props {
  organizationAssets?: IOrganizationAssets;
}

const NotFound: React.FC<Props> = (props: Props) => {
  const rootStore = useRootStore();
  const navigationStore = rootStore.navigationStore;

  useEffect(() => {
    navigationStore.setPageNotFound(true);
  }, []);

  return (
    <div className="page-not-found">
      {props.organizationAssets && <Home organizationAssets={props.organizationAssets} />}
      <NotFoundIcon />
      <div className="page-not-found-msg">{intl.get('plp.page.not.found.msg').d('OOPS! Page not found')}</div>
    </div>
  );
};

export default NotFound;
