import React from 'react';
import {useObserver} from 'mobx-react-lite';
import Card, {CardType} from '../Card/Card';
import {useRootStore} from 'stores/index';
import {Nodes} from 'stores/TreeStore';
import {ICaseFile, IFolder, IMeetingFolder} from 'stores/FileStore';
import {IRegistryEntry} from 'stores/RecordStore';
import Pagination from '../Pagination/Pagination';
import {getSearchableTypeLabel} from '../../utils/entityTypeLabels';
import PageSizeSwitcher from '../Pagination/PageSizeSwitcher';
import './css/root-grid.scss';
import SearchAndFilterResultsText from './SearchAndFilterResultsText';

const Grid: React.FC = () => {
  const rootStore = useRootStore();
  const treeStore = rootStore.treeStore;
  const searchStore = rootStore.searchStore;
  const fileStore = rootStore.fileStore;
  const recordStore = rootStore.recordStore;

  return useObserver(() => {
    let entities: Array<ICaseFile | IMeetingFolder | IRegistryEntry | IFolder> = [];

    switch (treeStore.rootNode) {
      case Nodes.File:
        entities = fileStore.entitiesList;
        break;
      case Nodes.Record:
        entities = recordStore.entitiesList as Array<IRegistryEntry>;
        break;
      default:
        break;
    }

    let typeLabel = getSearchableTypeLabel(searchStore.selectedSearchType);

    return (
      <>
        <main id="maincontent" className="plp-root-grid main-content" tabIndex={-1}>
          <header>
            <div>
              <h1>{typeLabel}</h1>
              <PageSizeSwitcher />
            </div>
            <SearchAndFilterResultsText entities={entities} />
          </header>
          {entities.map((archiveEntity: ICaseFile | IMeetingFolder | IRegistryEntry | IFolder) => {
            return <Card key={archiveEntity.id} archiveEntity={archiveEntity} cardType={CardType.ListItem} />;
          })}
          <Pagination />
        </main>
      </>
    );
  });
};

export default Grid;
