import {action, observable} from 'mobx';
import client from '../client/client';
import {IRecord} from './RecordStore';
import {DTORecordTypesInverse} from '../types/DTOEntityTypes';

export interface RequestDocumentsFields {
  name: string;
  email: string;
  phone?: string;
  comment?: string;
  verifyEmail?: string;
}

export class RequestAccessFormStore {
  constructor() {
    this.fields = {
      name: '',
      email: '',
      comment: '',
      phone: '',
      verifyEmail: '',
    };

    this.validationErrors = {
      name: '',
      email: '',
      comment: '',
      phone: '',
      verifyEmail: '',
    };
  }

  @observable
  fields: RequestDocumentsFields;

  @observable
  validationErrors: RequestDocumentsFields;

  @observable
  isSubmitting: boolean = false;

  public getFieldValue(fieldName: keyof RequestDocumentsFields): string | undefined {
    return this.getValueFor(this.fields, fieldName);
  }

  public getFieldError(fieldName: keyof RequestDocumentsFields): string | undefined {
    return this.getValueFor(this.validationErrors, fieldName);
  }

  public async doRequest(record: IRecord): Promise<boolean> {
    this.isSubmitting = true;

    try {
      await client.requestDocuments({
        recordType: DTORecordTypesInverse[record.type],
        id: record.id,
        ...this.fields,
      });

      this.isSubmitting = false;

      return true;
    } catch (e) {
      this.isSubmitting = false;

      return false;
    }
  }

  @action.bound
  public clearFields(): void {
    Object.keys(this.fields).forEach(name => {
      this.setFieldValue(name as keyof RequestDocumentsFields, '');
      this.setFieldError(name as keyof RequestDocumentsFields, '');
    });
  }

  private getValueFor(formFields: RequestDocumentsFields, fieldName: keyof RequestDocumentsFields): string | undefined {
    return formFields[fieldName];
  }

  @action.bound
  public setFieldValue(fieldName: keyof RequestDocumentsFields, value: string): void {
    this.setValueFor(this.fields, fieldName, value);
  }

  @action.bound
  public setFieldError(fieldName: keyof RequestDocumentsFields, value: string): void {
    this.setValueFor(this.validationErrors, fieldName, value);
  }

  @action.bound
  private setValueFor(
    formFields: RequestDocumentsFields,
    fieldName: keyof RequestDocumentsFields,
    value: string,
  ): void {
    if (fieldName in formFields) {
      formFields[fieldName] = value;
    }
  }
}
