import React, {useState} from 'react';
import Send from '../Icons/Send';
import {v4 as uuidv4} from 'uuid';

interface Props {
  isOpen: boolean;
  onClick: (e: React.SyntheticEvent) => void;
  onMouseUp?: (e: React.SyntheticEvent) => void;
  onMouseDown?: (e: React.SyntheticEvent) => void;
  label: string;
  className?: string;
}

const DialogButton = React.forwardRef<HTMLButtonElement, Props>(
  ({...props}: Props, ref: React.Ref<HTMLButtonElement>) => {
    const [htmlId] = useState(uuidv4());

    return (
      <button
        ref={ref}
        id={`btn-${htmlId}`}
        type="button"
        className="btn btn-icon"
        aria-expanded={props.isOpen ? 'true' : 'false'}
        aria-haspopup="dialog"
        onClick={props.onClick}
        onMouseDown={props.onMouseDown}
        onMouseUp={props.onMouseUp}
      >
        <Send />
        {props.label}
      </button>
    );
  },
);

export default DialogButton;
