import React from 'react';
import {useObserver} from 'mobx-react-lite';
import {useRootStore} from 'stores/index';
import Grid from './Grid';
import GridLimited from './GridLimited';
import {PortalRouteProps} from 'types/PortalRouteProps';
import useInitializeTree from 'hooks/useInitializeTree';

type Props = {
  portalRouteProps: PortalRouteProps;
  setRootPortalRouteProps: (rootRouteProps: PortalRouteProps) => void;
  setChildrenOpen: (childrenOpen: boolean) => void;
  setPortalClass: (portalClass: string) => void;
};

const GridWrapper: React.FC<Props> = (props: Props) => {
  const rootStore = useRootStore();
  const treeStore = rootStore.treeStore;

  useInitializeTree(props.portalRouteProps, props.setRootPortalRouteProps, props.setChildrenOpen, props.setPortalClass);

  return useObserver(() => {
    if (!treeStore.isEntitySelected) {
      return <Grid />;
    } else {
      return <GridLimited />;
    }
  });
};

export default GridWrapper;
