import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '14',
  height: '14',
  fill: 'none',
  viewBox: '0 0 14 14',
  focusable: false,
};

const SearchIconSmall: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11C6.6575 11 7.73148 10.6424 8.61752 10.0317L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L10.0317 8.61752C10.6424 7.73148 11 6.6575 11 5.5C11 2.46243 8.53757 0 5.5 0ZM2 5.5C2 3.567 3.567 2 5.5 2C7.433 2 9 3.567 9 5.5C9 7.433 7.433 9 5.5 9C3.567 9 2 7.433 2 5.5Z" />
    </svg>
  );
};

export default SearchIconSmall;
