import Flag from '../components/Icons/Flag';
import {CardFieldCategory, CardField} from '../components/Card/Footer/CardTooltips';
import React from 'react';
import Calendar from '../components/Icons/Calendar';
import Padlock from '../components/Icons/Padlock';
import Document from '../components/Icons/Document';
import PinDrop from '../components/Icons/PinDrop';
import {localDateString, shortLocalDateString} from './date';

export function getCardFieldIcon(cardFieldCategory: CardFieldCategory): JSX.Element {
  switch (cardFieldCategory) {
    case CardFieldCategory.Date:
      return <Calendar />;
    case CardFieldCategory.Screening:
      return <Padlock />;
    case CardFieldCategory.Document:
      return <Document />;
    case CardFieldCategory.TypeAndStatus:
      return <Flag />;
    case CardFieldCategory.Location:
      return <PinDrop />;
  }
}

export function groupCardFieldsByCategory(cardFields: CardField[]) {
  let fieldsByCategory: {[key: string]: CardField[]} = {};
  cardFields.forEach(cardField => {
    if (!fieldsByCategory[cardField.category]) {
      fieldsByCategory[cardField.category] = [];
    }
    fieldsByCategory[cardField.category].push(cardField);
  });
  return fieldsByCategory;
}

export function getUniqueSortedCardFieldCategories(cardFields: CardField[]) {
  let categories: CardFieldCategory[] = [];
  cardFields.forEach(cardField => {
    if (categories.indexOf(cardField.category) < 0) {
      categories.push(cardField.category);
    }
  });
  categories.sort((cat1, cat2) => cat1.valueOf() - cat2.valueOf());
  return categories;
}

export function joinCardFields(
  categories: CardFieldCategory[],
  fieldsByCategory: {[key: string]: CardField[]},
  useShortValues: boolean,
) {
  return categories.map(category => {
    const fieldsForCategory = fieldsByCategory[category];
    return {
      name: fieldsForCategory.map(field => field.name).join(' / '),
      value: fieldsForCategory
        .map(field => {
          if (useShortValues) {
            return getShortCardFieldValue(field);
          } else if (category === CardFieldCategory.Date) {
            return localDateString(field.value);
          } else return field.value;
        })
        .join(' / '),
      category: category,
      className: Array.from(new Set(fieldsForCategory.map(t => t.className))).join(' '),
    };
  });
}

export function getShortCardFieldValue(cardField: CardField) {
  switch (cardField.category) {
    case CardFieldCategory.Date:
      return shortLocalDateString(cardField.value);
    case CardFieldCategory.TypeAndStatus:
      if(typeof cardField.value === 'string') {
        return cardField.value.substring(0, 1);
      } else {
        return cardField.value;
      }
    case CardFieldCategory.Document:
      return cardField.value;
    case CardFieldCategory.Location:
    case CardFieldCategory.Screening:
      return '';
  }
}
