import {observable, action, computed} from 'mobx';

export enum Nodes {
  File = 'file',
  Record = 'record',
  Document = 'document',
}
export type RootNode = Nodes.File | Nodes.Record;
export type Node = Nodes;

export class TreeStore {
  @observable
  rootNode: RootNode = Nodes.File;

  @action.bound
  public setRootNode(node: RootNode): void {
    this.rootNode = node;
  }

  @observable
  currentNode: Node = Nodes.File;

  @action.bound
  public setCurrentNode(node: Node): void {
    this.currentNode = node;
  }

  @computed get isEntitySelected() {
    return this.rootNode !== this.currentNode;
  }
}
