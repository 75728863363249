import React from 'react';
import StoreProvider from 'stores/index';
import Routing from './Routing';
import UIBlocker from './UIBlocker/UIBlocker';

const App: React.FC = () => {
  return (
    <StoreProvider>
      <UIBlocker>
        <Routing/>
      </UIBlocker>
    </StoreProvider>
  );
};

export default App;