import React, {useEffect, useState} from 'react';
import {useObserver} from 'mobx-react-lite';
import {useRootStore} from '../../stores';
import {SearchableCriteria, SearchableType} from '../../stores/SearchStore';
import {FileTypes, RecordTypes} from '../../types/EntityTypes';
import {autorun} from 'mobx';
import {Nodes, RootNode} from '../../stores/TreeStore';
import CompactSearch from './CompactSearch';
import Search from './Search';
import './css/search-common.scss';
import {getCriterionType} from './SearchOptions';

export interface SharedSearchProps {
  executeSearch: () => void;
  selectedSearchType: SearchableType;
  searchCriterionValue: string;
  setSearchCriterionValue: (value: string) => void;
  selectedSearchCriterion: SearchableCriteria;
  setSelectedSearchCriterion: (criterion: string) => void;
  disableClear: boolean;
}

export interface ExtendedSearchProps extends SharedSearchProps {
  parentHasFocusRing: boolean;
  setParentHasFocusRing: (focused: boolean) => void;
  setFocusIsInDropdown: (focusIsInDropdown: boolean) => void;
}

const SearchBar: React.FC = () => {
  const [hasFocusRing, setHasFocusRing] = useState(false);
  const [focusIsInDropdown, setFocusIsInDropdown] = useState(false);

  const rootStore = useRootStore();
  const treeStore = rootStore.treeStore;
  const fileStore = rootStore.fileStore;
  const recordStore = rootStore.recordStore;
  const searchStore = rootStore.searchStore;
  const uiStore = rootStore.uiStore;

  // Change selected search type on change of root type via filter
  useEffect(
    () =>
      autorun(() => {
        const rootNode: RootNode = treeStore.rootNode;
        const fileType: FileTypes = fileStore.type;
        const recordType: RecordTypes = recordStore.type;

        switch (rootNode) {
          case Nodes.File:
            if (fileType === FileTypes.FileSubset || fileType === FileTypes.MeetingFolder)
            searchStore.setSelectedSearchType(fileType);
            break;
          case Nodes.Record:
            if (recordType === RecordTypes.RecordSubset) {
              searchStore.setSelectedSearchType(recordType);
            }
            break;
          default:
            break;
        }
      }),
    [],
  ); // note no mobx observables are in dep array, we use autorun instead

  return useObserver(() => {
    const executeSearch = () => {
      fetchEntitiesByType();
    };

    const fetchEntitiesByType = () => {
      const type: SearchableType = searchStore.selectedSearchType;
      if (type === FileTypes.FileSubset || type === FileTypes.MeetingFolder) {
        fileStore.changeType(type);
      } else if (type === RecordTypes.RecordSubset) {
        recordStore.changeType(type);
      }
    };

    const setSearchCriterion = (selectedCriterion: string) => {
      const selectedCriterionType = getCriterionType(searchStore.selectedSearchType, selectedCriterion);
      if (selectedCriterionType !== searchStore.selectedSearchCriterion) {
        searchStore.setSelectedSearchCriterion(selectedCriterionType);
        if (searchStore.selectedSearchType === RecordTypes.RecordSubset) {
          recordStore.changePage(1);
        } else {
          fileStore.changePage(1);
        }
      }
    };

    const disableButtons: boolean = fileStore.isLoading || recordStore.isLoading;
    const disableClear: boolean = disableButtons || !!!searchStore.searchCriterionValue;

    const classNames = ['search'];
    if (hasFocusRing) classNames.push('focused');
    if (searchStore.searchCriterionValue) classNames.push('has-search-term');
    if (focusIsInDropdown) classNames.push('focus-in-drop-down');

    return (
      <form role="search" className={classNames.join(' ')}>
        {!uiStore.isMobile ? (
          <Search
            parentHasFocusRing={hasFocusRing}
            setParentHasFocusRing={setHasFocusRing}
            setFocusIsInDropdown={setFocusIsInDropdown}
            executeSearch={executeSearch}
            disableClear={disableClear}
            disableButtons={disableButtons}
            selectedSearchType={searchStore.selectedSearchType}
            valueLastSearched={searchStore.valueLastSearched}
            searchCriterionValue={searchStore.searchCriterionValue}
            setSearchCriterionValue={searchStore.setSearchCriterionValue}
            selectedSearchCriterion={searchStore.selectedSearchCriterion}
            setSelectedSearchCriterion={setSearchCriterion}
          />
        ) : (
          <CompactSearch
            parentHasFocusRing={hasFocusRing}
            setParentHasFocusRing={setHasFocusRing}
            setFocusIsInDropdown={setFocusIsInDropdown}
            executeSearch={executeSearch}
            disableClear={disableClear}
            selectedSearchType={searchStore.selectedSearchType}
            searchCriterionValue={searchStore.searchCriterionValue}
            setSearchCriterionValue={searchStore.setSearchCriterionValue}
            selectedSearchCriterion={searchStore.selectedSearchCriterion}
            setSelectedSearchCriterion={setSearchCriterion}
          />
        )}
      </form>
    );
  });
};

export default SearchBar;
