import React, {useEffect, useRef, useState} from 'react';
import intl from 'react-intl-universal';
import './css/stacked-filters.scss';
import ModalDialog from '../ModalDialog/ModalDialog';

interface Props {
  filtersCount: number;
  onToggleOpen: (open: boolean) => void;
}

const StackedFilters: React.FC<Props> = ({children, filtersCount, onToggleOpen}) => {
  const [open, setOpen] = useState<boolean>(false);

  const close = () => {
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => onToggleOpen(open), [open]);

  const filtersLabel = intl.get('plp.filters.label').d('Filters');
  const titleWithCount = (
    <>
      <span>{filtersLabel}</span>
      {filtersCount > 0 && <span> {filtersCount}</span>}
    </>
  );

  return (
    <div className="stacked-filters">
      <button onClick={toggleOpen} className={`btn open-filters ${filtersCount ? 'has-counter' : ''}`}>
        {titleWithCount}
      </button>
      <ModalDialog show={open} close={close} title={filtersLabel} titleEl={titleWithCount}>
        <div className="filters-in-dialog">{children}</div>
      </ModalDialog>
    </div>
  );
};

export default StackedFilters;
