import React from 'react';
import intl from 'react-intl-universal';
import {IFolder} from 'stores/FileStore';
import {identifiersToString} from 'utils/nationalIdentifierUtils';
import CardTemplate from '../Card/CardTemplate';
import {CardType} from '../Card/Card';
import {CardFieldCategory} from '../Card/Footer/CardTooltips';

interface Props {
  folder: IFolder;
  itemLink?: string;
  cardType: CardType;
}

const CardContent: React.FC<Props> = (props: Props) => {
  const {folder, cardType, itemLink} = props;

  let additionalHeadLineProps = [];
  if (folder.properties) {
    additionalHeadLineProps.push({
      name: intl.get('plp.grid.item.field.common.gnr.bnr').d('GBNR'),
      value: identifiersToString(folder.properties),
    });
  }

  let footerFields = [];

  if (folder.createdDate) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.created.date').d('Created date'),
      value: folder.createdDate,
      category: CardFieldCategory.Date,
    });
  }

  if (props.folder.screening) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.screened').d('Screened'),
      value: props.folder.screening.name,
      category: CardFieldCategory.Screening,
    });
  }

  return (
    <CardTemplate
      entityIdFieldTitle={intl.get('plp.grid.item.field.common.file.ident')}
      entityTitle={intl.get('plp.grid.item.field.entity.type.folder').d('Folder')}
      entityTypeCls="folder"
      abbreviation={intl.get('plp.grid.item.field.entity.type.abbrev.folder').d('F')}
      itemNumber={folder.fileId}
      additionalHeadLineFields={additionalHeadLineProps}
      title={folder.title}
      footerFields={footerFields}
      itemLink={itemLink}
      cardType={cardType}
      screened={!!props.folder.screening}
    />
  );
};

export default CardContent;
