import {action, observable} from 'mobx';
import client from 'client/client';
import {FilterCriteria, SearchStore} from './SearchStore';
import {PaginatableStore} from './PaginatableStore';
import {Nodes, TreeStore} from './TreeStore';
import {FileStore, ICaseFile, IFile, IFolder, IMeetingFolder} from './FileStore';
import IScreening from 'types/IScreening';
import {NavigationStore} from './NavigationStore';
import {FileTypes, RecordTypes} from 'types/EntityTypes';
import {DTORecordTypes} from 'types/DTOEntityTypes';
import {IEntitiesList} from '../types/IEntitiesList';
import {IType} from '../types/IType';
import {IChangeType} from '../types/IChangeType';
import {ISelectable} from '../types/ISelectable';
import {INoarkEntity} from '../types/INoarkEntity';
import {UIBlockerStore} from './UIBlockerStore';
import {isNotFound} from '../utils/errorUtils';

export interface IRecord extends INoarkEntity<DTORecordTypes> {
  recordId: string;
  screening: IScreening | null;
  documentCount: number | null;
  hasPrivateDocuments: boolean;
}

export interface IBasicRecord extends IRecord {
  type: DTORecordTypes.BasicRecord;
  file: ICaseFile | IFolder | IMeetingFolder;
  createdDate: string;
  recordDate: string | null;
}

export interface IRegistryEntry extends IRecord {
  type: DTORecordTypes.RegistryEntry;
  file: ICaseFile;
  senders: string[] | null;
  recipients: string[] | null;
  groupRecipients: string[] | null;
  recordDate: string | null;
  documentDate: string | null;
  registryEntryType: string | null;
  recordStatus: string | null;
}

export interface IMeetingRecord extends IRecord {
  type: DTORecordTypes.MeetingRecord;
  meetingFolder: IMeetingFolder;
  createdDate: string;
  meetingRecordStatus: string | null;
  meetingRecordType: string | null;
  caseNumber?: string;
}

export interface IRecordsResponse<T extends IRecord> {
  hasMore: boolean;
  page: number;
  pageSize: number;
  results: Array<T>;
}

export enum RecordSubsetSearchCriteria {
  title = 'title',
  recordId = 'recordId',
  correspondenceParty = 'correspondenceParty',
}

export enum RecordSubsetFilterCriteria {
  recordType = 'recordType',
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
}

export class RecordStore<T extends IRecord, FS extends FileStore<IFile>> extends PaginatableStore
  implements IEntitiesList<RecordTypes, T>, IType<RecordTypes>, IChangeType<RecordTypes>, ISelectable<T> {
  private readonly treeStore: TreeStore;
  private readonly navigationStore: NavigationStore;
  private readonly searchStore: SearchStore;
  private readonly fileStore: FS;
  private readonly uiBlockerStore: UIBlockerStore;

  constructor(
    treeStore: TreeStore,
    navigationStore: NavigationStore,
    searchStore: SearchStore,
    fileStore: FS,
    uiBlockerStore: UIBlockerStore,
  ) {
    super();
    this.treeStore = treeStore;
    this.navigationStore = navigationStore;
    this.searchStore = searchStore;
    this.fileStore = fileStore;
    this.uiBlockerStore = uiBlockerStore;
  }

  @observable
  type: RecordTypes = RecordTypes.RecordSubset;

  @action.bound
  public setType(type: RecordTypes): void {
    this.type = type;
  }

  @action.bound
  public changeType(type: RecordTypes): void {
    if (this.fileStore.selectedEntityId) {
      this.navigationStore.goToBranchRecords(
        this.fileStore.type,
        this.fileStore.selectedEntityId,
        1,
        this.pageSize,
      );
    } else {
      this.navigationStore.goToRootRecords(
        type as RecordTypes.RecordSubset,
        1,
        this.pageSize,
        this.searchStore.getSearchQuery(),
        this.searchStore.selectedFilters,
      );
    }
  }

  @action.bound
  public changePage(page: number): void {
    if (this.fileStore.selectedEntityId) {
      this.navigationStore.goToBranchRecords(
        this.fileStore.type,
        this.fileStore.selectedEntityId,
        page,
        this.pageSize,
      );
    } else {
      this.navigationStore.goToRootRecords(
        this.type as RecordTypes.RecordSubset,
        page,
        this.pageSize,
        this.searchStore.getSearchQuery(),
        this.searchStore.selectedFilters,
      );
    }
  }

  public getUrlForPage(page: number): string {
    if (this.fileStore.selectedEntityId) {
      return  this.navigationStore.getBranchRecordsUrl(
        this.fileStore.type,
        this.fileStore.selectedEntityId,
        page,
        this.pageSize,
      );
    } else {
      return this.navigationStore.getRootRecordsUrl(
        this.type as RecordTypes.RecordSubset,
        page,
        this.pageSize,
        this.searchStore.getSearchQuery(),
        this.searchStore.selectedFilters,
      );
    }
  }

  @observable
  entitiesList: Array<T> = [];

  @action.bound
  public async fetchEntities(page: number, type?: RecordTypes, excludeMeetingTypes?: string[]): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.setIsLoading(true);
    const actionId = this.uiBlockerStore.notifyActionStarted();

    try {
      const typeToSet = type || this.type;

      let searchCriterion: RecordSubsetSearchCriteria | undefined = undefined;
      let searchCriterionValue: string | undefined = undefined;

      const shouldCheckSearchStore: boolean =
        this.searchStore.selectedSearchType === RecordTypes.RecordSubset && !!this.searchStore.searchCriterionValue;

      if (shouldCheckSearchStore) {
        searchCriterion = this.searchStore.selectedSearchCriterion as RecordSubsetSearchCriteria;
        searchCriterionValue = this.searchStore.searchCriterionValue;
      }

      let filters: Map<RecordSubsetFilterCriteria, string> = new Map();
      this.searchStore.selectedFilters.forEach((value: string, key: FilterCriteria) => {
        let filterType = key as RecordSubsetFilterCriteria;
        if (filterType) {
          filters.set(filterType, value);
        }
      });

      const fileType: FileTypes = this.fileStore.type;
      const fileId: string | null = this.fileStore.selectedEntityId;

      let archiveEntitiesResponse: IRecordsResponse<T>;

      if (fileId && FileTypes.MeetingFolder === fileType && excludeMeetingTypes?.length) {

        archiveEntitiesResponse = await client.fetchMeetingRecordsExcludingTypes<T>(
          fileId,
          excludeMeetingTypes,
          page,
          this.pageSize,
        );

      } else if (fileId) {

        archiveEntitiesResponse = await client.fetchRecordsByFileId<T>(
          fileType,
          fileId,
          RecordTypes.RecordUnion,
          page,
          this.pageSize,
        );
      } else {
        archiveEntitiesResponse = await client.fetchRecords<T>(
          RecordTypes.RecordSubset,
          page,
          this.pageSize,
          searchCriterion,
          searchCriterionValue,
          filters,
        );
      }

      this.page = page;
      this.type = typeToSet;
      this.entitiesList = archiveEntitiesResponse.results;
      this.hasMore = archiveEntitiesResponse ? archiveEntitiesResponse.hasMore : false;

      if (!fileId) {
        this.treeStore.setRootNode(Nodes.Record);
        this.searchStore.setValueLastSearched(searchCriterionValue);
      }

      this.treeStore.setCurrentNode(Nodes.Record);
    } catch (error) {
      if (isNotFound(error)) {
        this.navigationStore.redirectToNotFound();
      }
    } finally {
      this.uiBlockerStore.notifyActionCompleted(actionId);
      this.setIsLoading(false);
    }
  }

  @action.bound
  public clearEntities(): void {
    this.entitiesList = [];
  }

  @observable
  selectedEntityId: string | null = null;

  @action.bound
  public setSelectedEntityId(recordId: string | null): void {
    this.selectedEntityId = recordId;
  }

  @observable
  selectedEntity: T | null = null;

  @action.bound
  public async fetchSelectedEntity(): Promise<void> {
    if (this.selectedEntityId) {
      this.setIsLoading(true);
      const actionId = this.uiBlockerStore.notifyActionStarted();

      try {
        const selectedEntity: IRecordsResponse<T> = await client.fetchSelectedRecord<T>(
          this.type,
          this.selectedEntityId,
        );

        this.selectedEntity = selectedEntity.results[0] || null;
      } catch (error) {
        if (isNotFound(error)) {
          this.navigationStore.redirectToNotFound();
        }
      } finally {
        this.uiBlockerStore.notifyActionCompleted(actionId);
        this.setIsLoading(false);
      }
    }
  }
}
