export enum DTOFileTypes {
  CaseFile = 'case_file',
  MeetingFolder = 'meeting_folder',
  Folder = 'folder',
}

export enum DTORecordTypes {
  RegistryEntry = 'registry_entry',
  MeetingRecord = 'meeting_record',
  BasicRecord = 'basic_record',
}

export enum DTORecordTypesInverse {
  'registry_entry' = 'RegistryEntry',
  'meeting_record' = 'MeetingRecord',
  'basic_record' = 'BasicRecord',
}

export enum DTODocumentTypes {
  Document = 'document',
}
