import React from 'react';
import intl from 'react-intl-universal';
import {IRegistryEntry} from 'stores/RecordStore';
import CardTemplate from '../Card/CardTemplate';
import ParentLink from '../Card/ParentLink';
import {CardType} from '../Card/Card';
import {CardFieldCategory} from '../Card/Footer/CardTooltips';

interface Props {
  registryEntry: IRegistryEntry;
  showFile: boolean;
  itemLink?: string;
  parentItemLink?: string;
  cardType: CardType;
  headLineButton?: JSX.Element;
}

const CardContent: React.FC<Props> = (props: Props) => {
  let fieldDefinitions = [];
  if (props.registryEntry.senders) {
    const propName =
      props.registryEntry.senders.length === 1 ? 'plp.grid.item.field.sender' : 'plp.grid.item.field.senders';
    const defaultPropName = props.registryEntry.senders.length === 1 ? 'Sender' : 'Senders';

    fieldDefinitions.push({
      name: intl.get(propName).d(defaultPropName),
      value: props.registryEntry.senders,
    });
  }
  if (props.registryEntry.recipients) {
    const propName =
      props.registryEntry.recipients.length === 1 ? 'plp.grid.item.field.recipient' : 'plp.grid.item.field.recipients';
    const defaultPropName = props.registryEntry.recipients.length === 1 ? 'Recipient' : 'Recipients';

    fieldDefinitions.push({
      name: intl.get(propName).d(defaultPropName),
      value: props.registryEntry.recipients,
    });
  }
  if (props.registryEntry.groupRecipients) {
    fieldDefinitions.push({
      name: intl.get('plp.grid.item.field.group.recipients').d('Group recipients'),
      value: props.registryEntry.groupRecipients,
    });
  }

  let footerFields = [];

  if (props.registryEntry.recordDate) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.record.date').d('Record date'),
      value: props.registryEntry.recordDate,
      className: 'record-date',
      category: CardFieldCategory.Date,
    });
  }
  if (props.registryEntry.documentDate) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.document.date').d('Document date'),
      value: props.registryEntry.documentDate,
      className: 'record-date',
      category: CardFieldCategory.Date,
    });
  }

  if (props.registryEntry.recordStatus) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.status').d('Status'),
      value: props.registryEntry.recordStatus,
      className: 'status',
      category: CardFieldCategory.TypeAndStatus,
    });
  }

  if (props.registryEntry.registryEntryType) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.type').d('Type'),
      value: props.registryEntry.registryEntryType,
      className: 'status',
      category: CardFieldCategory.TypeAndStatus,
    });
  }

  if (props.registryEntry.documentCount !== null) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.documents').d('Documents'),
      value: props.registryEntry.documentCount,
      className: 'document-count',
      category: CardFieldCategory.Document,
    });
  }

  if (props.registryEntry.screening) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.screened').d('Screened'),
      value: props.registryEntry.screening.name,
      className: 'screening',
      category: CardFieldCategory.Screening,
    });
  }

  let parentLink;

  if (props.showFile) {
    parentLink = (
      <ParentLink
        type={intl.get('plp.grid.item.field.entity.type.case.file').d('Case File')}
        number={props.registryEntry.file.fileId}
        title={props.registryEntry.file.title}
        itemLink={props.parentItemLink}
        parentCardType={props.cardType}
      />
    );
  }

  return (
    <CardTemplate
      entityIdFieldTitle={intl.get('plp.grid.item.field.common.record.ident')}
      entityTitle={intl.get('plp.grid.item.field.entity.type.registry.entry').d('Public record')}
      entityTypeCls={"registry-entry"}
      abbreviation={intl.get('plp.grid.item.field.entity.type.abbrev.registry.entry').d('RE')}
      itemNumber={props.registryEntry.recordId}
      title={props.registryEntry.title}
      fieldDefinitions={fieldDefinitions}
      parentLink={parentLink}
      footerFields={footerFields}
      itemLink={props.itemLink}
      cardType={props.cardType}
      screened={!!props.registryEntry.screening}
      headLineButton={props.headLineButton}
    />
  );
};

export default CardContent;
