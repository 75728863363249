import {DTOFileTypes} from '../types/DTOEntityTypes';
import {FileTypes} from '../types/EntityTypes';

export const convertFromDTOFileType = (type: DTOFileTypes): FileTypes => {
  switch (type) {
    case DTOFileTypes.CaseFile:
      return FileTypes.CaseFile;
    case DTOFileTypes.Folder:
      return FileTypes.Folder;
    case DTOFileTypes.MeetingFolder:
      return FileTypes.MeetingFolder;
    default:
      return FileTypes.CaseFile;
  }
};
