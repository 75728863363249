import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '12',
  height: '12',
  fill: 'none',
  viewBox: '0 0 12 12',
  focusable: false,
};

const Reset: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM8.7071 4.70711C9.09763 4.31659 9.09763 3.68343 8.70711 3.2929C8.31659 2.90237 7.68343 2.90237 7.2929 3.29289L5.99996 4.5858L4.7071 3.29297C4.31657 2.90245 3.68341 2.90245 3.29289 3.29298C2.90237 3.68351 2.90237 4.31667 3.2929 4.70719L4.58573 6L3.2929 7.29281C2.90237 7.68333 2.90237 8.31649 3.29289 8.70702C3.68341 9.09755 4.31657 9.09755 4.7071 8.70703L5.99996 7.4142L7.2929 8.70711C7.68343 9.09763 8.31659 9.09763 8.70711 8.7071C9.09763 8.31657 9.09763 7.68341 8.7071 7.29289L7.41419 6L8.7071 4.70711Z"
      />
    </svg>
  );
};

export default Reset;
