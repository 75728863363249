import React, {useRef, useState} from 'react';
import intl from 'react-intl-universal';
import Cross from '../Icons/Cross';

export enum InputType {
  Text = 'text',
  TextArea = 'textarea',
}

interface FormFieldProps {
  inputType: InputType;
  inputId: string;
  inputName: string;
  inputValue: string;
  onChange: (value: string) => void;
  clearValue?: () => void;
  fieldInFocus?: string;
  setFocusOn: (inputName: string) => void;
  label: string;
  disabled?: boolean;
  description?: string;
  validateOnBlur?: () => void;
  validationError?: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  attribs?: {[key: string]: string};
}

const FormField: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const [canShowError, setCanShowError] = useState(true);

  let additionalText, additionalTextClass;
  if (props.validationError && canShowError) {
    additionalText = props.validationError;
    additionalTextClass = 'validation-error';
  } else if (props.description) {
    additionalText = props.description;
    additionalTextClass = 'description';
  }

  const inputProps: any = {
    id: props.inputId,
    name: props.inputName,
    onFocus: () => {
      setCanShowError(false);
      props.setFocusOn(props.inputName);
    },
    onChange: (e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
      props.onChange(e.target.value);
    },
    onBlur: () => {
      setCanShowError(true);
      if (props.validateOnBlur) props.validateOnBlur();
    },
    disabled: props.disabled,
    autoComplete: 'off',
    ...props.attribs,
  };

  if (props.inputType === InputType.Text) {
    inputProps['value'] = props.inputValue;
  }

  if (props.inputType === InputType.TextArea) {
    inputProps['rows'] = 5;
  }

  let classNames = ['input-container'];
  if (props.inputValue) {
    classNames.push('has-value');
  }
  if (props.validationError && canShowError) {
    classNames.push('invalid');
  }

  return (
    <>
      <label className="input-label" htmlFor={props.inputId}>
        {props.label}
      </label>
      <div className={classNames.join(' ')}>
        {React.createElement(props.inputType === InputType.Text ? 'input' : 'textarea', {
          ...inputProps,
          ref: inputRef,
          onKeyDown: props.onKeyDown,
          onKeyUp: props.onKeyUp,
        })}
        {props.clearValue && props.inputValue && props.fieldInFocus === props.inputName && (
          <button
            onClick={() => {
              if (props.clearValue) {
                props.clearValue();
              }

              if (inputRef && inputRef.current) {
                inputRef.current.focus();
              }
            }}
            aria-labelledby={`clear-${props.inputId}`}
          >
            <span id={`clear-${props.inputId}`} className="sr-only">{`${intl
              .get('plp.filter.clear.value')
              .d('Clear value')} ${props.label}`}</span>
            <Cross width="13" height="12" />
          </button>
        )}
      </div>
      {additionalText && <div className={additionalTextClass}>{additionalText}</div>}
    </>
  );
};

export default FormField;
