import React, {useState} from 'react';
import intl from 'react-intl-universal';
import {v4 as uuidv4} from 'uuid';
import {useObserver} from 'mobx-react-lite';
import {Nodes} from '../../stores/TreeStore';
import {useRootStore} from '../../stores';
import {getSearchAndFilterValues} from './SearchAndFilterUtils';
import {ICaseFile, IFolder, IMeetingFolder} from '../../stores/FileStore';
import {IRegistryEntry} from '../../stores/RecordStore';
import {getSearchableTypeLabel} from '../../utils/entityTypeLabels';
import Reset from '../Icons/Reset';

interface Props {
  entities: Array<ICaseFile | IMeetingFolder | IRegistryEntry | IFolder>;
}

const SearchAndFilterResultsText: React.FC<Props> = (props: Props) => {
  const rootStore = useRootStore();
  const treeStore = rootStore.treeStore;
  const searchStore = rootStore.searchStore;
  const fileStore = rootStore.fileStore;
  const recordStore = rootStore.recordStore;
  const [htmlId] = useState<string>(uuidv4());

  return useObserver(() => {
    const resetSearchAndFilterValues = () => {
      searchStore.clearSearchAndFilters();

      switch (treeStore.rootNode) {
        case Nodes.File:
          fileStore.changePage(1);
          break;
        case Nodes.Record:
          recordStore.changePage(1);
          break;
        default:
          break;
      }
    };

    let typeLabel = getSearchableTypeLabel(searchStore.selectedSearchType);

    const searchAndFilterValues = getSearchAndFilterValues(
      searchStore.selectedSearchType,
      searchStore.valueLastSearched,
      searchStore.selectedSearchCriterion,
      searchStore.selectedFilters,
    );

    const isLoading = fileStore.isLoading || recordStore.isLoading;
    const hasSearchOrFilterResults = searchAndFilterValues && props.entities.length > 0 && !isLoading;
    const noSearchOrFilterResults = searchAndFilterValues && props.entities.length == 0 && !isLoading;

    const resetSearchButtonId = `reset-btn-${htmlId}`;
    const resetSearchButton = (
      <>
        <span id={resetSearchButtonId} className="sr-only">
          {intl.get('plp.search.result.set.reset.search').d('Reset search')}
        </span>
        <button
          className="btn  btn-secondary"
          onClick={resetSearchAndFilterValues}
          aria-labelledby={resetSearchButtonId}
        >
          <Reset />
          {intl.get('plp.search.result.set.reset').d('Reset')}
        </button>
      </>
    );

    return (
      <>
        {(hasSearchOrFilterResults || noSearchOrFilterResults) && (
          <div className="results-for">
            <span aria-hidden="true">
              {hasSearchOrFilterResults && (
                <>
                  {intl.get('plp.search.result.set.for.value').d('Showing results for') + ' '}
                  {searchAndFilterValues} {intl.get('plp.in').d('in')} <b>{typeLabel}</b>
                </>
              )}
              {noSearchOrFilterResults && (
                <>
                  {intl.get('plp.search.no.results').d('No search results')} {searchAndFilterValues}{' '}
                  {intl.get('plp.in').d('in')} <b>{typeLabel}</b>
                </>
              )}
            </span>
            {resetSearchButton}
          </div>
        )}
        <p className="sr-only" aria-live="polite">
          {hasSearchOrFilterResults && (
            <>
              {intl.get('plp.search.result.set.for.value').d('Showing results for') + ' '}
              {searchAndFilterValues} {intl.get('plp.in').d('in')} {typeLabel}
            </>
          )}
          {noSearchOrFilterResults && (
            <>
              {intl.get('plp.search.no.results').d('No search results')} {searchAndFilterValues}{' '}
              {intl.get('plp.in').d('in')} {typeLabel}
            </>
          )}
          {!searchAndFilterValues &&
            `${intl.get('plp.search.show.all').d('Showing all')} ${typeLabel.toLocaleLowerCase()}`}
        </p>
      </>
    );
  });
};

export default SearchAndFilterResultsText;
