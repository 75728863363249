import React from 'react';
import {SearchableType} from '../../stores/SearchStore';
import {FileTypes, RecordTypes} from '../../types/EntityTypes';
import intl from 'react-intl-universal';
import SingleSelect from '../SelectField/SingleSelect';
import {useRootStore} from '../../stores';
import {useObserver} from 'mobx-react-lite';
import {Option} from '../SelectField/SelectBase';

interface Props {
  onValueChange?: () => void;
  onOptionFocus?: (opt: Option) => void;
  onOptionBlur?: (opt: Option) => void;
}

const EntityTypeFilter: React.FC<Props> = (props: Props) => {
  const rootStore = useRootStore();
  const searchStore = rootStore.searchStore;
  const fileStore = rootStore.fileStore;
  const recordStore = rootStore.recordStore;
  const uiStore = rootStore.uiStore;

  const typeLabel = intl.get('plp.filter.entity.type').d('Type');
  const fileSubsetLabel = intl.get('plp.filter.entity.type.file.subset').d('Case files');
  const meetingFolderLabel = intl.get('plp.filter.entity.type.meeting.folders').d('Meetings');
  const regEntryLabel = intl.get('plp.filter.entity.type.public.records').d('Public records');

  return useObserver(() => {
    const options = [
      {
        label: fileSubsetLabel,
        value: FileTypes.FileSubset,
      },
      {
        label: meetingFolderLabel,
        value: FileTypes.MeetingFolder,
      },
      {
        label: regEntryLabel,
        value: RecordTypes.RecordSubset,
      },
    ];

    const getEntityTypeLabel = (type: SearchableType) => {
      switch (type) {
        case FileTypes.FileSubset:
          return fileSubsetLabel;
        case FileTypes.MeetingFolder:
          return meetingFolderLabel;
        case RecordTypes.RecordSubset:
          return regEntryLabel;
        default:
          return '';
      }
    };

    const constructButtonLabel = (type: SearchableType) => {
      return `${typeLabel}: ${getEntityTypeLabel(type)}`;
    };

    const constructButtonLabelEl = (type: SearchableType) => {
      if (uiStore.isMobile) {
        return <>{getEntityTypeLabel(type)}</>;
      } else {
        return (
          <>
            <span>{typeLabel}: </span>
            <span>{getEntityTypeLabel(type)}</span>
          </>
        );
      }
    };

    const onValueChange = (selectedValue: string): void => {
      const selectedType: SearchableType = selectedValue as
        | FileTypes.FileSubset
        | FileTypes.MeetingFolder;

      selectEntityType(selectedType);

      if (props.onValueChange) {
        props.onValueChange();
      }
    };

    const selectEntityType = (type: SearchableType) => {
      searchStore.clearAllFilters();
      searchStore.resetSelectedSearchCriterion(type);
      if (type === FileTypes.FileSubset || type === FileTypes.MeetingFolder) {
        fileStore.changeType(type);
      } else if (type === RecordTypes.RecordSubset) {
        recordStore.changeType(type);
      }
    };

    return (
      <SingleSelect
        options={options}
        selected={searchStore.selectedSearchType}
        buttonLabel={constructButtonLabel(searchStore.selectedSearchType)}
        buttonLabelEl={constructButtonLabelEl(searchStore.selectedSearchType)}
        accessibleLabel={intl.get('plp.filter.entity.choose.entity.type'.d('Choose type'))}
        onChange={onValueChange}
        isMobileWidth={uiStore.isMobile}
        dropDownBtnCls="btn-primary"
        dropDownContainerCls="entity-type-filter"
        onOptionFocus={props.onOptionFocus}
        onOptionBlur={props.onOptionBlur}
      />
    );
  });
};

export default EntityTypeFilter;
