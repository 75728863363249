import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '12',
  height: '12',
  fill: '#DD4624',
  viewBox: '0 0 12 12',
  focusable: false,
};

const Padlock: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.00012V5.00012H4V4.00012C4 2.89555 4.89543 2.00012 6 2.00012C7.10457 2.00012 8 2.89555 8 4.00012ZM2 5.00012V4.00012C2 1.79098 3.79086 0.00012207 6 0.00012207C8.20914 0.00012207 10 1.79098 10 4.00012V5.00012C10.5523 5.00012 11 5.44784 11 6.00012V11.0001C11 11.5524 10.5523 12.0001 10 12.0001H2C1.44772 12.0001 1 11.5524 1 11.0001V6.00012C1 5.44784 1.44772 5.00012 2 5.00012Z"
      />
    </svg>
  );
};

export default Padlock;
