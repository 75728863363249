import {action, observable} from 'mobx';

export class UIStore {
  //window width below 768px
  @observable
  isMobile: boolean = false;

  @action.bound
  public setIsMobile(isMobile: boolean): void {
    this.isMobile = isMobile;
  }

  //window width below 992px
  @observable
  isMobileOrTablet: boolean = false;

  @action.bound
  public setIsMobileOrTablet(isMobileOrTablet: boolean): void {
    this.isMobileOrTablet = isMobileOrTablet;
  }

  //window width below 1200px
  @observable
  isSmallScreen: boolean = false;

  @action.bound
  public setIsSmallScreen(isSmallScreen: boolean): void {
    this.isSmallScreen = isSmallScreen;
  }

  @observable
  gridContainerHasScroll: boolean = true;

  @action.bound
  public setGridContainerHasScroll(gridContainerHasScroll: boolean): void {
    this.gridContainerHasScroll = gridContainerHasScroll;
  }

  @observable
  searchTooltipHovered: boolean = false;

  @action.bound
  public setSearchTooltipHovered(searchTooltipHovered: boolean): void {
    this.searchTooltipHovered = searchTooltipHovered;
  }
}
