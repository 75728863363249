import React from 'react';
import intl from 'react-intl-universal';
import {useRootStore} from '../../stores';
import SingleSelect from '../SelectField/SingleSelect';
import {VALID_SEARCH_PAGE_SIZES} from '../../stores/PaginatableStore';
import {useObserver} from 'mobx-react-lite';
import './css/page-size-switcher.scss';

const PageSizeSwitcher: React.FC = () => {
  const rootStore = useRootStore();
  const uiStore = rootStore.uiStore;

  return useObserver(() => {
    const paginatableNodeStore = rootStore.paginatableNodeStore;
    return (
      <div className="page-size-switcher">
        <SingleSelect
          options={VALID_SEARCH_PAGE_SIZES.map(pageSize => {
            return {label: pageSize.toString(), value: pageSize.toString()};
          })}
          selected={paginatableNodeStore.pageSize.toString()}
          buttonLabel={intl
            .get('plp.grid.pagination.show.per.page', {itemsCount: paginatableNodeStore.pageSize})
            .d(`Show: ${paginatableNodeStore.pageSize} per page`)}
          accessibleLabel={intl
            .get('plp.grid.pagination.page.size.select.label')
            .d('Choose the number of items per page')}
          onChange={(pageSize: string) => {
            paginatableNodeStore.setPageSize(parseInt(pageSize));
            paginatableNodeStore.changePage(1);
          }}
          isMobileWidth={uiStore.isMobile}
          dropDownBtnCls="btn-sm"
        />
      </div>
    );
  });
};

export default PageSizeSwitcher;
