import {observable, action} from 'mobx';
import {createBrowserHistory, History} from 'history';
import {FileTypes, RecordTypes, DocumentTypes, FileUnion} from '../types/EntityTypes';
import queryString from 'query-string';
import {FilterCriteria, SearchQuery} from './SearchStore';
import {PortalRouteProps} from '../types/PortalRouteProps';

export class NavigationStore {
  @observable
  history: History<{}> = createBrowserHistory({});

  @observable
  pageNotFound: boolean = false;

  @action.bound
  setPageNotFound(pageNotFound: boolean): void {
    this.pageNotFound = pageNotFound;
  }

  @action.bound
  push(path: string): void {
    this.history.push(path);
  }

  @action.bound
  replace(path: string): void {
    this.history.replace(path);
  }

  @action.bound
  go(n: number): void {
    this.history.go(n);
  }

  @action.bound
  goBack(): void {
    this.history.goBack();
  }

  @action.bound
  goForward(): void {
    this.history.goForward();
  }

  @action.bound
  redirectToNotFound(): void {
    this.replace('/not-found');
  }

  @action.bound
  goToRoot(rootPortalRouteProps: PortalRouteProps): void {
    if (rootPortalRouteProps.type === RecordTypes.RecordSubset) {
      this.goToRootRecords(
        rootPortalRouteProps.type,
        rootPortalRouteProps.page,
        rootPortalRouteProps.pageSize,
        {
          criterion: rootPortalRouteProps.searchCriterion,
          value: rootPortalRouteProps.searchValue,
        },
        rootPortalRouteProps.filters,
      );
    } else {
      this.goToRootFiles(
        rootPortalRouteProps.type as FileTypes,
        rootPortalRouteProps.page,
        rootPortalRouteProps.pageSize,
        {
          criterion: rootPortalRouteProps.searchCriterion,
          value: rootPortalRouteProps.searchValue,
        },
        rootPortalRouteProps.filters,
      );
    }
  }

  @action.bound
  goToRootFiles(
    fileType: FileTypes,
    page: number,
    pageSize: number,
    searchQuery?: SearchQuery,
    filtersCriteria?: Map<FilterCriteria, string>,
  ): void {
    this.push(this.getRootFilesUrl(fileType, page, pageSize, searchQuery, filtersCriteria));
  }

  getRootFilesUrl(
    fileType: FileTypes,
    page: number,
    pageSize: number,
    searchQuery?: SearchQuery,
    filtersCriteria?: Map<FilterCriteria, string>,
  ): string {
    return `/${fileType}?page=${page}&pageSize=${pageSize}${this.searchString(searchQuery)}${this.filtersString(
      filtersCriteria,
    )}`;
  }

  @action.bound
  goToBranchRecords(fileType: FileTypes, fileId: string, page: number, pageSize: number): void {
    this.push(this.getBranchRecordsUrl(fileType, fileId, page, pageSize));
  }

  getBranchRecordsUrl(fileType: FileTypes, fileId: string, page: number, pageSize: number): string {
    return `/${fileType}/${fileId}/${RecordTypes.RecordUnion}?page=${page}&pageSize=${pageSize}`;
  }

  @action.bound
  goToLeafDocuments(
    recordType: RecordTypes,
    recordId: string,
    documentType: DocumentTypes,
    page: number,
    pageSize: number,
  ): void {
    this.push(this.getLeafDocumentsUrl(recordType, recordId, documentType, page, pageSize));
  }

  getLeafDocumentsUrl(
    recordType: RecordTypes,
    recordId: string,
    documentType: DocumentTypes,
    page: number,
    pageSize: number,
  ): string {
    return `/${FileUnion}/${recordType}/${recordId}/${documentType}?page=${page}&pageSize=${pageSize}`;
  }

  @action.bound
  goToRootRecords(
    recordType: RecordTypes.RecordSubset,
    page: number,
    pageSize: number,
    searchQuery?: SearchQuery,
    filtersCriteria?: Map<FilterCriteria, string>,
  ): void {
    this.push(this.getRootRecordsUrl(recordType, page, pageSize, searchQuery, filtersCriteria));
  }

  getRootRecordsUrl(
    recordType: RecordTypes.RecordSubset,
    page: number,
    pageSize: number,
    searchQuery?: SearchQuery,
    filtersCriteria?: Map<FilterCriteria, string>,
  ): string {
    return `/${recordType}?page=${page}&pageSize=${pageSize}${this.searchString(searchQuery)}${this.filtersString(
      filtersCriteria,
    )}`;
  }

  @action.bound
  goToBranchDocuments(
    recordType: RecordTypes.RegistryEntry,
    recordId: string,
    documentType: DocumentTypes,
    page: number,
    pageSize: number,
  ): void {
    this.push(this.getBranchDocumentsUrl(recordType, recordId, documentType, page, pageSize));
  }

  getBranchDocumentsUrl(
    recordType: RecordTypes.RegistryEntry | RecordTypes.BasicRecord,
    recordId: string,
    documentType: DocumentTypes,
    page: number,
    pageSize: number,
  ): string {
    return `/${recordType}/${recordId}/${documentType}?page=${page}&pageSize=${pageSize}`;
  }

  private searchString(searchQuery?: SearchQuery): string {
    return searchQuery ? `&searchCriterion=${searchQuery.criterion}&searchValue=${searchQuery.value}` : '';
  }

  private filtersString(filtersCriteria?: Map<FilterCriteria, string>): string {
    const filters: {[key: string]: string} = {};
    if (filtersCriteria) {
      filtersCriteria.forEach((value, key) => {
        filters[key] = value;
      });
    }
    return `&${queryString.stringify(filters)}`;
  }
}
