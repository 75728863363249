export interface IErrorResponse {
  statusCode: number,
  errorId: string,
  description: string
}

export function instanceOfIErrorResponse(object: any): object is IErrorResponse {
  return (
    object.hasOwnProperty('statusCode') &&
    object.hasOwnProperty('errorId') &&
    object.hasOwnProperty('description')
  );
}