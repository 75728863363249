import {useEffect, useState} from 'react';
import intl, {ReactIntlUniversalOptions} from 'react-intl-universal';
import axios, {AxiosResponse} from 'axios';
import 'moment/locale/nb';
import 'moment/locale/sv';
import 'moment/locale/bg';
import moment, {locale} from 'moment';

const client = axios.create({
  baseURL: '/',
  responseType: 'json',
  withCredentials: false,
});

export const isSupported = (locale: string): locale is SupportedLocales => {
  return supportedLocales().indexOf(locale) !== -1
}

export const supportedLocales = (): string[] => {
  return (process.env.REACT_APP_LOCALES as string).split(',');
};

export const getLocaleLabel = (locale: string): string => {
  switch (locale) {
    case 'en':
      return 'English';
    case 'nb':
      return 'Norsk';
    case 'sv':
      return 'Svenska';
    default:
      return '';
  }
};

export type SupportedLocales = 'en' | 'nb' | 'sv';

const storedLocale = (): SupportedLocales | undefined => {
  const chosenLocale: SupportedLocales | null = localStorage.getItem('locale') as SupportedLocales;

  if (chosenLocale && isSupported(chosenLocale)) {
    return chosenLocale;
  }

  return undefined;
};

export default function useTranslations(blockUI: () => string, unblockUI: (actionId: string) => void, configLoaded: boolean, defaultLocale?: SupportedLocales) {
  const [selectedLocale, setSelectedLocale] = useState<SupportedLocales | undefined>(storedLocale());
  const [loadedLocale, setLoadedLocale] = useState<string>('');

  const fallbackLocale: SupportedLocales = process.env.REACT_APP_DEFAULT_LOCALE as SupportedLocales;

  useEffect(() => {
    if (!configLoaded || selectedLocale) {
      return;
    }

    if (defaultLocale && isSupported(defaultLocale)) {
      setSelectedLocale(defaultLocale);
    } else if(isSupported(fallbackLocale)) {
      setSelectedLocale(fallbackLocale)
    } else {
      setSelectedLocale("en");
    }


  }, [defaultLocale, configLoaded])

  useEffect(() => {
    if (!selectedLocale || !isSupported(selectedLocale)) {
      return;
    }

    const initializeTranslations = async (): Promise<void> => {
      const actionId = blockUI();
      try {
        const translation: AxiosResponse = await client.get(`${process.env.PUBLIC_URL}/i18n/${selectedLocale}.json`);

        let conf: ReactIntlUniversalOptions = {
          currentLocale: selectedLocale,
          locales: {
            [selectedLocale]: translation.data,
          },
        };

        if (process.env.NODE_ENV === 'production') {
          conf['warningHandler'] = function() {};
        }

        await intl.init(conf);
        moment.locale(selectedLocale);

        setLoadedLocale(selectedLocale);
      } catch (e) {
        // mute language load network errors
      } finally {
        unblockUI(actionId);
      }
    };

    initializeTranslations();
  }, [selectedLocale]);

  const setLocaleAndPreserve = (locale: SupportedLocales) => {
    localStorage.setItem('locale', locale);

    setSelectedLocale(locale);
  }

  return {loadedLocale, selectedLocale: selectedLocale, setSelectedLocale: setLocaleAndPreserve};
}
