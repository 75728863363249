import {PaginatableStore} from './PaginatableStore';
import {action, observable} from 'mobx';
import {SearchStore} from './SearchStore';
import client from '../client/client';

export interface IMeetingBoardResponse {
  hasMore: boolean;
  page: number;
  pageSize: number;
  results: Array<string>;
}

const SEARCH_PAGE_SIZE = 10;

export class MeetingBoardStore extends PaginatableStore {
  private readonly searchStore: SearchStore;

  @observable
  entitiesList: Array<string> = [];

  constructor(searchStore: SearchStore) {
    super();
    this.searchStore = searchStore;
  }

  @action.bound
  public changePage(page: number) {
    throw new Error('Not implemented');
  }

  public getUrlForPage(page: number): string {
    throw new Error('Not implemented');
  }

  public fetchFirstPage() {
    this.fetchEntities(1);
  }

  public loadNextPage() {
    this.fetchEntities(this.page + 1);
  }

  @action.bound
  private async fetchEntities(page: number, type?: string): Promise<void> {
    if (this.isLoading) {
      return;
    }
    this.setIsLoading(true);

    try {
      const response = await client.searchForMeetingBoards(this.searchStore.meetingBoardTerm, page, SEARCH_PAGE_SIZE);

      this.page = page;
      if (this.page > 1) {
        this.entitiesList = this.entitiesList.concat(response.results);
      } else {
        this.entitiesList = response.results;
      }
      this.hasMore = response ? response.hasMore : false;
      this.setIsLoading(false);
      this.page = page;
    } catch (e) {
      this.setIsLoading(false);
    }
  }

  @action.bound
  public clearEntities(): void {
    this.entitiesList = [];
  }
}
