import React, {useRef} from 'react';
import intl from 'react-intl-universal';
import {IDocument} from 'stores/DocumentStore';
import {humanReadableFileSize} from 'utils/fileSizeUtils';
import {getFileExtension} from '../../utils/fileExtensionUtils';
import {useRootStore} from '../../stores';
import {useObserver} from 'mobx-react-lite';
import Download from '../Icons/Download';
import './css/document-card.scss';

interface Props {
  document: IDocument;
}

const CardContent: React.FC<Props> = (props: Props) => {
  const {document} = props;
  const downloadAnchor = useRef<HTMLAnchorElement>(null);

  const rootStore = useRootStore();
  const uiStore = rootStore.uiStore;

  let fileExtension = document.title ? getFileExtension(document.title).toLocaleLowerCase() : '';

  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!document.fileContentId) {
      return false;
    }

    if (downloadAnchor.current) {
      downloadAnchor.current.click();
    }
  };

  return useObserver(() => {
    const displayDownloadIcon = document.fileContentId && uiStore.isMobileOrTablet;

    return (
      <section
        className={`grid-item document-card ${document.fileContentId ? 'item-link' : ''}`}
        onClick={handleDownload}
      >
        <div className="card-icons">
          <span className={`document-extension ${fileExtension}`}>{fileExtension}</span>
          {displayDownloadIcon && <Download />}
        </div>
        <div className="card-content">
          <header>
            <h3>
              {document.fileContentId ? (
                <a
                  onClick={e => {
                    // Do not trigger the parent container click handler
                    e.stopPropagation();
                  }}
                  ref={downloadAnchor}
                  title={`${document.title}`}
                  href={`${process.env.REACT_APP_PLP_URI}/api/document/${document.id}/download/${document.fileContentId}`}
                  target="_blank"
                >
                  {document.title}
                </a>
              ) : (
                document.title
              )}
            </h3>
          </header>
          <dl>
            <div>
              <dt>{intl.get('plp.grid.item.field.record.association').d('Linked as')}:</dt>
              <dd>{document.recordAssociation}</dd>
            </div>
            {document.documentType && (
              <div>
                <dt>{intl.get('plp.grid.item.field.common.type').d('Type')}:</dt>
                <dd>{document.documentType}</dd>
              </div>
            )}
            {document.fileSize && (
              <div>
                <dt>{intl.get('plp.grid.item.field.file.size').d('Size')}:</dt>
                <dd>{humanReadableFileSize(parseInt(document.fileSize, 10))}</dd>
              </div>
            )}
          </dl>
        </div>
      </section>
    );
  });
};

export default CardContent;
