import React from 'react';
import intl from 'react-intl-universal';
import {ICaseFile} from 'stores/FileStore';
import {identifiersToString} from 'utils/nationalIdentifierUtils';
import CardTemplate from '../Card/CardTemplate';
import {CardType} from '../Card/Card';
import {CardFieldCategory} from '../Card/Footer/CardTooltips';

interface Props {
  caseFile: ICaseFile;
  itemLink?: string;
  cardType: CardType;
}

const CardContent: React.FC<Props> = (props: Props) => {
  const {caseFile, cardType} = props;

  let additionalHeadLineProps = [];
  if (caseFile.properties) {
    additionalHeadLineProps.push({
      name: intl.get('plp.grid.item.field.common.gnr.bnr').d('GBNR'),
      value: identifiersToString(caseFile.properties),
    });
  }

  const fieldDefinitions = [];

  if (caseFile.caseResponsible) {
    fieldDefinitions.push({
      name: intl.get('plp.grid.item.field.case.responsible').d('Case responsible'),
      value: caseFile.caseResponsible,
    });
  }

  let footerFields = [];

  if (props.caseFile.fileType) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.type').d('Type'),
      value: props.caseFile.fileType,
      className: 'status',
      category: CardFieldCategory.TypeAndStatus,
    });
  }

  if (props.caseFile.type) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.status').d('Status'),
      value: props.caseFile.caseStatus,
      className: 'status',
      category: CardFieldCategory.TypeAndStatus,
    });
  }

  footerFields.push({
    name: intl.get('plp.grid.item.field.case.date').d('Case date'),
    value: caseFile.caseDate,
    category: CardFieldCategory.Date,
  });

  if (props.caseFile.screening) {
    footerFields.push({
      name: intl.get('plp.grid.item.field.common.screened').d('Screened'),
      value: props.caseFile.screening.name,
      category: CardFieldCategory.Screening,
    });
  }

  return (
    <CardTemplate
      entityIdFieldTitle={intl.get('plp.grid.item.field.common.file.ident')}
      entityTitle={intl.get('plp.grid.item.field.entity.type.case.file').d('Case file')}
      entityTypeCls="case-file"
      abbreviation={intl.get('plp.grid.item.field.entity.type.abbrev.case.file').d('CF')}
      itemNumber={caseFile.fileId}
      additionalHeadLineFields={additionalHeadLineProps}
      title={caseFile.title}
      fieldDefinitions={fieldDefinitions}
      footerFields={footerFields}
      itemLink={props.itemLink}
      cardType={cardType}
      screened={!!props.caseFile.screening}
    />
  );
};

export default CardContent;
