import React, {FC} from 'react';
import {IDocument} from '../../stores/DocumentStore';
import './css/meetings-section.scss';
import intl from 'react-intl-universal';

interface IProps {
  title: string;
  documents: IDocument[];
  loadMore?: () => void;
  hasError: boolean;
}

export const MeetingsSectionContainer: FC<IProps> = ({title, documents, loadMore, hasError}) => {
  if (documents.length === 0) {
    return null;
  }

  return (
    <section className="meeting-section">
      <h4>{title}</h4>
      <ul>
        {documents.map(doc => {
          // Do not show the document if it can not be downloaded
          if (!doc.fileContentId) {
            return null;
          }

          return (
            <li key={doc.id}>
              <span className={`document-extension pdf`}>pdf</span>
              <a
                title={`${doc.title}`}
                href={`${process.env.REACT_APP_PLP_URI}/api/document/${doc.id}/download/${doc.fileContentId}`}
                target="_blank"
              >
                {doc.title}
              </a>
            </li>
          );
        })}
      </ul>

      {(loadMore || hasError) && (
        <button className="btn btn-link load-more" onClick={loadMore}>
          {hasError ? (
            <>{intl.get('plp.meetings.sections.error.loading.try.again').d('Try again')}</>
          ) : (
            <>{intl.get('plp.load.more').d('Load more')}</>
          )}
        </button>
      )}
    </section>
  );
};
