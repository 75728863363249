import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '15',
  height: '14',
  fill: 'none',
  viewBox: '0 0 15 14',
  focusable: false,
};

const Send: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3301 7.92058C15.1427 7.57584 15.1427 6.42416 14.3301 6.07942L1.39055 0.58993C0.731098 0.310163 0 0.794171 0 1.51051V5.10496C0 5.61451 0.383136 6.04257 0.889568 6.09884L6.76376 6.75153C7.06029 6.78448 7.06028 7.21552 6.76376 7.24847L0.889567 7.90116C0.383135 7.95743 0 8.38549 0 8.89504V12.4895C0 13.2058 0.731099 13.6898 1.39055 13.4101L14.3301 7.92058Z"
      />
    </svg>
  );
};

export default Send;
