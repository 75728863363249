import React, {useEffect, useRef, useState} from 'react';
import {useObserver} from 'mobx-react-lite';
import useCloseMenuEffect from '../../../hooks/useCloseMenuEffect';
import intl from 'react-intl-universal';
import FilterFooter from './FilterFooter';
import FilterButton from './FilterButton';
import {v4 as uuidv4} from 'uuid';
import {BaseFilterProps, FilterViewMode} from '../types';
import FormField, {InputType} from '../../Form/FormField';

interface FilterMethods {
  get: () => string;
  set: (value: string) => void;
}

interface Props extends BaseFilterProps {
  currentFilters: {[filterName: string]: string};
  applyFilters: (filterValues: {[filterName: string]: string}) => void;
}

const BaseNationalIdentifiersFilter: React.FC<Props> = (props: Props) => {
  const containerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const defaultButtonLabel = intl.get('plp.filter.national.identifiers').d('National Identifiers');

  const [open, setOpen] = useState<boolean>(false);
  const [knr, setKnr] = useState<string>('');
  const [gnr, setGnr] = useState<string>('');
  const [bnr, setBnr] = useState<string>('');
  const [fnr, setFnr] = useState<string>('');
  const [snr, setSnr] = useState<string>('');
  const [focus, setFocus] = useState<string>();
  const [htmlId] = useState(uuidv4());

  //Sync selected gnr/bnr values with ones applied in the search store. This is needed when:
  //1) the user has closed the filter without clicking on the Apply button
  //2) the gnr/bnr filter values have been changed from another place
  useEffect(() => {
    filtersMetaData.map(filterMeta => filterMethods[filterMeta.name].set(props.currentFilters[filterMeta.name] || ''));
  }, [props.currentFilters, open]);

  const filterMethods: {[key: string]: FilterMethods} = {
    knr: {
      get: () => knr,
      set: setKnr,
    },
    gnr: {
      get: () => gnr,
      set: setGnr,
    },
    bnr: {
      get: () => bnr,
      set: setBnr,
    },
    fnr: {
      get: () => fnr,
      set: setFnr,
    },
    snr: {
      get: () => snr,
      set: setSnr,
    },
  };

  const filtersMetaData = [
    {
      label: intl.get('plp.filter.national.identifier.knr').d('Municipality unit number'),
      name: 'knr',
    },
    {
      label: intl.get('plp.filter.national.identifier.gnr').d('Cadastral unit number'),
      name: 'gnr',
    },
    {
      label: intl.get('plp.filter.national.identifier.bnr').d('Property unit number'),
      name: 'bnr',
    },
    {
      label: intl.get('plp.filter.national.identifier.fnr').d('Leasehold unit number'),
      name: 'fnr',
    },
    {
      label: intl.get('plp.filter.national.identifier.snr').d('Section unit number'),
      name: 'snr',
    },
  ];

  useCloseMenuEffect(containerRef, open, setOpen, props.filterViewMode === FilterViewMode.Stacked);

  return useObserver(() => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    const getFilterValue = (filterName: string) => {
      return filterMethods[filterName].get();
    };

    const setFilterValue = (filterName: string, value: string) => {
      filterMethods[filterName].set(value);
    };

    const applyFilters = () => {
      let filterValues: {[filterName: string]: string} = {};
      filtersMetaData.forEach(filterMeta => (filterValues[filterMeta.name] = filterMethods[filterMeta.name].get()));
      props.applyFilters(filterValues);
      if (props.filterViewMode === FilterViewMode.Default) {
        toggleOpen();
      }
    };

    const clearValues = () => {
      let filterValues: {[filterName: string]: string} = {};
      filtersMetaData.forEach(filterMeta => (filterValues[filterMeta.name] = ''));
      props.applyFilters(filterValues);
    };

    const onCloseClick = () => {
      setFocus('');
      toggleOpen();
    };

    //Expand the filter on arrow-down
    const onDropDownButtonKeyDown = (e: React.KeyboardEvent) => {
      switch (e.keyCode) {
        //key down
        case 40:
          if (!open) {
            e.preventDefault();
            setOpen(true);
            break;
          }
      }
    };

    //Collapse the filter on Shift+Tab on the first input
    const onInputKeyDown = (e: React.KeyboardEvent) => {
      switch (e.keyCode) {
        //Tab
        case 9:
          if (e.shiftKey) {
            setOpen(false);
            break;
          }
      }
    };

    const filtersWithValues = filtersMetaData.filter(filterMeta => !!props.currentFilters[filterMeta.name]);
    const prettyFilterName = filtersWithValues.map(filterMeta => filterMeta.name).join('/') || '';
    const prettyFilterValue =
      filtersWithValues.map(filterMeta => props.currentFilters[filterMeta.name]).join('/') || '';

    const noValueWasChanged = filtersMetaData.reduce(
      (noValueWasChanged: boolean, filterMeta: {label: string; name: string}) => {
        const filterName = filterMeta.name;
        return noValueWasChanged && props.currentFilters[filterName] === getFilterValue(filterName);
      },
      true,
    );

    return (
      <div className={`national-identifier-filter dropdown ${open ? 'is-shown' : ''}`} ref={containerRef}>
        <FilterButton
          open={open}
          toggleOpen={toggleOpen}
          label={prettyFilterName || defaultButtonLabel}
          value={prettyFilterValue}
          clearValue={clearValues}
          onKeyDown={onDropDownButtonKeyDown}
        />
        <div className={`dropdown-pane ${open ? 'is-shown' : ''}`} tabIndex={-1}>
          <div className="filters-grid">
            {filtersMetaData.map((filterMeta, index) => {
              const inputId = `${filterMeta.name.replace(' ', '').toLocaleLowerCase()}-${htmlId}`;
              const inputValue = getFilterValue(filterMeta.name);
              const onInputKeyDownHandler = index === 0 ? onInputKeyDown : undefined;
              return (
                <FormField
                  key={inputId}
                  inputType={InputType.Text}
                  inputId={inputId}
                  inputName={filterMeta.name}
                  inputValue={inputValue}
                  label={filterMeta.label}
                  onKeyDown={onInputKeyDownHandler}
                  onChange={(value: string) => {
                    setFilterValue(filterMeta.name, value);
                  }}
                  clearValue={() => {
                    setFilterValue(filterMeta.name, '');
                  }}
                  fieldInFocus={focus}
                  setFocusOn={() => {
                    setFocus(filterMeta.name);
                  }}
                  disabled={false}
                />
              );
            })}
          </div>
          <FilterFooter
            applyFilter={applyFilters}
            cancelAndClose={onCloseClick}
            applyButtonDisabled={noValueWasChanged}
          />
        </div>
      </div>
    );
  });
};

export default BaseNationalIdentifiersFilter;
