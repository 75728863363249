import React, {createRef, KeyboardEvent, useEffect, useLayoutEffect, useRef, useState} from 'react';
import EntityTypeFilter from '../Filters/EntityTypeFilter';
import intl from 'react-intl-universal';
import SearchIcon from '../Icons/SearchIcon';
import SearchIconSmall from '../Icons/SearchIconSmall';
import CrossReset from '../Icons/CrossReset';
import SingleSelect from '../SelectField/SingleSelect';
import ArrowDown from '../Icons/ArrowDown';
import SearchTooltip from '../Tooltip/SearchTooltip';
import {ExtendedSearchProps} from './SearchBar';
import {v4 as uuidv4} from 'uuid';
import './css/search.scss';
import {getCriteriaForType, getCriterionLabel, getSearchPlaceholderForCriterion} from './SearchOptions';
import {useRootStore} from '../../stores';
import {useObserver} from 'mobx-react-lite';

interface Props extends ExtendedSearchProps {
  disableButtons: boolean;
  valueLastSearched: string | undefined;
}

const Search: React.FC<Props> = (props: Props) => {
  const [htmlId] = useState(uuidv4());
  const searchInput: React.RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
  const [searchTooltipVisible, setSearchTooltipVisible] = useState(false);
  const [inputOnFocus, setInputOnFocus] = useState(false);
  const [clearButtonVisible, setClearButtonVisible] = useState(false);

  //Before we show the criterion tooltip we need to check that we have a search term and that the user has not performed a search.
  //However, closures such as the callbacks of setTimeout or throttle/debounce use the values of state/props at the time of call.
  //To do the check with the latest values we need to keep ref's of the state values.
  const searchTermRef = useRef(props.searchCriterionValue);
  searchTermRef.current = props.searchCriterionValue;
  const valueLastSearchedRef = useRef(props.valueLastSearched);
  valueLastSearchedRef.current = props.valueLastSearched;

  const rootStore = useRootStore();
  const searchStore = rootStore.searchStore;
  const uiStore = rootStore.uiStore;

  useEffect(() => {
    if (inputOnFocus) {
      setClearButtonVisible(true);
    }

    if (!inputOnFocus && !props.searchCriterionValue) {
      setClearButtonVisible(false);
    }

    if (!props.searchCriterionValue) {
      if (inputOnFocus) {
        props.setParentHasFocusRing(true);
      } else {
        props.setParentHasFocusRing(false);
      }
    } else {
      props.setParentHasFocusRing(false);
    }
  }, [inputOnFocus, props.searchCriterionValue]);

  return useObserver(() => {
    const criteriaDropdownBtnId = `criteria-filter-btn-${htmlId}`;

    const focusSearchInput = () => {
      if (searchInput && searchInput.current) {
        searchInput.current.focus();
      }
    };

    const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      switch (e.keyCode) {
        //Enter
        case 13:
          setSearchTooltipVisible(false);
          props.executeSearch();
          e.stopPropagation();
          e.preventDefault();
          break;
        default:
          break;
      }
    };

    const onSearchInputChange = (e: any) => {
      const now = Date.now();
      searchStore.setTimeOfLastSearchTermChange(now);
      setTimeout(() => {
        showTooltipWithDelay(now);
      }, 600);

      if (searchInput && searchInput.current) {
        props.setSearchCriterionValue(searchInput.current.value);
      }
    };

    const showTooltipWithDelay = (timeOfInputChangeCall: number) => {
      const now = Date.now();

      //There has been a newer input change - return
      if (searchStore.timeOfLastSearchTermChange > timeOfInputChangeCall) {
        return;
      }

      const ellapsedTimeSinceLastTermChange = now - searchStore.timeOfLastSearchTermChange;
      if (ellapsedTimeSinceLastTermChange > 10000 || ellapsedTimeSinceLastTermChange < 800) {
        setTimeout(() => {
          showTooltipWithDelay(timeOfInputChangeCall);
        }, 800);
      } else {
        showTooltip();
      }
    };

    const showTooltip = () => {
      if (searchTermRef.current && searchTermRef.current !== valueLastSearchedRef.current) {
        setSearchTooltipVisible(true);
        hideTooltipWithDelay(6000);
      } else {
        setSearchTooltipVisible(false);
      }
    };

    const hideTooltipWithDelay = (timeoutInMs: number) => {
      setTimeout(() => {
        hideTooltip();
      }, timeoutInMs);
    };

    const hideTooltip = () => {
      if (!uiStore.searchTooltipHovered) {
        setSearchTooltipVisible(false);
      }
    };

    const onTooltipClick = () => {
      setSearchTooltipVisible(false);
      const criteriaDropdownBtn = document.getElementById(criteriaDropdownBtnId);
      if (criteriaDropdownBtn) {
        criteriaDropdownBtn.click();
      }
    };

    const onTooltipMouseEnter = () => {
      uiStore.setSearchTooltipHovered(true);
      setSearchTooltipVisible(true);
    };

    const onTooltipMouseLeave = () => {
      uiStore.setSearchTooltipHovered(false);
      hideTooltipWithDelay(1500);
    };

    const onClearSearchValueClick = (e: React.MouseEvent) => {
      props.setSearchCriterionValue('');
      e.preventDefault();
      e.stopPropagation();
      focusSearchInput();
    };

    const onSearchClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      props.executeSearch();
    };

    const searchInputPlaceholder = getSearchPlaceholderForCriterion(props.selectedSearchCriterion);
    const searchCriteria = getCriteriaForType(props.selectedSearchType);
    const chooseCriteriaLabel = intl.get('plp.search.bar.tooltip.choose.search.criteria').d('Choose search criteria');

    return (
      <>
        <EntityTypeFilter
          onValueChange={focusSearchInput}
          onOptionFocus={() => props.setFocusIsInDropdown(true)}
          onOptionBlur={() => props.setFocusIsInDropdown(false)}
        />
        <SearchIconSmall />
        <input
          type="search"
          ref={searchInput}
          aria-label={intl.get('plp.search.bar.sr.search').d('Search')}
          value={props.searchCriterionValue}
          onChange={onSearchInputChange}
          onKeyDown={onInputKeyDown}
          placeholder={searchInputPlaceholder}
          onFocus={() => setInputOnFocus(true)}
          onBlur={() => setInputOnFocus(false)}
        />
        {clearButtonVisible && (
          <button
            className="btn-icon clear-search"
            disabled={props.disableClear}
            onClick={onClearSearchValueClick}
            aria-label={intl.get('plp.search.bar.sr.clear').d('Clear')}
          >
            <CrossReset />
          </button>
        )}
        {searchCriteria.length > 0 && (
          <>
            <SingleSelect
              options={searchCriteria.map(opt => {
                return {value: opt, label: getCriterionLabel(props.selectedSearchType, opt)};
              })}
              selected={props.selectedSearchCriterion}
              buttonLabel=""
              innerLabel={chooseCriteriaLabel}
              accessibleLabel={chooseCriteriaLabel}
              buttonIcon={<ArrowDown />}
              onChange={props.setSelectedSearchCriterion}
              isMobileWidth={uiStore.isMobile}
              dropDownBtnCls="btn-icon"
              dropDownContainerCls="search-criteria-filter"
              dropDownBtnId={criteriaDropdownBtnId}
              onOptionFocus={() => props.setFocusIsInDropdown(true)}
              onOptionBlur={() => props.setFocusIsInDropdown(false)}
            />
            <SearchTooltip
              searchCriteria={getCriterionLabel(props.selectedSearchType, props.selectedSearchCriterion)}
              onSearchCriteriaChangeClick={onTooltipClick}
              onMouseEnter={onTooltipMouseEnter}
              onMouseLeave={onTooltipMouseLeave}
              visible={searchTooltipVisible}
            />
          </>
        )}
        <button
          type="submit"
          disabled={props.disableButtons}
          className={`btn-icon execute-search ${
            !!props.searchCriterionValue && props.parentHasFocusRing ? 'active' : ''
          }`}
          onClick={onSearchClick}
          aria-label={intl.get('plp.search.bar.sr.search').d('Search')}
        >
          <SearchIcon fill={!!props.searchCriterionValue && props.parentHasFocusRing ? 'white' : '#808080'} />
        </button>
      </>
    );
  });
};

export default Search;
