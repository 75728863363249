import React from 'react';
import './css/tooltip.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
  tooltip: string;
}

const Tooltip: React.FC<Props> = (props: Props) => {
  return (
    <div className="tooltip-container">
      <div className="tooltip-text">
        {props.tooltip}
        <div className="rhombus" />
      </div>
      {props.children}
    </div>
  );
};

export default Tooltip;
