import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '16',
  height: '16',
  fill: '#666666',
  viewBox: '0 0 16 16',
  focusable: false,
};

const Cross: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2956 0.292854C14.6862 -0.0976485 15.3193 -0.0976136 15.7098 0.292932C16.1003 0.683478 16.1003 1.31664 15.7097 1.70715L9.41563 8.00056L15.7098 14.294C16.1003 14.6845 16.1003 15.3177 15.7098 15.7082C15.3193 16.0987 14.6862 16.0988 14.2956 15.7083L8.00134 9.4147L1.70707 15.7083C1.31652 16.0988 0.683357 16.0987 0.292854 15.7082C-0.0976487 15.3177 -0.0976135 14.6845 0.292932 14.294L6.58705 8.00056L0.292938 1.70715C-0.0976081 1.31664 -0.097643 0.683478 0.29286 0.292932C0.683362 -0.0976136 1.31653 -0.0976485 1.70707 0.292854L8.00134 6.58643L14.2956 0.292854Z"
      />
    </svg>
  );
};

export default Cross;
