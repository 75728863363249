import moment, {Moment} from 'moment';

export const DISPLAY_DATE_FORMAT = 'DD MM YYYY';
export const DISPLAY_DATE_FORMAT_MONTH_NAME = 'DD MMM YYYY';

export function localDateString(dateString: string, fallback?: string): string | null {
  const date = moment(dateString);

  if (date.isValid()) {
    return date.format('LL');
  }

  return fallback || null;
}

export function shortLocalDateString(dateString: string, fallback?: string): string | null {
  const date = moment(dateString);

  if (date.isValid()) {
    return date.format('MMM D, YY');
  }

  return fallback || null;
}

export function localDateTimeString(dateString: string, fallback?: string): string | null {
  const date = moment(dateString);

  if (date.isValid()) {
    return date.format('LLLL');
  }

  return fallback || null;
}

export function momentToISODateString(date: Moment): string {
  return date.format('YYYY-MM-DD');
}

export function isoDateStringToMoment(date: string | undefined): Moment | null {
  if (!date) {
    return null;
  }

  const parsedDate = moment(date);

  if (parsedDate.isValid()) {
    return parsedDate;
  }

  return null;
}

export function areFilterDatesEqual(d1: Moment | null, d2: Moment | null) {
  if (d1 === null && d2 === null) {
    return true;
  } else if (d1 === null || d2 === null) {
    return false;
  }

  return d1.day() === d2.day() && d2.month() === d2.month() && d1.year() === d2.year();
}
