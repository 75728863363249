import React from 'react';
import intl from 'react-intl-universal';
import '../css/filter-footer.scss';

interface FilterFooterProps {
  applyFilter: () => void;
  cancelAndClose: () => void;
  applyButtonDisabled?: boolean;
}

const FilterFooter: React.FC<FilterFooterProps> = (props: FilterFooterProps) => {
  //Collapse the filter on Tab when Cancel is the last active button
  const onCancelButtonKeyDown = (e: React.KeyboardEvent) => {
    if (props.applyButtonDisabled && e.keyCode === 9 && !e.shiftKey) {
      props.cancelAndClose();
    }
  };

  //Collapse the filter on Tab on the last button
  const onApplyButtonKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 9 && !e.shiftKey) {
      props.cancelAndClose();
    }
  };

  return (
    <footer>
      <button className="btn cancel" onClick={props.cancelAndClose} onKeyDown={onCancelButtonKeyDown}>
        {intl.get('plp.filter.cancel').d('Cancel')}
      </button>
      <button
        className="btn btn-link apply"
        onClick={props.applyFilter}
        onKeyDown={onApplyButtonKeyDown}
        disabled={props.applyButtonDisabled}
      >
        {intl.get('plp.search.bar.sr.apply').d('Apply')}
      </button>
    </footer>
  );
};

export default FilterFooter;
