import {action, observable} from 'mobx';
import {v4 as uuid} from 'uuid';

export class UIBlockerStore {
  @observable
  blocked: boolean;

  private actionsInProgress: Set<string>;

  private blockTimer: NodeJS.Timeout | null = null;

  private unblockTimer: NodeJS.Timeout | null = null;

  private unblockTimeout: number = 0;

  constructor() {
    this.actionsInProgress = new Set<string>();
    this.blocked = false;
    const timeoutConfig = parseInt(process.env.REACT_APP_BLOCK_TIMEOUT_IN_SECONDS || '0');
    this.unblockTimeout = timeoutConfig ? timeoutConfig : 0;
  }

  /**
   * Register an async action in order to block the UI
   * @returns the unique identifier of the registered action
   */
  @action.bound
  public notifyActionStarted(): string {
    const actionId = uuid();
    this.actionsInProgress.add(actionId);
    if (!this.blockTimer) {
      this.blockTimer = setTimeout(this.blockUI, 600);
    }
    return actionId;
  }

  @action.bound
  private blockUI(): void {
    if (this.actionsInProgress.size > 0 && !this.blocked) {
      this.blocked = true;
      if (this.unblockTimeout > 0) {
        this.setupUnblockTimer();
      }
    }
  }

  /**
   *   Every time a new action blocks the UI, the unblockTimer is reset with [unblockTimeout] seconds.
   *   The unblockTimer forcefully unblocks the UI [unblockTimeout] seconds after the last action was registered, but failed to complete.
   *   The unblockTimer is cleared when the UI is unblocked.
   */
  @action.bound
  private setupUnblockTimer() {
    if (this.unblockTimer) {
      clearTimeout(this.unblockTimer);
    }
    this.unblockTimer = setTimeout(this.forceUnblock, this.unblockTimeout * 1000);
  }

  /**
   * Notify that an action has completed
   * @param actionId: the unique identifier of the action registered using the "notifyActionStarted" method
   */
  @action.bound
  public notifyActionCompleted(actionId: string): void {
    this.actionsInProgress.delete(actionId);
    if (this.actionsInProgress.size === 0) {
      this.blocked = false;
      this.blockTimer = null;
      if (this.unblockTimer) {
        clearTimeout(this.unblockTimer);
      }
    }
  }

  @action.bound
  private forceUnblock(): void {
    this.actionsInProgress.clear();
    this.blocked = false;
    this.blockTimer = null;
    //todo: show notification
  }
}
