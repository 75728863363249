import React, {useRef, useState} from 'react';
import {useObserver} from 'mobx-react-lite';
import useCloseMenuEffect from '../../../hooks/useCloseMenuEffect';
import FilterButton from './FilterButton';
import SelectBase, {Option} from '../../SelectField/SelectBase';
import useDefaultProps from '../../../hooks/useDefaultProps';
import {FilterViewMode} from '../types';
import {SingleSelectProps} from '../../SelectField/SingleSelect';

interface FilterSelectProps extends SingleSelectProps {
  filterViewMode: FilterViewMode;
}

const propDefaults: Partial<FilterSelectProps> = {
  filterViewMode: FilterViewMode.Default,
};

const SelectFilter: React.FC<FilterSelectProps> = (props: FilterSelectProps) => {
  props = useDefaultProps(props, propDefaults);

  const selectContainer: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);

  useCloseMenuEffect(selectContainer, open, setOpen, props.filterViewMode === FilterViewMode.Stacked);

  return useObserver(() => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    const selectValue = (opt: Option) => {
      props.onChange(opt.value);
      if (props.filterViewMode === FilterViewMode.Default) {
        close();
      }
    };

    const clearValue = () => {
      props.onChange('');
    };

    //Expand the filter on arrow-down
    const onDropDownButtonKeyDown = (e: React.KeyboardEvent) => {
      switch (e.keyCode) {
        //key down
        case 40:
          if (!open) {
            e.preventDefault();
            setOpen(true);
            break;
          }
      }
    };

    const close = () => {
      setOpen(false);
    };

    return (
      <div className={`record-filter dropdown ${open ? 'is-shown' : ''}`} ref={selectContainer}>
        <FilterButton
          open={open}
          toggleOpen={toggleOpen}
          clearValue={clearValue}
          label={props.buttonLabel}
          value={props.selected ? props.selected : ''}
          onKeyDown={onDropDownButtonKeyDown}
        />
        <SelectBase
          options={props.options}
          selected={props.selected}
          name={props.buttonLabel}
          open={open}
          close={close}
          onChange={selectValue}
          closeOnTab={props.closeOnTab !== undefined ? props.closeOnTab : !props.isMobileWidth}
        />
      </div>
    );
  });
};

export default SelectFilter;
