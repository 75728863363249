import React from 'react';
import {useObserver} from 'mobx-react-lite';
import Card, {CardType} from '../Card/Card';
import {useRootStore} from 'stores/index';
import Pagination from '../Pagination/Pagination';
import {getNodeLabel} from '../../utils/entityTypeLabels';
import PageSizeSwitcher from '../Pagination/PageSizeSwitcher';
import {ICaseFile, IFolder, IMeetingFolder} from '../../stores/FileStore';
import {IDocument} from '../../stores/DocumentStore';
import {IBasicRecord, IMeetingRecord, IRegistryEntry} from '../../stores/RecordStore';
import './css/non-root-grid.scss';

const GridLimited: React.FC = () => {
  const rootStore = useRootStore();
  const treeStore = rootStore.treeStore;

  return useObserver(() => {
    let entities: Array<
      ICaseFile | IFolder | IDocument | IRegistryEntry | IBasicRecord | IMeetingRecord | IMeetingFolder
    > = rootStore.entityList;
    let typeLabel = getNodeLabel(treeStore.currentNode);

    return (
      <>
        <main id="maincontent" className="plp-non-root-grid main-content" tabIndex={-1}>
          {entities.length > 0 && (
            <header>
              <h2>{typeLabel}</h2>
              <PageSizeSwitcher />
            </header>
          )}
          {entities.map((archiveEntity) => {
            return (
              <Card
                archiveEntity={archiveEntity}
                showParentItem={false}
                cardType={CardType.ChildListItem}
              />
            );
          })}
          <Pagination />
        </main>
      </>
    );
  });
};

export default GridLimited;
