import React, {useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {CardField, CardFieldCategory} from './CardTooltips';
import ChevronRightParentLink from '../../Icons/ChevronRightParentLink';
import {
  getCardFieldIcon,
  joinCardFields,
  getUniqueSortedCardFieldCategories,
  groupCardFieldsByCategory,
} from '../../../utils/tooltips';
import {localDateString} from '../../../utils/date';
import intl from 'react-intl-universal';

interface Props {
  fields: CardField[];
}

const CollapsibleCardFields = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [htmlId] = useState(uuidv4());

  const categories = getUniqueSortedCardFieldCategories(props.fields);
  const fieldsByCategory = groupCardFieldsByCategory(props.fields);
  const shortJoinedFields: CardField[] = joinCardFields(categories, fieldsByCategory, true);

  let buttonId = `button-${htmlId}`;
  let panelId = `fields-${htmlId}`;

  return (
    <div className="collapsible-fields">
      <button
        id={buttonId}
        aria-expanded={open}
        aria-controls={panelId}
        onMouseUp={e => {
          e.stopPropagation();
        }}
        onClick={() => {
          setOpen(!open);
        }}
        className={`${open ? 'open' : ''}`}
        aria-label={
          open
            ? intl.get('plp.grid.item.more.info').d('Hide info')
            : intl.get('plp.grid.item.show.more.info').d('Show more info')
        }
      >
        {!open && (
          <span className="short-fields-button-label">
            {shortJoinedFields.map(field => (
              <React.Fragment key={field.name}>
                {getCardFieldIcon(field.category)}
                {field.value && <span>{field.value}</span>}
              </React.Fragment>
            ))}
          </span>
        )}
        <ChevronRightParentLink />
      </button>
      {open && (
        <div
          className="table-container"
          onMouseUp={e => {
            e.stopPropagation();
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <p id={`table-label-${htmlId}`} className="sr-only">
            {intl.get('plp.grid.item.more.info').d('More info')}
          </p>
          <table aria-labelledby={`table-label-${htmlId}`}>
            <thead className="sr-only">
              <tr>
                <th>{intl.get('plp.grid.item.table.icon.column').d('Icon')}</th>
                <th>{intl.get('plp.grid.item.table.field.column'.d('Field'))}</th>
                <th>{intl.get('plp.grid.item.table.value.column').d('Value')}</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, catIndex) =>
                fieldsByCategory[category].map((field, index) => (
                  <tr
                    key={field.name}
                    className={
                      index === fieldsByCategory[category].length - 1 && catIndex < categories.length - 1
                        ? 'last-group-row'
                        : ''
                    }
                  >
                    <td>{index === 0 && getCardFieldIcon(category)}</td>
                    <td>{field.name}</td>
                    <td>{category === CardFieldCategory.Date ? localDateString(field.value) : field.value}</td>
                  </tr>
                )),
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CollapsibleCardFields;
