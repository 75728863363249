import React from 'react';
import CardContent from './CardContent';
import {useRootStore} from 'stores/index';
import {ICaseFile} from 'stores/FileStore';
import {BaseCardProps} from '../Card/Card';
import {FileTypes} from '../../types/EntityTypes';

interface Props extends BaseCardProps {
  caseFile: ICaseFile;
}

const CaseFile: React.FC<Props> = (props: Props) => {
  const {caseFile, cardType, isOpen} = props;

  const rootStore = useRootStore();
  const recordStore = rootStore.recordStore;
  const navigationStore = rootStore.navigationStore;

  const itemLink = navigationStore.getBranchRecordsUrl(FileTypes.CaseFile, props.caseFile.id, 1, recordStore.pageSize);

  return (
    <CardContent
      caseFile={caseFile}
      cardType={cardType}
      itemLink={!isOpen ? itemLink : undefined}
    />
  );
};

export default CaseFile;
