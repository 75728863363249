import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useObserver} from 'mobx-react-lite';
import intl from 'react-intl-universal';
import {DateRangePicker, FocusedInputShape} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment, {Moment} from 'moment';
import {
  areFilterDatesEqual,
  DISPLAY_DATE_FORMAT,
  isoDateStringToMoment,
  momentToISODateString,
} from '../../../utils/date';
import ShortArrowRight from '../../Icons/ShortArrowRight';
import FilterFooter from './FilterFooter';
import FilterButton from './FilterButton';
import {getDateRangePickerPhrases} from './DateRangePickerLocalization';
import {BaseFilterProps, FilterViewMode} from '../types';
import useDateInputValidationHook from './useDateInputValidationHook';
import useCloseDatePickerEffect from './useCloseDatePickerEffect';

const START_DATE: FocusedInputShape = 'startDate';
const END_DATE: FocusedInputShape = 'endDate';

interface Props extends BaseFilterProps {
  currentFromFilter: string;
  currentToFilter: string;
  applyFilter: (from: string, to: string) => void;
}

const BaseDateRangeFilter: React.FC<Props> = (props: Props) => {
  const localizedPhrases = getDateRangePickerPhrases();

  const containerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const liveAriaRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [hasBeenExpanded, setHasBeenExpanded] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(START_DATE);
  const [disabledApplyButton, setDisabledApplyButton] = useState(true);

  //Enable button Apply when the dates are changed and are different from the applied dates
  useEffect(() => {
    const currentStartDateFilter = isoDateStringToMoment(props.currentFromFilter);
    const currentEndDateFilter = isoDateStringToMoment(props.currentToFilter);
    if (
      !areFilterDatesEqual(startDate, currentStartDateFilter) ||
      !areFilterDatesEqual(endDate, currentEndDateFilter)
    ) {
      setDisabledApplyButton(false);
    } else {
      setDisabledApplyButton(true);
    }
  }, [startDate, endDate]);

  useCloseDatePickerEffect(containerRef, open, setOpen, props.filterViewMode === FilterViewMode.Stacked);

  //Sync dates with applied ones. This is needed because
  //1) the user might have closed the filter without clicking on Apply
  //2) the filter could have been changed from another place - so sync, but not while the filter is open
  useEffect(() => {
    syncDatesWithApplied();
  }, [props.currentFromFilter, props.currentToFilter]);

  useEffect(() => {
    if (open) {
      syncDatesWithApplied();
    }
  }, [open]);

  const syncDatesWithApplied = () => {
    const currentStartDateFilter = isoDateStringToMoment(props.currentFromFilter);
    setStartDate(currentStartDateFilter);
    removeInvalidStartDateClass();
    const currentEndDateFilter = isoDateStringToMoment(props.currentToFilter);
    setEndDate(currentEndDateFilter);
    removeInvalidEndDateClass();
  };

  //The useEffect below is a WORKAROUND that allows us to clear invalid input values when closing the filter.
  //Problem: The airbnb component sets startDate/endDate to null when the typed value is not a valid date.
  //There is NO clean way to clear invalid input on close. If we just set startDate/endDate to null/undefined,
  //nothing changes due to some complexity in the logic.
  //Solution: Before close, if startDate/endDate is null, but there is invalid input, set startDate/endDate to a
  //the invalid value. Then, the syncing useEffect above will set the value to null.
  useEffect(() => {
    if (!open) {
      setAValueForInvalidInput(startDate, setStartDate, START_DATE);
      setAValueForInvalidInput(endDate, setEndDate, END_DATE);
      setDisabledApplyButton(true);
    }
  }, [open]);

  const setAValueForInvalidInput = (
    dateValue: Moment | null,
    setValue: (val: Moment | null) => void,
    inputId: FocusedInputShape,
  ) => {
    const dateInput = document.getElementById(inputId) as HTMLInputElement | null;
    if (dateInput) {
      if (dateValue === null && dateInput.value) {
        setValue(moment(dateInput.value));
      }
    }
  };

  const fixSelectedStartDateClassNames = useCallback(() => {
    if (containerRef && containerRef.current) {
      const selectedStart: HTMLCollection = containerRef.current.getElementsByClassName('CalendarDay__selected_start');
      for (let i = 0; i < selectedStart.length; i++) {
        const el: Element | null = selectedStart[i];
        if (el && el.classList) {
          el.classList.remove('CalendarDay__selected_start_no_selected_end');
        }
      }
    }
  }, []);

  useEffect(() => {
    if (open && !hasBeenExpanded) {
      setHasBeenExpanded(true);
    }
  }, [open, hasBeenExpanded]);

  //Focus start date input on expand
  useEffect(() => {
    if (open) {
      const startDateInput = document.getElementById(START_DATE);
      if (startDateInput) {
        startDateInput.focus();
      }
    }
  }, [open]);

  // Attach onKeyDown handler to the start date input in order to close the filter on Shift+Tab
  useEffect(() => {
    if (hasBeenExpanded) {
      const onKeyDown = (e: any) => {
        if (e.keyCode === 9 && e.shiftKey) {
          setOpen(false);
        }
      };

      const startDateInput = document.getElementById(START_DATE);
      if (startDateInput) {
        startDateInput.addEventListener('keydown', onKeyDown);
        startDateInput.setAttribute('aria-label', intl.get('plp.filter.calendar.start.date').d('Start date'));

        return () => startDateInput.removeEventListener('keydown', onKeyDown);
      }
    }
  }, [hasBeenExpanded]);

  //Set better 'aria-label' values to the two inputs
  useEffect(() => {
    if (hasBeenExpanded) {
      const startDateInput = document.getElementById(START_DATE);
      if (startDateInput) {
        startDateInput.setAttribute('aria-label', intl.get('plp.filter.calendar.start.date').d('Start date'));
      }
      const endDateInput = document.getElementById(END_DATE);
      if (endDateInput) {
        endDateInput.setAttribute('aria-label', intl.get('plp.filter.calendar.end.date').d('End date'));
      }
    }
  }, [hasBeenExpanded]);

  const updateLiveAria = (text: string) => {
    if (liveAriaRef && liveAriaRef.current) {
      liveAriaRef.current.textContent = text;
    }
  };

  const [validateStartDate, removeInvalidStartDateClass] = useDateInputValidationHook(
    START_DATE,
    DISPLAY_DATE_FORMAT,
    hasBeenExpanded,
    updateLiveAria,
  );
  const [validateEndDate, removeInvalidEndDateClass] = useDateInputValidationHook(
    END_DATE,
    DISPLAY_DATE_FORMAT,
    hasBeenExpanded,
    updateLiveAria,
  );

  //Validate explicitly because the blur event is not triggered when switching the input's
  useEffect(() => {
    if (focusedInput === START_DATE) {
      validateEndDate();
    } else {
      validateStartDate();
    }
  }, [focusedInput]);

  const onFocusChange = useCallback((newlyFocusedInput: FocusedInputShape | null) => {
    if (!newlyFocusedInput) return; // don't update the focusedInput if trying to close the DRP
    setFocusedInput(newlyFocusedInput);
  }, []);

  return useObserver(() => {
    const onCloseClick = () => {
      setOpen(false);
    };

    const confirmDates = () => {
      const from = startDate ? momentToISODateString(startDate) : '';
      const to = endDate ? momentToISODateString(endDate) : '';
      props.applyFilter(from, to);
      if (props.filterViewMode === FilterViewMode.Default) {
        setOpen(false);
      }
    };

    const clearValues = () => {
      setFocusedInput(START_DATE);
      props.applyFilter('', '');
    };

    //Expand the filter on arrow-down
    const onDropDownButtonKeyDown = (e: React.KeyboardEvent) => {
      switch (e.keyCode) {
        //key down
        case 40:
          if (!open) {
            e.preventDefault();
            setOpen(true);
            break;
          }
      }
    };

    const currentStartDateFilter = isoDateStringToMoment(props.currentFromFilter);
    const currentEndDateFilter = isoDateStringToMoment(props.currentToFilter);
    const prettyAppliedValue =
      currentStartDateFilter || currentEndDateFilter
        ? `${currentStartDateFilter ? currentStartDateFilter.format(DISPLAY_DATE_FORMAT) : ''} - ${
            currentEndDateFilter ? currentEndDateFilter.format(DISPLAY_DATE_FORMAT) : ''
          }`
        : '';

    return (
      <div className={`dates-filter dropdown ${open ? 'is-shown' : ''}`} ref={containerRef}>
        <div className="sr-only" ref={liveAriaRef} aria-live="polite"></div>
        <FilterButton
          open={open}
          toggleOpen={() => {
            setOpen(!open);
          }}
          clearValue={clearValues}
          label={intl.get('plp.filter.date.range').d('Date range')}
          value={prettyAppliedValue}
          onKeyDown={onDropDownButtonKeyDown}
        />
        <div
          className={`dropdown-pane ${open ? 'is-shown' : ''} ${
            props.filterViewMode === FilterViewMode.Default ? 'default-view' : 'stacked-view'
          }`}
          tabIndex={-1}
        >
          <div className="datepicker-container">
            {hasBeenExpanded && (
              <DateRangePicker
                startDate={startDate}
                startDateId={START_DATE}
                endDate={endDate}
                endDateId={END_DATE}
                onDatesChange={({startDate, endDate}) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                  if (startDate && endDate) {
                    fixSelectedStartDateClassNames();
                  }
                }}
                renderDayContents={moment => {
                  return (
                    <div className="day-contents">
                      <div>{moment.date()}</div>
                    </div>
                  );
                }}
                firstDayOfWeek={1}
                focusedInput={focusedInput}
                onFocusChange={onFocusChange}
                keepOpenOnDateSelect={true}
                minimumNights={0}
                showClearDates={true}
                displayFormat={DISPLAY_DATE_FORMAT}
                startDatePlaceholderText={DISPLAY_DATE_FORMAT}
                endDatePlaceholderText={DISPLAY_DATE_FORMAT}
                customArrowIcon={<span>{intl.get('plp.filter.date.range.to').d('To')}</span>}
                numberOfMonths={1}
                hideKeyboardShortcutsPanel={false}
                showDefaultInputIcon={false}
                noBorder={true}
                enableOutsideDays={true}
                isOutsideRange={() => false}
                navPrev={<ShortArrowRight />}
                navNext={<ShortArrowRight />}
                phrases={localizedPhrases}
              />
            )}
            <FilterFooter
              applyFilter={confirmDates}
              cancelAndClose={onCloseClick}
              applyButtonDisabled={disabledApplyButton}
            />
          </div>
        </div>
      </div>
    );
  });
};

export default BaseDateRangeFilter;
