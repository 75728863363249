export enum FileTypes {
  CaseFile = 'case-file',
  MeetingFolder = 'meeting-folder',
  Folder = 'folder',
  FileSubset = 'file-subset',
}

export const FileUnion: string = 'file';

export enum RecordTypes {
  RegistryEntry = 'registry-entry',
  MeetingRecord = 'meeting-record',
  BasicRecord = 'basic-record',
  RecordUnion = 'record',
  RecordSubset = 'record-subset',
}

export enum DocumentTypes {
  Document = 'document',
}
