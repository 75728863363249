import {CodeListValue} from './Types';
import {PaginatableStore} from './PaginatableStore';
import {SearchStore} from './SearchStore';
import {action, observable} from 'mobx';
import client from '../client/client';

export interface ISeriesTypeListResponse {
  hasMore: boolean;
  page: number;
  pageSize: number;
  results: Array<CodeListValue>;
}
const SEARCH_PAGE_SIZE = 20;

export class SeriesTypesStore extends PaginatableStore {
  private readonly searchStore: SearchStore;

  @observable
  entitiesList: Array<CodeListValue> = [];

  constructor(searchStore: SearchStore) {
    super();
    this.searchStore = searchStore;
  }

  @action.bound
  public changePage(page: number): void {
    this.fetchEntities(page);
  }

  public getUrlForPage(page: number): string {
    throw new Error('Not implemented')
  }

  @action.bound
  public async fetchEntities(page: number, type?: CodeListValue): Promise<void> {
    if (this.isLoading) {
      return;
    }
    this.setIsLoading(true);

    try {
      const response = await client.fetchSeriesTypes(1, SEARCH_PAGE_SIZE);

      this.page = page;
      this.entitiesList = response.results;
      this.hasMore = response ? response.hasMore : false;
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
    }
  }

  @action.bound
  public clearEntities(): void {
    this.entitiesList = [];
  }
}
