import React from 'react';
import {getLocaleLabel, isSupported, supportedLocales} from 'hooks/useTranslations';
import Planet from 'components/Icons/Planet';
import ILocaleProps from 'types/ILocaleProps';
import SingleSelect from '../SelectField/SingleSelect';
import './css/locale-switcher.scss';
import intl from 'react-intl-universal';

interface Props extends ILocaleProps {
  isMobile: boolean;
}

const LocaleSwitcher: React.FC<Props> = (props: Props) => {
  return (
    <div className="locale-switcher">
      <SingleSelect
        options={supportedLocales().map(item => {
          return {label: getLocaleLabel(item), value: item};
        })}
        selected={props.selectedLocale}
        buttonLabel={props.loadedLocale.toUpperCase()}
        buttonIcon={<Planet />}
        onChange={(item: string) => {
          if (isSupported(item)) {
            props.setSelectedLocale(item);
          }
        }}
        isMobileWidth={props.isMobile}
        accessibleLabel={intl.get('plp.change.language').d('Change language')}
      />
    </div>
  );
};

export default LocaleSwitcher;
