import {AxiosInstance, AxiosResponse} from 'axios';
import {IPLPClient} from './IPLPClient';
import {
  FileSubsetFilterCriteria,
  FileSubsetSearchCriteria,
  IFile,
  IFilesResponse,
  MeetingFolderFilterCriteria,
  MeetingFolderSearchCriteria,
} from 'stores/FileStore';
import {IRecord, IRecordsResponse, RecordSubsetFilterCriteria, RecordSubsetSearchCriteria} from 'stores/RecordStore';
import {IDocumentsResponse} from 'stores/DocumentStore';
import {DocumentTypes, FileTypes, RecordTypes} from 'types/EntityTypes';
import {
  getMeetingBoardParametersString,
  getParametersString,
  getSearchParametersString,
} from 'utils/getParametersString';
import {IMeetingBoardResponse} from '../stores/MeetingBoardStore';
import {IRegistryEntryTypeListResponse} from '../stores/RegistryEntryTypesStore';
import {ISeriesTypeListResponse} from "../stores/SeriesTypesStore";

export type RecordType = 'BasicRecord' | 'MeetingRecord' | 'RegistryEntry';

export interface IRequestDocuments {
  id: string;
  recordType: RecordType;
  name: string;
  email: string;
  phone?: string;
  comment?: string;
  verifyEmail?: string;
}

export default class PLPClient implements IPLPClient {
  private readonly client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async fetchSelectedFile<T extends IFile>(fileType: FileTypes, fileId: string): Promise<IFilesResponse<T>> {
    const response: AxiosResponse<IFilesResponse<T>> = await this.client.get(encodeURI(`/api/${fileType}/${fileId}`));
    return response.data;
  }

  public async fetchFiles<T extends IFile>(
    fileType: FileTypes,
    page: number,
    pageSize: number,
    searchKey?: FileSubsetSearchCriteria | MeetingFolderSearchCriteria,
    searchValue?: string,
    filters?: Map<FileSubsetFilterCriteria | MeetingFolderFilterCriteria, string>,
  ): Promise<IFilesResponse<T>> {
    const response: AxiosResponse<IFilesResponse<T>> = await this.client.get(
      encodeURI(`/api/${fileType}?${getSearchParametersString(page, pageSize, searchKey, searchValue, filters)}`),
    );
    return response.data;
  }

  public async fetchSelectedRecord<T extends IRecord>(
    recordType: RecordTypes,
    recordId: string,
  ): Promise<IRecordsResponse<T>> {
    const response: AxiosResponse<IRecordsResponse<T>> = await this.client.get(
      encodeURI(`/api/${recordType}/${recordId}`),
    );
    return response.data;
  }

  public async fetchRecords<T extends IRecord>(
    recordType: RecordTypes,
    page: number,
    pageSize: number,
    searchKey?: RecordSubsetSearchCriteria,
    searchValue?: string,
    filters?: Map<RecordSubsetFilterCriteria, string>,
  ): Promise<IRecordsResponse<T>> {
    const response: AxiosResponse<IRecordsResponse<T>> = await this.client.get(
      encodeURI(`/api/${recordType}?${getSearchParametersString(page, pageSize, searchKey, searchValue, filters)}`),
    );
    return response.data;
  }

  public async fetchMeetingRecordsExcludingTypes<T extends IRecord>(
    fileId: string,
    excludedTypes: string[],
    page: number,
    pageSize: number,
  ): Promise<IRecordsResponse<T>> {
    let response: AxiosResponse<IRecordsResponse<T>>;

    response = await this.client.get(
      encodeURI(
        `/api/${FileTypes.MeetingFolder}/${fileId}/${RecordTypes.RecordUnion}?${getParametersString(
          page,
          pageSize,
        )}&excludeMeetingRecordTypes=${excludedTypes.join(',')}`,
      ),
    );

    return response.data;
  }

  public async fetchMeetingDocuments(
    fileId: string,
    page: number,
    pageSize: number,
    includeTypes: string[],
    recordAssociations: string[],
    documentTypes: string[],
  ): Promise<IDocumentsResponse> {
    let response: AxiosResponse<IDocumentsResponse>;

    if (!includeTypes || includeTypes.length === 0) {
      throw new Error("Invalid types");
    }

    let recordAssocs = '';
    if (recordAssociations && recordAssociations.length > 0) {
      recordAssocs = `&recordAssociations=${recordAssociations.join(',')}`;
    }

    let docTypes = '';
    if (documentTypes && documentTypes.length > 0) {
      docTypes = `&documentTypes=${documentTypes.join(',')}`;
    }

    response = await this.client.get(
      encodeURI(
        `/api/${FileTypes.MeetingFolder}/${fileId}/${RecordTypes.RecordUnion}/document?${getParametersString(
          page,
          pageSize,
        )}&includeMeetingRecordTypes=${includeTypes.join(',')}${recordAssocs}${docTypes}`,
      ),
    );

    return response.data;
  }

  public async fetchRecordsByFileId<T extends IRecord>(
    fileType: FileTypes,
    fileId: string,
    recordType: RecordTypes,
    page: number,
    pageSize: number,
  ): Promise<IRecordsResponse<T>> {
    const response: AxiosResponse<IRecordsResponse<T>> = await this.client.get(
      encodeURI(`/api/${fileType}/${fileId}/${recordType}?${getParametersString(page, pageSize)}`),
    );
    return response.data;
  }

  public async fetchDocumentsByRecordId(
    recordType: RecordTypes,
    recordId: string,
    documentType: DocumentTypes,
    page: number,
    pageSize: number,
  ): Promise<IDocumentsResponse> {
    const response: AxiosResponse<IDocumentsResponse> = await this.client.get(
      encodeURI(`/api/${recordType}/${recordId}/${documentType}?${getParametersString(page, pageSize)}`),
    );
    return response.data;
  }

  public async searchForMeetingBoards(term: string, page: number, pageSize: number): Promise<IMeetingBoardResponse> {
    const response: AxiosResponse<IMeetingBoardResponse> = await this.client.get(
      encodeURI(`/api/${FileTypes.MeetingFolder}/list/board?${getMeetingBoardParametersString(page, pageSize, term)}`),
    );
    return response.data;
  }

  public async fetchRegistryEntryTypes(page: number, pageSize: number): Promise<IRegistryEntryTypeListResponse> {
    const response: AxiosResponse<IRegistryEntryTypeListResponse> = await this.client.get(
      encodeURI(`/api/${RecordTypes.RegistryEntry}/list/type?${getParametersString(page, pageSize)}`),
    );
    return response.data;
  }

  public async fetchSeriesTypes(page: number, pageSize: number): Promise<ISeriesTypeListResponse> {
    const response: AxiosResponse<IRegistryEntryTypeListResponse> = await this.client.get(
        encodeURI(`/api/${FileTypes.FileSubset}/list/type?${getParametersString(page, pageSize)}`),
    );
    return response.data;
  }

  public async requestDocuments(data: IRequestDocuments): Promise<AxiosResponse<any>> {
    return this.client.post(encodeURI(`/api/document-request`), data);
  }
}
