import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '12',
  height: '12',
  fill: '#758F9A',
  viewBox: '0 0 12 12',
  focusable: false,
};

const Document: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12.0002C2.44772 12.0002 2 11.5524 2 11.0002V1.00015C2 0.447868 2.44772 0.000152588 3 0.000152588H6.58579C6.851 0.000152588 7.10536 0.10551 7.29289 0.293046L10 3.00015V11.0002C10 11.5524 9.55228 12.0002 9 12.0002H3ZM9 4.00015L6 1.00015V4.00015H9Z"
      />
    </svg>
  );
};

export default Document;
