import {SearchableCriteria, SearchableType} from '../../stores/SearchStore';
import {FileSubsetSearchCriteria, MeetingFolderSearchCriteria} from '../../stores/FileStore';
import {RecordSubsetSearchCriteria} from '../../stores/RecordStore';
import intl from 'react-intl-universal';
import {FileTypes, RecordTypes} from '../../types/EntityTypes';

export interface Criterion {
  type: SearchableCriteria;
  label: () => string;
}

const criteriaOptionsMap: {[key: string]: string[]} = {
  [FileTypes.FileSubset]: [FileSubsetSearchCriteria.title, FileSubsetSearchCriteria.fileId],
  [RecordTypes.RecordSubset]: [
    RecordSubsetSearchCriteria.title,
    RecordSubsetSearchCriteria.recordId,
    RecordSubsetSearchCriteria.correspondenceParty,
  ],
  [FileTypes.MeetingFolder]: [],
};

const criteriaMetaMap: {[key: string]: {[key: string]: Criterion}} = {
  [FileTypes.FileSubset]: {
    title: {
      type: FileSubsetSearchCriteria.title,
      label: () => intl.get('plp.search.bar.section.buttons.criteria.title').d('Title'),
    },
    fileId: {
      type: FileSubsetSearchCriteria.fileId,
      label: () => intl.get('plp.search.bar.section.buttons.criteria.case.file.file.id').d('Case number'),
    },
  },
  [FileTypes.MeetingFolder]: {
    title: {
      type: MeetingFolderSearchCriteria.title,
      label: () => intl.get('plp.search.bar.section.buttons.criteria.title').d('Title'),
    },
  },
  [RecordTypes.RecordSubset]: {
    title: {
      type: RecordSubsetSearchCriteria.title,
      label: () => intl.get('plp.search.bar.section.buttons.criteria.title').d('Title'),
    },
    recordId: {
      type: RecordSubsetSearchCriteria.recordId,
      label: () => intl.get('plp.search.bar.section.buttons.criteria.record.subset.record.id').d('Record number'),
    },
    correspondenceParty: {
      type: RecordSubsetSearchCriteria.correspondenceParty,
      label: () =>
        intl
          .get('plp.search.bar.section.buttons.criteria.record.subset.sender.recipient.group.recipient')
          .d('Sender/recipient/group recipient'),
    },
  },
};

export function getCriteriaForType(entityType: SearchableType): string[] {
  return criteriaOptionsMap[entityType] || '';
}

export function getCriterionValue(entityType: SearchableType, criterion: string): string {
  return criteriaMetaMap[entityType][criterion]?.type || '';
}

export function getCriterionLabel(entityType: SearchableType, criterion: string): string {
  return criteriaMetaMap[entityType][criterion]?.label() || '';
}

export function getCriterionType(entityType: SearchableType, criterion: string): SearchableCriteria {
  return criteriaMetaMap[entityType][criterion]?.type || '';
}

export function getSearchPlaceholderForCriterion(criterion: SearchableCriteria): string {
  switch (criterion) {
    case FileSubsetSearchCriteria.title:
    case RecordSubsetSearchCriteria.title:
    case MeetingFolderSearchCriteria.title:
      return intl.get('plp.search.bar.placeholder.title').d('Search by title');
    case FileSubsetSearchCriteria.fileId:
      return intl.get('plp.search.bar.placeholder.case.file.file.id').d('Search by case number');
    case RecordSubsetSearchCriteria.recordId:
      return intl.get('plp.search.bar.placeholder.record.subset.record.id').d('Search by record number');
    case RecordSubsetSearchCriteria.correspondenceParty:
      return intl
        .get('plp.search.bar.placeholder.record.subset.sender.recipient')
        .d('Search by sender/recipient/group recipient');
    default:
      return '';
  }
}
