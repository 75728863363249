import React, {FC, useEffect, useRef, useState} from 'react';
import useOrganizationAssets, {MeetingSection} from '../../hooks/useOrganizationAssets';
import {useRootStore} from '../../stores';
import {observer} from 'mobx-react';
import client from '../../client/client';
import {IDocument} from '../../stores/DocumentStore';
import './css/sidebar.scss';
import {MeetingsSectionContainer} from './MeetingsSectionContainer';
import intl from 'react-intl-universal';

interface ISectionState {
  hasMore: boolean;
  page: number;
  docs: IDocument[];
  hasError: boolean;
}

const defaultSectionState: ISectionState = {
  docs: [],
  hasMore: false,
  page: 0,
  hasError: false,
};

const defaultPageSize = 100;

export const MeetingRightSidebar: FC = observer(() => {
  const organizationAssets = useOrganizationAssets();
  const rootStore = useRootStore();
  const mountedRef = useRef<boolean>(false);

  //Keep track if the component is mounted for handling state manipulation from async operations
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const [agenda, setAgenda] = useState<ISectionState>(defaultSectionState);
  const [noticeOfMeeting, setNoticeOfMeeting] = useState<ISectionState>(defaultSectionState);
  const [minutesOfTheMeeting, setMinutesOfTheMeeting] = useState<ISectionState>(defaultSectionState);

  const assetsLoaded = organizationAssets.organizationAssetsLoaded;
  const meetingSections = organizationAssets.organizationAssets?.meetingSections;
  const currentFileId = rootStore.fileStore.selectedEntityId;

  const loadMeetingSection = async (
    fileId: string,
    currentState: ISectionState,
    section: MeetingSection,
    setSection: (section: ISectionState) => void,
    startPage: number,
  ) => {
    const recordTypes = section?.recordTypes || [];
    const docAssoc = section?.documentRecordAssociations || [];
    const docTypes = section?.documentTypes || [];

    if (!recordTypes.length || !docAssoc.length) {
      return;
    }

    try {
      const res = await client.fetchMeetingDocuments(
        fileId,
        startPage,
        defaultPageSize,
        recordTypes,
        docAssoc,
        docTypes,
      );

      // Is the component still mounted? If not, then then we should not update the state as the user has navigated.
      if (!mountedRef.current) {
        return;
      }

      if (startPage === 1 ) {
        setSection({
          docs: res.results,
          hasMore: res.hasMore,
          page: startPage,
          hasError: false,
        });
      } else {
        setSection({
          docs: [...currentState.docs, ...res.results],
          hasMore: res.hasMore,
          page: startPage,
          hasError: false,
        });
      }

    } catch (e) {
      setSection({...currentState, hasError: true});
    }
  };

  const loadNextFactory = (
    currentSectionState: ISectionState,
    section: MeetingSection,
    setSectionState: (state: ISectionState) => void,
  ) => {
    if (currentSectionState.hasMore && currentFileId) {
      return () =>
        loadMeetingSection(currentFileId, currentSectionState, section, setSectionState, currentSectionState.page + 1);
    }
  };

  useEffect(() => {
    if (!assetsLoaded || !meetingSections || !currentFileId) {
      return;
    }

    if (meetingSections.agenda) {
      loadMeetingSection(currentFileId, agenda, meetingSections.agenda, setAgenda, 1);
    }

    if (meetingSections.noticeOfMeeting) {
      loadMeetingSection(currentFileId, noticeOfMeeting, meetingSections.noticeOfMeeting, setNoticeOfMeeting, 1);
    }

    if (meetingSections.minutesOfTheMeeting) {
      loadMeetingSection(
        currentFileId,
        minutesOfTheMeeting,
        meetingSections.minutesOfTheMeeting,
        setMinutesOfTheMeeting,
        1,
      );
    }
  }, [assetsLoaded, currentFileId, meetingSections]);

  const hasErrorForAllSections = agenda.hasError && minutesOfTheMeeting.hasError && noticeOfMeeting.hasError;
  const hasData = agenda.docs.length > 0 || minutesOfTheMeeting.docs.length > 0 || noticeOfMeeting.docs.length > 0;

  //If no documents for any of the sections show empty sidebar
  if (!hasErrorForAllSections && !hasData) {
    return null;
  }

  return (
    <aside className="right-sidebar">
      <h2>{intl.get('plp.meetings.sections.details').d('Details')}</h2>
      <section>
        <h3 className="sub-section-heading">{intl.get('plp.meetings.sections.attachments').d('Attachments')}</h3>
        {hasErrorForAllSections && !hasData && (
          <div className="meetings-sections-container">
            <p className="error-loading-section">
              {intl.get('plp.meetings.sections.error.loading').d('Error loading attachments. Please refresh the page.')}
            </p>
          </div>
        )}
        {hasData && (
          <div className="meetings-sections-container">
            {meetingSections?.agenda && (
              <MeetingsSectionContainer
                loadMore={loadNextFactory(agenda, meetingSections.agenda, setAgenda)}
                title={intl.get('plp.meetings.sections.agenda').d('Agenda')}
                hasError={agenda.hasError}
                documents={agenda.docs}
              />
            )}
            {meetingSections?.noticeOfMeeting && (
              <MeetingsSectionContainer
                loadMore={loadNextFactory(noticeOfMeeting, meetingSections.noticeOfMeeting, setNoticeOfMeeting)}
                title={intl.get('plp.meetings.sections.notice.of.meeting').d('Notice of meeting')}
                hasError={noticeOfMeeting.hasError}
                documents={noticeOfMeeting.docs}
              />
            )}
            {meetingSections?.minutesOfTheMeeting && (
              <MeetingsSectionContainer
                loadMore={loadNextFactory(
                  minutesOfTheMeeting,
                  meetingSections.minutesOfTheMeeting,
                  setMinutesOfTheMeeting,
                )}
                title={intl.get('plp.meetings.sections.minutes.of.the.meeting').d('Minutes of the meeting')}
                hasError={minutesOfTheMeeting.hasError}
                documents={minutesOfTheMeeting.docs}
              />
            )}
          </div>
        )}
      </section>
    </aside>
  );
});
