import React, {useEffect} from 'react';
import intl from 'react-intl-universal';
import {useObserver} from 'mobx-react-lite';
import SelectFilter from './BaseFilterComponents/SelectFilter';
import {autorun} from 'mobx';
import {useRootStore} from '../../stores';
import {SearchableType} from '../../stores/SearchStore';
import {FileTypes} from '../../types/EntityTypes';
import {BaseFilterProps} from './types';
import {FileSubsetFilterCriteria, MeetingFolderFilterCriteria} from '../../stores/FileStore';

const SeriesTypeFilter: React.FC<BaseFilterProps> = (props: BaseFilterProps) => {
  const rootStore = useRootStore();
  const filesStore = rootStore.fileStore;
  const searchStore = rootStore.searchStore;
  const seriesTypesStore = rootStore.seriesTypesStore;
  const uiStore = rootStore.uiStore;

  useEffect(
    () =>
      autorun(() => {
        const selectedSearchType: SearchableType = searchStore.selectedSearchType;
        if (selectedSearchType === FileTypes.FileSubset || selectedSearchType === FileTypes.MeetingFolder) {
          seriesTypesStore.fetchEntities(1);
        }
      }),
    [],
  );

  return useObserver(() => {
    const type: SearchableType = searchStore.selectedSearchType;

    const filter=
        type === FileTypes.MeetingFolder ? MeetingFolderFilterCriteria.seriesType : FileSubsetFilterCriteria.seriesType;

    const currentSeriesTypeFilter = searchStore.selectedFilters.get(filter) || '';

    const selectSeriesType = (seriesType: string) => {
      if (seriesType) {
        searchStore.setFilter(filter, seriesType);
      } else {
        if (searchStore.selectedFilters.has(filter)) {
          searchStore.clearFilter(filter);
        }
      }

      if (type === FileTypes.FileSubset || type === FileTypes.MeetingFolder) {
        filesStore.changePage(1);
      }
    };

    return (
      <SelectFilter
        buttonLabel={intl.get('plp.filter.series.type').d('Series type')}
        options={seriesTypesStore.entitiesList.map(codeValue => {
          return {
            value: codeValue.name,
            label: codeValue.name,
          };
        })}
        selected={currentSeriesTypeFilter}
        onChange={selectSeriesType}
        closeOnTab={true}
        isMobileWidth={uiStore.isMobile}
        dropDownBtnCls="btn-secondary"
        filterViewMode={props.filterViewMode}
      />
    );
  });
};

export default SeriesTypeFilter;
