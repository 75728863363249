import React from 'react';
import {useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import {SupportedLocales} from './useTranslations';

const defaultOrganizationName: string = 'Documaster';
const defaultOrganizationLogoFilePath: string = process.env.PUBLIC_URL + '/images/documaster-logo.svg';

const defaultLogo = {
  src: defaultOrganizationLogoFilePath,
};

const defaultAssets: IOrganizationAssets = {
  name: defaultOrganizationName,
  logo: defaultLogo,
  details: null,
  links: {},
};

const client = axios.create({
  baseURL: '/',
  responseType: 'json',
  withCredentials: false,
});

interface LogoAssets {
  src: string;
  fallbackSrc?: string;
}

export interface MeetingSection {
  recordTypes: string[];
  documentRecordAssociations: string[];
  documentTypes?: string[];
}

export interface MeetingSections {
  agenda: MeetingSection;
  noticeOfMeeting: MeetingSection;
  minutesOfTheMeeting: MeetingSection;
}

export type CustomLink = {url: string; label: string};

export interface IOrganizationAssets {
  name: string;
  logo: LogoAssets;
  details: IOrganizationDetails | null;
  defaultLocale?: SupportedLocales;
  meetingSections?: MeetingSections;
  links: {[locale: string]: CustomLink[]};
}

export interface IOrganizationDetails {
  country: string;
  city: string;
  postalCode: string;
  address: string;
  email: string;
  phone: string;
}

export default function useOrganizationAssets() {
  const [organizationAssets, setOrganizationAssets] = useState<IOrganizationAssets>();
  const [organizationAssetsLoaded, setOrganizationAssetsLoaded] = useState<boolean>(false);

  useEffect(() => {
    async function getAssets() {
      // prod env
      if (process.env.REACT_APP_ORGANIZATION_ASSETS_INFO && process.env.REACT_APP_ORGANIZATION_ASSETS_LOGO) {
        const resp: AxiosResponse = await client.get(process.env.REACT_APP_ORGANIZATION_ASSETS_INFO);
        const assets: IOrganizationAssets = defaultAssets;

        if (resp.data && validAssets(resp.data)) {
          assets.name = resp.data['name'];
          assets.logo = {
            src: process.env.REACT_APP_ORGANIZATION_ASSETS_LOGO,
            fallbackSrc: defaultOrganizationLogoFilePath,
          };

          if (resp.data.hasOwnProperty('defaultLocale')) {
            assets.defaultLocale = resp.data['defaultLocale'];
          }

          assets.details = {
            country: resp.data['country'] || '',
            city: resp.data['city'] || '',
            postalCode: resp.data['postal-code'] || '',
            address: resp.data['address'] || '',
            email: resp.data['email'] || '',
            phone: resp.data['phone'] || '',
          };

          if (resp.data['meetingSections']) {
            assets.meetingSections = resp.data['meetingSections'];
          }

          if (resp.data['links']) {
            assets.links = resp.data['links'];
          }
        } else {
          console.log('Misconfigured organization info JSON file.');
        }
        setOrganizationAssets(assets);
        // dev env
      } else {
        setOrganizationAssets(defaultAssets);
      }

      setOrganizationAssetsLoaded(true);
    }

    getAssets();
  }, []);

  const getAllMeetingTypesForExclusion = () => {
    if (!organizationAssetsLoaded && organizationAssets?.meetingSections) {
      return [];
    }

    let types: string[] = [];

    if (organizationAssets?.meetingSections?.agenda?.recordTypes) {
      types = [...types, ...organizationAssets?.meetingSections?.agenda.recordTypes];
    }

    if (organizationAssets?.meetingSections?.minutesOfTheMeeting?.recordTypes) {
      types = [...types, ...organizationAssets?.meetingSections?.minutesOfTheMeeting.recordTypes];
    }

    if (organizationAssets?.meetingSections?.noticeOfMeeting?.recordTypes) {
      types = [...types, ...organizationAssets?.meetingSections?.noticeOfMeeting.recordTypes];
    }

    return types.filter((n, i) => types.indexOf(n) === i);
  };

  return {
    organizationAssets,
    organizationAssetsLoaded,
    getAllMeetingTypesForExclusion,
  };
}

const validAssets = (assets: {[key: string]: any}): boolean => {
  const keys = ['name', 'country', 'city', 'postal-code', 'address', 'email', 'phone'];

  return !!assets['name'] && !keys.find(key => !assets.hasOwnProperty(key));
};
