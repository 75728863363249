import {useEffect} from 'react';
import {useRootStore} from '../stores';

const max768MediaQuery = '(max-width: 767.98px)';
const max992MediaQuery = '(max-width: 991.98px)';
const max1200MediaQuery = '(max-width: 1199.98px)';

export default function useCalculateWindowWidthEffect() {
  const rootStore = useRootStore();
  const uiStore = rootStore.uiStore;

  const getMediaQueryListBelow768: () => MediaQueryList = () => {
    return window.matchMedia(max768MediaQuery);
  };

  const getMediaQueryListBelow992: () => MediaQueryList = () => {
    return window.matchMedia(max992MediaQuery);
  };

  const getMediaQueryListBelow1200: () => MediaQueryList = () => {
    return window.matchMedia(max1200MediaQuery);
  };

  const onMobileWidthChange = (event: MediaQueryListEvent): any => {
    if (event.matches) {
      uiStore.setIsMobile(true);
    } else {
      uiStore.setIsMobile(false);
    }
  };

  const onMobileOrTableWidthChange = (event: MediaQueryListEvent): any => {
    if (event.matches) {
      uiStore.setIsMobileOrTablet(true);
    } else {
      uiStore.setIsMobileOrTablet(false);
    }
  };

  const onSmallScreenWidthChange = (event: MediaQueryListEvent): any => {
    if (event.matches) {
      uiStore.setIsSmallScreen(true);
    } else {
      uiStore.setIsSmallScreen(false);
    }
  };

  /* It is preferable to use MediaQueryList.addEventListener instead of MediaQueryList.addListener.
     However in IE 11 the UI crashes with addEventListener.
     We have agreed not to support IE any more, especially not to add more styles because of it.
     However, having the whole UI crash is not desirable!
     TODO: start using addEventListener as soon as we drop support for IE as a whole.
  */

  useEffect(() => {
    const mediaQueryList = getMediaQueryListBelow768();
    uiStore.setIsMobile(mediaQueryList.matches);
    mediaQueryList.addListener(onMobileWidthChange);

    return () => {
      mediaQueryList.removeListener(onMobileWidthChange);
    };
  }, []);

  useEffect(() => {
    const mediaQueryList = getMediaQueryListBelow992();
    uiStore.setIsMobileOrTablet(mediaQueryList.matches);
    mediaQueryList.addListener(onMobileOrTableWidthChange);

    return () => {
      mediaQueryList.removeListener(onMobileOrTableWidthChange);
    };
  }, []);

  useEffect(() => {
    const mediaQueryList = getMediaQueryListBelow1200();
    uiStore.setIsSmallScreen(mediaQueryList.matches);
    mediaQueryList.addListener(onSmallScreenWidthChange);

    return () => {
      mediaQueryList.removeListener(onSmallScreenWidthChange);
    };
  }, []);
}
