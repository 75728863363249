import React, {useEffect} from 'react';

const dateRangePickerSelector = '.DateRangePicker';
const infoPanelCloseBtnSelector = '.DayPickerKeyboardShortcuts_close';

export default function useCloseDatePickerEffect(
  menu: React.RefObject<HTMLElement | undefined>,
  showMenu: boolean,
  setShowMenu: (showMenu: boolean) => void,
  disableEffect: boolean = false,
) {
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (!showMenu) {
        return;
      }

      //The user has clicked somewhere in the calendar while the info panel is open
      //We will close the info panel
      const datePicker = document.querySelectorAll<HTMLButtonElement>(dateRangePickerSelector);
      const infoPanelCloseBtn = document.querySelectorAll<HTMLButtonElement>(infoPanelCloseBtnSelector);
      if (
        datePicker &&
        datePicker.length &&
        infoPanelCloseBtn &&
        infoPanelCloseBtn.length &&
        datePicker[0].contains(e.target)
      ) {
        infoPanelCloseBtn[0].click();
        return;
      }

      if (!menu || !menu.current) {
        return;
      }

      //The user has clicked outside the calendar
      //We should close the calendar, but before that we should check if the info panel is open and close it too
      if (!menu.current.contains(e.target)) {
        //The user has clicked outside the calendar while the info panel is open
        if (infoPanelCloseBtn && infoPanelCloseBtn.length) {
          infoPanelCloseBtn[0].click();
        }

        setShowMenu(false);
      }
    };

    const handleEscape = (e: any) => {
      if (!showMenu) {
        return;
      }
      if (e.keyCode === 27) {
        //The info panel close button is focused - close the info panel
        if (e.target.className.split(' ').indexOf(infoPanelCloseBtnSelector.substring(1)) > -1) {
          return;
        } else {
          e.preventDefault();
          e.stopPropagation();
          setShowMenu(false);
        }
      }
    };

    if (!disableEffect) {
      document.addEventListener('mousedown', handleOutsideClick, false);
      document.addEventListener('keydown', handleEscape, false);

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick, false);
        document.removeEventListener('keydown', handleEscape, false);
      };
    }
  }, [showMenu, menu]);
}
