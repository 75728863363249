import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '12',
  height: '12',
  fill: 'none',
  viewBox: '0 0 12 12',
  focusable: false,
};

const Calendar: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1V2H8V1C8 0.447715 8.44771 0 9 0C9.55229 0 10 0.447715 10 1V2H11C11.5523 2 12 2.44772 12 3V11C12 11.5523 11.5523 12 11 12H1C0.447715 12 0 11.5523 0 11V3C0 2.44772 0.447715 2 1 2H2V1C2 0.447715 2.44772 0 3 0C3.55228 0 4 0.447715 4 1ZM2 6V10H10V6H2Z"
        fill="#758F9A"
      />
    </svg>
  );
};

export default Calendar;
