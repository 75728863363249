import React from 'react';
import Tooltip from '../../Tooltip/Tooltip';
import {
  getCardFieldIcon,
  joinCardFields,
  getUniqueSortedCardFieldCategories,
  groupCardFieldsByCategory,
} from '../../../utils/tooltips';

export enum CardFieldCategory {
  Date,
  TypeAndStatus,
  Location,
  Document,
  Screening,
}

export interface CardField {
  name: string;
  value: any;
  className?: string;
  category: CardFieldCategory;
}

interface Props {
  fields: CardField[];
}

const CardTooltips: React.FC<Props> = (props: Props) => {
  const categories = getUniqueSortedCardFieldCategories(props.fields);
  const fieldsByCategory = groupCardFieldsByCategory(props.fields);
  const joinedFields: CardField[] = joinCardFields(categories, fieldsByCategory, false);

  return (
    <>
      {joinedFields.map(field => (
        <Tooltip key={field.name} tooltip={field.name}>
          <span className="sr-only">{field.name}</span>
          <span className={`footer-field ${field.className}`}>
            {getCardFieldIcon(field.category)}
            {field.value}
          </span>
        </Tooltip>
      ))}
    </>
  );
};

export default CardTooltips;
