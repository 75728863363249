import React from 'react';
import CardContent from './CardContent';
import {useRootStore} from 'stores/index';
import {IMeetingRecord} from 'stores/RecordStore';
import useDefaultProps from 'hooks/useDefaultProps';
import {DocumentTypes, RecordTypes} from 'types/EntityTypes';
import {BaseCardProps} from '../Card/Card';
import {canOpen} from 'utils/recordUtils';

interface Props extends BaseCardProps {
  meetingRecord: IMeetingRecord;
  showFile?: boolean;
  headLineButton?: JSX.Element;
}

const propDefaults: Partial<Props> = {
  showFile: false,
};

const MeetingRecord: React.FC<Props> = (props: Props) => {
  props = useDefaultProps(props, propDefaults);

  const rootStore = useRootStore();
  const documentStore = rootStore.documentStore;
  const navigationStore = rootStore.navigationStore;

  const itemLink = navigationStore.getLeafDocumentsUrl(
    RecordTypes.MeetingRecord,
    props.meetingRecord.id,
    DocumentTypes.Document,
    1,
    documentStore.pageSize,
  );

  return (
    <CardContent
      meetingRecord={props.meetingRecord}
      showFile={!!props.showFile}
      itemLink={(!props.isOpen && canOpen(props.meetingRecord)) ? itemLink : undefined}
      cardType={props.cardType}
      headLineButton={props.headLineButton}
    />
  );
};

export default MeetingRecord;
