import React, {useState} from 'react';
import {v4 as uuidv4} from 'uuid';

interface Props {
  id?: string;
  open: boolean;
  toggleOpen: () => void;
  label: string;
  labelEl?: JSX.Element;
  className?: string;
  icon?: JSX.Element;
  accessibleLabel?: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

const DropDownButton: React.FC<Props> = (props: Props) => {
  const [htmlId] = useState(uuidv4());

  const accessibleLabelId = `dropdown-btn-${htmlId}`;
  return (
    <>
      {props.accessibleLabel && (
        <span className="sr-only" id={accessibleLabelId}>
          {props.accessibleLabel}
        </span>
      )}
      <button
        id={props.id ? props.id : `btn-${htmlId}`}
        type="button"
        className={`btn switch dropdown-switch ${props.className ? props.className : ''}`}
        aria-haspopup="true"
        aria-expanded={props.open ? 'true' : 'false'}
        onClick={props.toggleOpen}
        onKeyDown={props.onKeyDown}
        aria-labelledby={props.accessibleLabel ? accessibleLabelId : ''}
      >
        {props.icon}
        {props.labelEl ? props.labelEl : props.label}
      </button>
    </>
  );
};

export default DropDownButton;
