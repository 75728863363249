import React, {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import FocusTrap from 'focus-trap-react';
import intl from 'react-intl-universal';
import Cross from '../Icons/Cross';
import './css/modal.scss';

interface Props {
  title: string;
  titleEl?: JSX.Element;
  show: boolean;
  close: () => void;
  hideHeader?: boolean;
}

const ModalDialog: React.FC<Props> = ({children, title, titleEl, show, close, hideHeader}) => {
  const modalDialogRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [htmlId] = useState(uuidv4());

  useEffect(() => {
    if (show) {
      document.querySelector('html')?.classList.add('scroll-lock');
    } else {
      document.querySelector('html')?.classList.remove('scroll-lock');
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const onModalBackdropClick = (e: any) => {
    if (modalDialogRef && modalDialogRef.current && !modalDialogRef.current.contains(e.target)) {
      closeDialog();
    }
  };

  const closeOnEsc = (e: React.KeyboardEvent) => {
    //Esc
    if (e.keyCode === 27) {
      closeDialog();
    }
  };

  const closeDialog = () => {
    //prevent message???
    close();
  };

  return (
    <FocusTrap>
      <div className="modal-backdrop" tabIndex={-1} onClick={onModalBackdropClick} onKeyDown={closeOnEsc}>
        <div
          ref={modalDialogRef}
          role="dialog"
          aria-modal={true}
          aria-labelledby={`title-${htmlId}`}
          className="modal-dialog"
        >
          <span id={`title-${htmlId}`} className="sr-only">
            {title}
          </span>
          {!hideHeader && (
            <header>
              <h2>{titleEl || title}</h2>
              <button type="button" className="btn btn-icon close" onClick={closeDialog}>
                <span className="sr-only">{intl.get('plp.parent.item.header.button.close').d('Close')}</span>
                <Cross />
              </button>
            </header>
          )}
          {children}
        </div>
      </div>
    </FocusTrap>
  );
};

export default ModalDialog;
