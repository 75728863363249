import React, {useRef} from 'react';
import intl from 'react-intl-universal';
import {isInternetExplorer} from '../../utils/browserVersion';
import {isArray} from '../../utils/isArray';
import {CardType} from './Card';
import CardTooltips, {CardField} from './Footer/CardTooltips';
import CollapsibleCardFields from './Footer/CollapsibleCardFields';
import {useRootStore} from '../../stores';
import {useObserver} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import './css/card.scss';
import './css/card-footer.scss';
import './css/request-documents-button.scss';

export interface NameValue {
  name: string;
  value: string;
}

interface NameValues {
  name: string;
  value: string | string[];
}

interface Props {
  entityTypeCls: string,
  entityTitle: string;
  entityIdFieldTitle: string;
  title: string | null;
  abbreviation?: string;
  itemNumber: string;
  additionalHeadLineFields?: NameValue[];
  fieldDefinitions?: NameValues[];
  footerFields?: CardField[];
  parentLink?: JSX.Element;
  itemLink?: string;
  cardType: CardType;
  screened: boolean;
  headLineButton?: JSX.Element;
}

const MIN_SELECTION_TIME = 200;
const MAX_FIELD_VALUE_COUNT = 10;

const CardTemplate = (props: Props) => {
  const linkRef: React.RefObject<HTMLAnchorElement> = useRef<HTMLAnchorElement>(null);

  const rootStore = useRootStore();
  const uiStore = rootStore.uiStore;

  const {entityTypeCls, entityTitle, entityIdFieldTitle} = props;

  return useObserver(() => {
    let downTime = 0,
      upTime = 0;

    const onMouseDown = (e: React.MouseEvent) => {
      downTime = Date.now();
    };

    const onMouseUp = (e: React.MouseEvent) => {
      if (props.itemLink) {
        upTime = Date.now();
        if (upTime - downTime < MIN_SELECTION_TIME && linkRef && linkRef.current) {
          linkRef.current.click();
        } else {
          //the user might be taking longer to select a text in the card or holding the mouse down without knowing what to do
        }
      }
    };

    let footerContent;
    if (props.footerFields && props.footerFields.length > 0) {
      if (uiStore.isMobileOrTablet) {
        footerContent = <CollapsibleCardFields fields={props.footerFields} />;
      } else {
        footerContent = <CardTooltips fields={props.footerFields} />;
      }
    }

    const title =
      props.title !== null ? props.title : intl.get('plp.grid.item.no.public.title.placeholder').d('(no public title)');

    const isIE: boolean = isInternetExplorer();
    const ieLayout = isIE ? 'ie-layout' : '';
    const headingContent = props.itemLink ? (
      <Link
        innerRef={linkRef}
        to={props.itemLink}
        onMouseUp={e => {
          e.stopPropagation();
        }}
      >
        {title}
      </Link>
    ) : (
      title
    );

    return (
      <section
        className={`grid-item card ${props.itemLink ? 'item-link' : ''}`}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <header>
          <p>
            {props.abbreviation && (
              <>
                <span className="sr-only">{entityTitle}</span>
                <span className={`entity-type ${entityTypeCls}`} title={entityTitle}>
                  {props.abbreviation}
                </span>
              </>
            )}
            <span className="head-line">
              <span className="head-line-block">
                <span className="sr-only">{entityIdFieldTitle}</span>
                <span className="entity-identifier" title={entityIdFieldTitle}>
                  {props.itemNumber}
                </span>
              </span>
              {props.additionalHeadLineFields &&
                props.additionalHeadLineFields.map(d => {
                  return (
                    <span className="head-line-block" key={`${d.name}-${d.value}`}>
                      <span className="prop-label">{d.name}:</span>
                      <span className="prop-value">{d.value}</span>
                    </span>
                  );
                })}
            </span>
          </p>
          <div className={props.screened ? 'screened' : ''}>
            {props.screened && !props.itemLink && props.cardType !== CardType.MainItem && (
              <span className="screened">🛇 {intl.get('plp.grid.item.field.common.screened').d('Screened')}</span>
            )}
            {props.cardType === CardType.MainItem ? (
              <h1>{headingContent}</h1>
            ) : props.cardType === CardType.ListItem ? (
              <h2>{headingContent}</h2>
            ) : (
              <h3>{headingContent}</h3>
            )}
          </div>
          {props.headLineButton && props.headLineButton}
        </header>

        {props.fieldDefinitions && props.fieldDefinitions.length > 0 && (
          <dl className={ieLayout}>
            {props.fieldDefinitions
              .filter(d => d.name && d.value)
              .map(d => {
                let fieldDefValue: JSX.Element | JSX.Element[];
                if (isArray(d.value)) {
                  const values = d.value as string[];
                  fieldDefValue = (d.value as string[]).map((val, index) => {
                    const hideValue = index >= MAX_FIELD_VALUE_COUNT && props.cardType !== CardType.MainItem;
                    const separator: string = index < values.length - 1 ? ', ' : '';
                    return (
                      <React.Fragment key={`${d.name}-${index}`}>
                        <dd className={hideValue ? 'sr-only' : ''}>
                          {val}
                          {separator}
                        </dd>
                        {props.cardType !== CardType.MainItem &&
                          values.length > MAX_FIELD_VALUE_COUNT &&
                          index === values.length - 1 && (
                            <span className="more-field-values">
                              <span>...</span>
                            </span>
                          )}
                      </React.Fragment>
                    );
                  });
                } else {
                  fieldDefValue = <dd className="subtitle-value">{d.value}</dd>;
                }

                return (
                  <React.Fragment key={d.name}>
                    <div>
                      <dt className="subtitle-label">{d.name}:</dt>
                      {fieldDefValue}
                    </div>
                  </React.Fragment>
                );
              })}
          </dl>
        )}

        {props.parentLink}

        {footerContent !== undefined && <footer>{footerContent}</footer>}
      </section>
    );
  });
};

export default CardTemplate;
