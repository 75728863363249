import React from 'react';
import {Link} from 'react-router-dom';
import {IOrganizationAssets} from 'hooks/useOrganizationAssets';
import Image from '../Image/Image';
import intl from 'react-intl-universal';
import './css/logo.scss';

interface Props {
  organizationAssets: IOrganizationAssets;
}

const Home: React.FC<Props> = (props: Props) => {
  const baseUrl: string = `${process.env.PUBLIC_URL}/`;
  return (
    <Link to={baseUrl}>
      <div className="logo-wrapper">
        <Image
          src={props.organizationAssets.logo.src}
          fallBackSrc={props.organizationAssets.logo.fallbackSrc}
          alt={`${intl.get('plp.logo.of').d('Logo of')} ${props.organizationAssets.name}`}
          isLogo={true}
        />
      </div>
      {props.organizationAssets.name}
    </Link>
  );
};

export default Home;
