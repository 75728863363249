import {useEffect, useState} from 'react';
import moment from 'moment';
import intl from 'react-intl-universal';
import {FocusedInputShape} from 'react-dates';
import {v4 as uuidv4} from 'uuid';

const INVALID_INPUT_CLS = 'invalid';

export default function useDateInputValidationHook(
  inputId: FocusedInputShape,
  dateFormat: string,
  hasBeenExpanded: boolean,
  updateLiveAria: (text: string) => void,
) {
  const [htmlId] = useState(uuidv4());
  const validationContainerId = `validation-${htmlId}`;

  //Validate on blur
  useEffect(() => {
    if (hasBeenExpanded) {
      const onBlur = (e: any) => {
        validate();
      };

      const dateInput = document.getElementById(inputId);
      if (dateInput) {
        let validationContainer = document.createElement('div');
        validationContainer.id = validationContainerId;
        validationContainer.classList.add('validation-container');
        dateInput.insertAdjacentElement('afterend', validationContainer);

        dateInput.addEventListener('blur', onBlur);

        return () => dateInput.removeEventListener('blur', onBlur);
      }
    }
  }, [hasBeenExpanded]);

  //Remove validation on focus or on close
  useEffect(() => {
    if (hasBeenExpanded) {
      const onFocus = (e: any) => {
        setClass(true);
      };

      const dateInput = document.getElementById(inputId);
      if (dateInput) {
        dateInput.addEventListener('focus', onFocus);

        return () => dateInput.removeEventListener('focus', onFocus);
      }
    }
  }, [hasBeenExpanded]);

  const validate = () => {
    const dateInput = document.getElementById(inputId) as HTMLInputElement | null;
    if (dateInput && dateInput.value) {
      const enteredDate = moment(dateInput.value, dateFormat, true);
      const inputLabel = dateInput.getAttribute('aria-label');
      if (!enteredDate || !enteredDate.isValid()) {
        setClass(false);
        updateLiveAria(`${getInvalidText()}: ${inputLabel}`);
      } else {
        setClass(true);
        updateLiveAria('');
      }
    }
  };

  const removeInvalidClass = () => {
    setClass(true);
  };

  const setClass = (isValid: boolean) => {
    const dateInput = document.getElementById(inputId) as HTMLInputElement | null;
    if (dateInput) {
      if (!isValid) {
        dateInput.classList.add(INVALID_INPUT_CLS);
      } else {
        dateInput.classList.remove(INVALID_INPUT_CLS);
      }
    }

    const validationContainer = document.getElementById(validationContainerId) as HTMLDivElement | null;
    if (validationContainer) {
      if (!isValid) {
        validationContainer.classList.add(INVALID_INPUT_CLS);
        validationContainer.innerText = getInvalidText();
      } else {
        validationContainer.classList.remove(INVALID_INPUT_CLS);
        validationContainer.innerText = '';
      }
    }
  };

  const getInvalidText = () => {
    return intl.get('plp.filter.calendar.invalid').d('Invalid');
  };

  return [validate, removeInvalidClass];
}
