import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '17',
  height: '10',
  fill: 'none',
  viewBox: '0 0 17 10',
  focusable: true,
};

const ArrowDown: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      xmlns="http://www.w3.org/2000/svg"
      focusable={props.focusable}
    >
      <path d="M16.5606 2.56051C17.1465 1.97476 17.1465 1.02505 16.5606 0.439297C15.9749 -0.146433 15.0251 -0.146432 14.4394 0.439297L8.49957 6.37857L2.56063 0.440152C1.97485 -0.145578 1.02513 -0.145576 0.439354 0.44015C-0.146449 1.0259 -0.146452 1.97562 0.439352 2.56137L7.4393 9.5607C8.02508 10.1464 8.97479 10.1464 9.56057 9.56071L16.5606 2.56051Z" />
    </svg>
  );
};

export default ArrowDown;
