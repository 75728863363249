import React from 'react';
import CardContent from './CardContent';
import {IDocument} from 'stores/DocumentStore';

interface Props {
  document: IDocument;
}

const Document: React.FC<Props> = (props: Props) => {
  const {document} = props;

  return <CardContent document={document} />;
};

export default Document;
