import React, {useEffect, useRef, useState} from 'react';
import {useObserver} from 'mobx-react-lite';
import SearchField, {SearchResult} from '../../SearchField/SearchField';
import useCloseMenuEffect from '../../../hooks/useCloseMenuEffect';
import FilterFooter from './FilterFooter';
import FilterButton from './FilterButton';
import {v4 as uuidv4} from 'uuid';
import {BaseFilterProps, FilterViewMode} from '../types';
import '../css/search-field.scss';

interface Props extends BaseFilterProps {
  filterButtonLabel: string;
  searchFieldPlaceholder: string;
  search: (searchTerm: string) => void;
  resetSearch: () => void;
  loadMoreResults?: () => void;
  currentFilterValue: string;
  applyFilterValue: (value: string) => void;
  searchResults: Array<string>;
  hasMoreResults: boolean;
  className: string;
}

const SearchFilter: React.FC<Props> = (props: Props) => {
  const containerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<SearchResult>();
  const [searchFieldId] = useState<string>(uuidv4());

  //Sync selected search value with one saved in the app state. This is needed when:
  //1) the user has closed the filter without clicking on the Apply button
  //2) the filter value in the app state has been changed from another place
  useEffect(() => {
    if (props.currentFilterValue) {
      setSelectedValue({value: props.currentFilterValue, label: props.currentFilterValue});
    } else {
      setSelectedValue(undefined);
    }
  }, [props.currentFilterValue]);

  const clearOnClose = () => {
    setSelectedValue(undefined);
    props.resetSearch();
  };

  const setShowFilterMenu = (show: boolean) => {
    if (!show) {
      clearOnClose();
    }

    setOpen(show);
  };

  useCloseMenuEffect(containerRef, open, setShowFilterMenu, props.filterViewMode === FilterViewMode.Stacked);

  return useObserver(() => {
    const toggleOpen = () => {
      setShowFilterMenu(!open);
    };

    const clearValue = () => {
      props.applyFilterValue('');
    };

    const onCloseClick = () => {
      setShowFilterMenu(false);
    };

    const applyFilter = () => {
      props.applyFilterValue(selectedValue ? selectedValue.value : '');
      props.resetSearch();
      if (props.filterViewMode === FilterViewMode.Default) {
        setOpen(false);
      }
    };

    //Expand the filter on arrow-down
    const onDropDownButtonKeyDown = (e: React.KeyboardEvent) => {
      switch (e.keyCode) {
        //key down
        case 40:
          if (!open) {
            e.preventDefault();
            setShowFilterMenu(true);
            break;
          }
      }
    };

    const shouldDisableApplyButton = () => {
      return selectedValue?.value === props.currentFilterValue || (!selectedValue?.value && !props.currentFilterValue);
    };

    //Collapse the filter on Shift+Tab on the first focus-able element
    const onKeyDownOfFirstElement = (e: React.KeyboardEvent) => {
      switch (e.keyCode) {
        //Tab
        case 9:
          if (e.shiftKey) {
            setShowFilterMenu(false);
            break;
          }
      }
    };

    return (
      <div className={`${props.className} dropdown ${open ? 'is-shown' : ''}`} ref={containerRef}>
        <FilterButton
          open={open}
          toggleOpen={toggleOpen}
          clearValue={clearValue}
          label={props.filterButtonLabel}
          value={props.currentFilterValue}
          onKeyDown={onDropDownButtonKeyDown}
        />
        <div className={`dropdown-pane ${open ? 'is-shown' : ''}`} aria-labelledby="dropdownPaneBtn" tabIndex={-1}>
          <SearchField
            id={searchFieldId}
            onSearch={props.search}
            onLoadMoreResults={props.loadMoreResults}
            results={props.searchResults.map(result => {
              return {value: result, label: result};
            })}
            hasMoreResults={props.hasMoreResults}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            placeholder={props.searchFieldPlaceholder}
            onKeyDownOfFirstElement={onKeyDownOfFirstElement}
            visible={open}
          />
          <FilterFooter
            applyFilter={applyFilter}
            cancelAndClose={onCloseClick}
            applyButtonDisabled={shouldDisableApplyButton()}
          />
        </div>
      </div>
    );
  });
};

export default SearchFilter;
