import {action, computed, observable} from 'mobx';
import {TreeStore} from './TreeStore';
import {
  RecordSubsetFilterCriteria,
  RecordSubsetSearchCriteria,
} from './RecordStore';
import {
  FileSubsetFilterCriteria,
  FileSubsetSearchCriteria,
  MeetingFolderFilterCriteria,
  MeetingFolderSearchCriteria,
} from './FileStore';
import {FileTypes, RecordTypes} from '../types/EntityTypes';

export type SearchableType = FileTypes.FileSubset | FileTypes.MeetingFolder | RecordTypes.RecordSubset;
export type SearchableCriteria =
  | FileSubsetSearchCriteria
  | MeetingFolderSearchCriteria
  | RecordSubsetSearchCriteria;
export type FilterCriteria =
  | FileSubsetFilterCriteria
  | MeetingFolderFilterCriteria
  | RecordSubsetFilterCriteria;

export type SearchQuery = {
  criterion: SearchableCriteria;
  value: string;
};

export class SearchStore {
  private readonly treeStore: TreeStore;

  constructor(treeStore: TreeStore) {
    this.treeStore = treeStore;
  }

  @observable
  selectedSearchType: SearchableType = FileTypes.FileSubset;

  @action.bound
  public setSelectedSearchType(type: SearchableType): void {
    this.selectedSearchType = type;
  }

  @observable
  selectedSearchCriterion: SearchableCriteria = FileSubsetSearchCriteria.title;

  @action.bound
  public setSelectedSearchCriterion(criterion: SearchableCriteria): void {
    this.selectedSearchCriterion = criterion;
  }

  @observable
  timeOfLastSearchTermChange: number = 0;

  @observable
  searchCriterionValue: string = '';

  @action.bound
  public setTimeOfLastSearchTermChange(value: number) {
    this.timeOfLastSearchTermChange = value;
  }

  @action.bound
  public setSearchCriterionValue(value: string) {
    this.searchCriterionValue = value;
  }

  @observable
  valueLastSearched: string | undefined = undefined;

  @action.bound
  public setValueLastSearched(value: string | undefined) {
    this.valueLastSearched = value;
  }

  @action.bound
  public resetSelectedSearchCriterion(type: SearchableType) {
    if (this.selectedSearchType === type) return;
    this.setSelectedSearchType(type);
    switch (type) {
      case FileTypes.FileSubset:
        this.setSelectedSearchCriterion(FileSubsetSearchCriteria.title);
        break;
      case FileTypes.MeetingFolder:
        this.setSelectedSearchCriterion(MeetingFolderSearchCriteria.title);
        break;
      case RecordTypes.RecordSubset:
        this.setSelectedSearchCriterion(RecordSubsetSearchCriteria.title);
        break;
      default:
        break;
    }
  }

  @action.bound
  public clearSearchAndFilters() {
    this.valueLastSearched = undefined;
    this.searchCriterionValue = '';
    this.clearAllFilters();
  }

  @action.bound
  public getSearchQuery(): SearchQuery | undefined {
    let searchQuery: SearchQuery | undefined;
    if (this.searchCriterionValue) {
      searchQuery = {
        criterion: this.selectedSearchCriterion,
        value: this.searchCriterionValue,
      };
    }
    return searchQuery;
  }

  @observable
  selectedFilters: Map<FilterCriteria, string> = new Map();

  @action.bound
  public setFilter(filterCriteria: FilterCriteria, value: string) {
    this.selectedFilters.set(filterCriteria, value);
  }

  @action.bound
  public clearFilter(filterCriteria: FilterCriteria) {
    this.selectedFilters.delete(filterCriteria);
  }

  @action.bound
  public clearAllFilters() {
    this.selectedFilters.clear();
    this.meetingBoardTerm = '';
  }

  @observable
  meetingBoardTerm: string = '';

  @action.bound
  public setMeetingBoardTerm(term: string) {
    this.meetingBoardTerm = term;
  }

  @computed
  public get filtersWithSelectedValuesCount() {
    let filtersWithSelectedValuesCount = 0;
    switch (this.selectedSearchType) {
      case FileTypes.FileSubset: {
        if (
          this.selectedFilters.has(FileSubsetFilterCriteria.knr) ||
          this.selectedFilters.has(FileSubsetFilterCriteria.bnr) ||
          this.selectedFilters.has(FileSubsetFilterCriteria.gnr) ||
          this.selectedFilters.has(FileSubsetFilterCriteria.fnr) ||
          this.selectedFilters.has(FileSubsetFilterCriteria.snr)
        ) {
          filtersWithSelectedValuesCount++;
        }
        if (
          this.selectedFilters.has(FileSubsetFilterCriteria.dateFrom) ||
          this.selectedFilters.has(FileSubsetFilterCriteria.dateTo)
        ) {
          filtersWithSelectedValuesCount++;
        }

        if (this.selectedFilters.has(FileSubsetFilterCriteria.seriesType)) {
          filtersWithSelectedValuesCount++;
        }
        break;
      }
      case FileTypes.MeetingFolder:
        if (this.selectedFilters.has(MeetingFolderFilterCriteria.board)) {
          filtersWithSelectedValuesCount++;
        }
        if (this.selectedFilters.has(MeetingFolderFilterCriteria.seriesType)) {
          filtersWithSelectedValuesCount++;
        }
        if (
          this.selectedFilters.has(MeetingFolderFilterCriteria.meetingDateFrom) ||
          this.selectedFilters.has(MeetingFolderFilterCriteria.meetingDateTo)
        ) {
          filtersWithSelectedValuesCount++;
        }
        break;
      case RecordTypes.RecordSubset:
        if (this.selectedFilters.has(RecordSubsetFilterCriteria.recordType)) {
          filtersWithSelectedValuesCount++;
        }
        if (
          this.selectedFilters.has(RecordSubsetFilterCriteria.dateFrom) ||
          this.selectedFilters.has(RecordSubsetFilterCriteria.dateTo)
        ) {
          filtersWithSelectedValuesCount++;
        }
        break;
    }
    return filtersWithSelectedValuesCount;
  }
}
