import React from 'react';
import DropDownButtonWithValue from '../../DropDownButton/DropDownButtonWithValue';
import DropDownButton from '../../DropDownButton/DropDownButton';
import '../css/filter-dropdown-button.scss';
import '../css/filter-dropdown-button-with-value.scss';

interface Props {
  open: boolean;
  toggleOpen: () => void;
  clearValue: () => void;
  label: string;
  value: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

const FilterButton: React.FC<Props> = (props: Props) => {
  if (props.value) {
    return <DropDownButtonWithValue {...props} />;
  } else {
    return <DropDownButton {...props} className="btn-light" />;
  }
};

export default FilterButton;
