import React from 'react';

const ChevronRightParentLink: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" focusable={false} xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.35" d="M9 7L14 12L9 17" stroke="#344249" strokeLinecap="round" />
    </svg>
  );
};

export default ChevronRightParentLink;
