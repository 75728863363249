import React from 'react';
import LocaleSwitcher from './LocaleSwitcher';
import Home from '../NavigationBar/Home';
import Filters from '../Filters/Filters';
import AppProps from '../../types/AppProps';
import './css/head-side-panel.scss';
import SearchBar from '../SearchBar/SearchBar';

interface Props extends AppProps {
  isSmallScreen: boolean;
  isMobile: boolean;
}

const Header: React.FC<Props> = (props: Props) => {
  const headSidePanel = (
    <div className="head-side-panel">
      <LocaleSwitcher
        isMobile={props.isMobile}
        selectedLocale={props.selectedLocale}
        setSelectedLocale={props.setSelectedLocale}
        loadedLocale={props.loadedLocale}
      />
    </div>
  );

  const search = <SearchBar />;

  return (
    <header className="top-bar">
      <div className="logo-name">
        {props.organizationAssets && <Home organizationAssets={props.organizationAssets} />}
      </div>
      {!props.isSmallScreen ? (
        <>
          {search}
          {headSidePanel}
        </>
      ) : (
        <>
          {headSidePanel}
          {search}
        </>
      )}
      <Filters />
    </header>
  );
};

export default Header;
