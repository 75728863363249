import React, {useEffect, useRef, useState} from 'react';
import CaseFile from '../CaseFile/CaseFile';
import MeetingFolder from '../MeetingFolder/MeetingFolder';
import RegistryEntry from '../RegistryEntry/RegistryEntry';
import MeetingRecord from '../MeetingRecord/MeetingRecord';
import Document from '../Document/Document';
import {ICaseFile, IFolder, IMeetingFolder} from 'stores/FileStore';
import {IBasicRecord, IMeetingRecord, IRegistryEntry} from 'stores/RecordStore';
import {IDocument} from 'stores/DocumentStore';
import useDefaultProps from 'hooks/useDefaultProps';
import {DTODocumentTypes, DTOFileTypes, DTORecordTypes} from '../../types/DTOEntityTypes';
import Folder from '../Folder/Folder';
import BasicRecord from '../BasicRecord/BasicRecord';
import DialogButton from '../DialogButton/DialogButton';
import intl from 'react-intl-universal';
import RequestAccessForm from '../RequestAccessForm/RequestAccessForm';
import ModalDialog from '../ModalDialog/ModalDialog';
import {useRootStore} from '../../stores';

export enum CardType {
  'ListItem',
  'MainItem',
  'ChildListItem',
}

export interface BaseCardProps {
  isOpen?: boolean;
  cardType: CardType;
}

interface Props extends BaseCardProps {
  archiveEntity: ICaseFile | IFolder | IDocument | IRegistryEntry | IBasicRecord | IMeetingRecord | IMeetingFolder;
  showParentItem?: boolean;
}

const propDefaults: Partial<Props> = {
  showParentItem: true,
  isOpen: false,
};

const Card: React.FC<Props> = (props: Props) => {
  props = useDefaultProps(props, propDefaults);
  const [requestAccessDialogOpen, setRequestAccessDialogOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<boolean | undefined>(undefined);

  const rootStore = useRootStore();
  const prevStatus = useRef<boolean | undefined>(undefined);

  useEffect(() => {
    prevStatus.current = submitStatus;
  });

  useEffect(() => {
    let timer: number;

    if (submitStatus !== undefined) {
      prevStatus.current = submitStatus;

      timer = window.setTimeout(() => {
        setSubmitStatus(undefined);
      }, 6000);
    }

    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [submitStatus]);

  const {archiveEntity, showParentItem, isOpen} = props;

  let headLineButton: JSX.Element | undefined;

  if (
    'hasPrivateDocuments' in archiveEntity &&
    archiveEntity.hasPrivateDocuments &&
    props.cardType !== CardType.MainItem
  ) {
    headLineButton = (
      <DialogButton
        label={intl.get('plp.request.access.button.label').d('Request access')}
        onMouseUp={e => {
          e.stopPropagation();
        }}
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
          e.preventDefault();
          setRequestAccessDialogOpen(true);
        }}
        isOpen={requestAccessDialogOpen}
      />
    );
  }

  const afterFormSuccessSubmit = () => {
    setRequestAccessDialogOpen(false);
    rootStore.requestAccessFormStore.clearFields();
  };

  const closeDialog = () => {
    rootStore.requestAccessFormStore.clearFields();
    setRequestAccessDialogOpen(false);
  };

  let className = '';

  if (submitStatus === false) {
    className = 'error';
  } else if (submitStatus === true) {
    className = 'success';
  }

  return (
    <>
      <p className={`request-documents-status ${className}`} aria-live="assertive">
        {(prevStatus.current === false || submitStatus === false) &&
          intl
            .get('plp.request.access.form.submit.error')
            .d('There was error during form submission. Please try again later.')}
        {(prevStatus.current === true || submitStatus === true) &&
          intl.get('plp.request.access.form.submit.ok').d('Your request was submitted.')}
      </p>
      {archiveEntity.type === DTOFileTypes.CaseFile && (
        <CaseFile isOpen={isOpen} caseFile={archiveEntity} cardType={props.cardType} />
      )}
      {archiveEntity.type === DTOFileTypes.Folder && (
        <Folder isOpen={isOpen} folder={archiveEntity} cardType={props.cardType} />
      )}
      {archiveEntity.type === DTOFileTypes.MeetingFolder && (
        <MeetingFolder isOpen={isOpen} meetingFolder={archiveEntity} cardType={props.cardType} />
      )}
      {archiveEntity.type === DTORecordTypes.RegistryEntry && (
        <RegistryEntry
          registryEntry={archiveEntity}
          showFile={showParentItem}
          isOpen={isOpen}
          cardType={props.cardType}
          headLineButton={headLineButton}
        />
      )}
      {archiveEntity.type === DTORecordTypes.BasicRecord && (
        <BasicRecord
          basicRecord={archiveEntity}
          showFile={showParentItem}
          isOpen={isOpen}
          cardType={props.cardType}
          headLineButton={headLineButton}
        />
      )}
      {archiveEntity.type === DTORecordTypes.MeetingRecord && (
        <MeetingRecord
          meetingRecord={archiveEntity}
          showFile={showParentItem}
          isOpen={isOpen}
          cardType={props.cardType}
          headLineButton={headLineButton}
        />
      )}
      {archiveEntity.type === DTODocumentTypes.Document && <Document document={archiveEntity} />}

      {'hasPrivateDocuments' in archiveEntity && archiveEntity.hasPrivateDocuments && (
        <ModalDialog
          show={requestAccessDialogOpen}
          close={closeDialog}
          title={intl.get('plp.request.access.form.title').d('Request access form')}
        >
          <RequestAccessForm
            record={archiveEntity}
            close={closeDialog}
            setSubmitStatus={setSubmitStatus}
            afterFormSuccessSubmit={afterFormSuccessSubmit}
          />
        </ModalDialog>
      )}
    </>
  );
};

export default Card;
