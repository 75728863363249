import {useEffect, useState} from 'react';
import {PortalRouteProps} from 'types/PortalRouteProps';
import {Location} from 'history';
import {useLocation, useParams} from 'react-router-dom';
import {IDynamicRouteSegments} from '../types/IDynamicRouteSegments';
import useQuery from './useQuery';
import {DocumentTypes, FileTypes, FileUnion, RecordTypes} from '../types/EntityTypes';
import {Nodes, RootNode} from '../stores/TreeStore';
import {DEFAULT_SEARCH_PAGE_SIZE} from '../stores/PaginatableStore';
import {FilterCriteria, SearchableCriteria} from '../stores/SearchStore';
import isEqual from 'lodash/isEqual';
import {
  queryFilters,
  queryPage,
  queryPageSize,
  querySearchCriterion,
  querySearchValue,
} from '../utils/validQueryParams';
import {FileSubsetSearchCriteria} from '../stores/FileStore';

export interface GridProps {
  initialized: boolean;
  portalRouteProps: PortalRouteProps;
  rootPortalRouteProps: PortalRouteProps;
  setRootPortalRouteProps: (rootPortalRouteProps: PortalRouteProps) => void;
}

const defaultPortalRouteProps: PortalRouteProps = {
  page: 1,
  pageSize: DEFAULT_SEARCH_PAGE_SIZE,
  rootNode: Nodes.File,
  type: FileTypes.FileSubset,
  searchCriterion: FileSubsetSearchCriteria.title,
  searchValue: '',
  filters: new Map<FilterCriteria, string>(),
};

export default function useGridProps(): GridProps {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [portalRouteProps, setPortalRouteProps] = useState<PortalRouteProps>(defaultPortalRouteProps);
  const [rootPortalRouteProps, setRootPortalRouteProps] = useState<PortalRouteProps>(defaultPortalRouteProps);

  const location: Location = useLocation();
  const urlParams = useParams<IDynamicRouteSegments>();
  const queryParams = useQuery();

  const nodes: string[] = location.pathname.split('/').filter((urlPathNamePart: string) => {
    switch (urlPathNamePart) {
      case FileTypes.CaseFile:
      case FileTypes.Folder:
      case FileTypes.MeetingFolder:
      case FileUnion:
      case RecordTypes.RegistryEntry:
      case RecordTypes.BasicRecord:
      case RecordTypes.MeetingRecord:
      case RecordTypes.RecordUnion:
      case RecordTypes.RecordSubset:
      case DocumentTypes.Document:
        return true;
      default:
        return false;
    }
  });

  let rootNode: RootNode = Nodes.File;
  let type: FileTypes | RecordTypes = FileTypes.FileSubset;

  const id: string | undefined = urlParams.id;
  let childrenType: RecordTypes | DocumentTypes | undefined;

  if (nodes[0]) {
    switch (nodes[0]) {
      case FileTypes.CaseFile:
      case FileTypes.Folder:
      case FileTypes.MeetingFolder:
        rootNode = Nodes.File;
        type = nodes[0] as FileTypes;
        if (nodes[1]) {
          childrenType = nodes[1] as RecordTypes;
        }
        break;
      case FileUnion:
        rootNode = Nodes.File;
        if (nodes[1]) {
          type = nodes[1] as RecordTypes;
          if (nodes[2]) {
            childrenType = nodes[2] as DocumentTypes;
          }
        }
        break;
      case RecordTypes.RegistryEntry:
      case RecordTypes.BasicRecord:
        rootNode = Nodes.Record;
        type = nodes[0] as RecordTypes;
        if (nodes[1]) {
          childrenType = nodes[1] as DocumentTypes;
        }
        break;
      case RecordTypes.RecordSubset:
        rootNode = Nodes.Record;
        type = nodes[0] as RecordTypes.RecordSubset;
        break;
      default:
        break;
    }
  }

  const page: number = queryPage(queryParams);
  const pageSize: number = queryPageSize(queryParams);
  const searchCriterion: SearchableCriteria = querySearchCriterion(queryParams);
  const searchValue: string = querySearchValue(queryParams);
  const filters: Map<FilterCriteria, string> = queryFilters(queryParams);

  const newPortalRouteProps: PortalRouteProps = {
    page,
    pageSize,
    rootNode,
    type,
    searchCriterion,
    searchValue,
    filters,
  };

  if (id && childrenType) {
    newPortalRouteProps.id = id;
    newPortalRouteProps.childrenType = childrenType;
  }

  const touched: boolean = !isEqual(portalRouteProps, newPortalRouteProps);

  useEffect(() => {
    if (!initialized || touched) {
      setInitialized(true);
      setPortalRouteProps(newPortalRouteProps);
    }
  }, [initialized, touched, newPortalRouteProps]);

  return {initialized, portalRouteProps, rootPortalRouteProps, setRootPortalRouteProps};
}
