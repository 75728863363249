import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '12',
  height: '12',
  fill: '#758F9A',
  viewBox: '0 0 12 12',
  focusable: false,
};

const Flag: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.width}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1V11C1 11.5523 1.44771 12 2 12C2.55228 12 3 11.5523 3 11L3 6H11L8 3L11 0H2C1.44771 0 1 0.447715 1 1Z"
      />
    </svg>
  );
};

export default Flag;
