import React from 'react';
import {IconProps} from './IconProps';
import useDefaultProps from '../../hooks/useDefaultProps';

const propDefaults: Partial<IconProps> = {
  width: '9',
  height: '16',
  fill: '#808080',
  viewBox: '0 0 9 16',
  focusable: false,
};

const Close: React.FC<IconProps> = (props: IconProps) => {
  props = useDefaultProps(props, propDefaults);

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      focusable={props.focusable}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29305 0.292891C7.68354 -0.0976304 8.31665 -0.0976304 8.70713 0.292891C9.09762 0.683412 9.09762 1.31657 8.70713 1.70709L2.41435 8.00043L8.70628 14.2929C9.09677 14.6834 9.09677 15.3166 8.70628 15.7071C8.31579 16.0976 7.68269 16.0976 7.2922 15.7071L0.292866 8.70716C-0.0976219 8.31664 -0.0976219 7.68348 0.292866 7.29296C0.300503 7.28532 0.308247 7.27782 0.316094 7.27046L7.29305 0.292891ZM1.00018 7.9998L0.999905 8.00006L1.00027 8.00043L1.00852 7.99218L1.00018 7.9998Z"
      />
    </svg>
  );
};

export default Close;
