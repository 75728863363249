import React, {createContext, useContext} from 'react';
import {Nodes, TreeStore} from './TreeStore';
import {FileStore, ICaseFile, IFolder, IMeetingFolder} from './FileStore';
import {IBasicRecord, IMeetingRecord, IRegistryEntry, RecordStore} from './RecordStore';
import {DocumentStore, IDocument} from './DocumentStore';
import {NavigationStore} from './NavigationStore';
import {SearchStore} from './SearchStore';
import {UIBlockerStore} from './UIBlockerStore';
import {MeetingBoardStore} from './MeetingBoardStore';
import {RegistryEntryTypesStore} from './RegistryEntryTypesStore';
import {UIStore} from './UIStore';
import {computed} from 'mobx';
import {PaginatableStore} from './PaginatableStore';
import {RequestAccessFormStore} from './RequestAccessFormStore';
import {SeriesTypesStore} from "./SeriesTypesStore";

export class RootStore {
  treeStore: TreeStore;
  navigationStore: NavigationStore;
  searchStore: SearchStore;
  fileStore: FileStore<ICaseFile | IMeetingFolder | IFolder>;
  recordStore: RecordStore<
    IRegistryEntry | IMeetingRecord | IBasicRecord,
    FileStore<ICaseFile | IMeetingFolder | IFolder>
  >;
  documentStore: DocumentStore;
  meetingBoardStore: MeetingBoardStore;
  registryEntryTypesStore: RegistryEntryTypesStore;
  seriesTypesStore: SeriesTypesStore;
  uiStore: UIStore;
  uiBlockerStore: UIBlockerStore;
  requestAccessFormStore: RequestAccessFormStore;

  constructor() {
    this.treeStore = new TreeStore();
    this.navigationStore = new NavigationStore();
    this.searchStore = new SearchStore(this.treeStore);
    this.uiStore = new UIStore();
    this.uiBlockerStore = new UIBlockerStore();
    this.fileStore = new FileStore<ICaseFile | IMeetingFolder | IFolder>(
      this.treeStore,
      this.navigationStore,
      this.searchStore,
      this.uiBlockerStore,
    );
    this.recordStore = new RecordStore<
      IRegistryEntry | IMeetingRecord | IBasicRecord,
      FileStore<ICaseFile | IMeetingFolder | IFolder>
    >(this.treeStore, this.navigationStore, this.searchStore, this.fileStore, this.uiBlockerStore);
    this.documentStore = new DocumentStore(
      this.treeStore,
      this.navigationStore,
      this.searchStore,
      this.fileStore,
      this.recordStore,
      this.uiBlockerStore,
    );
    this.meetingBoardStore = new MeetingBoardStore(this.searchStore);
    this.registryEntryTypesStore = new RegistryEntryTypesStore(this.searchStore);
    this.seriesTypesStore = new SeriesTypesStore(this.searchStore);
    this.requestAccessFormStore = new RequestAccessFormStore();
  }

  @computed get entityList(): Array<
    ICaseFile | IFolder | IDocument | IRegistryEntry | IBasicRecord | IMeetingRecord | IMeetingFolder
  > {
    switch (this.treeStore.currentNode) {
      case Nodes.File:
        return this.fileStore.entitiesList;
      case Nodes.Record:
        return this.recordStore.entitiesList;
      case Nodes.Document:
        return this.documentStore.entitiesList;
    }
  }

  @computed get parentEntity():
    | ICaseFile
    | IFolder
    | IRegistryEntry
    | IBasicRecord
    | IMeetingRecord
    | IMeetingFolder
    | null {
    switch (this.treeStore.currentNode) {
      case Nodes.File:
        return null;
      case Nodes.Record:
        return this.fileStore.selectedEntity;
      case Nodes.Document:
        return this.recordStore.selectedEntity;
    }
  }

  @computed get paginatableNodeStore() {
    let store: PaginatableStore;

    switch (this.treeStore.currentNode) {
      case Nodes.File:
        return this.fileStore;
      case Nodes.Record:
        return this.recordStore;
      case Nodes.Document:
        return this.documentStore;
      default:
        throw new Error('Unknown node type');
    }
  }
}

const store = new RootStore();
const context = createContext<RootStore>(store);

export default (props: {children: React.ReactNode}) => {
  const Provider = context.Provider;
  return <Provider value={store}>{props.children}</Provider>;
};

export const useRootStore = () => {
  return useContext(context);
};
