import React from 'react';
import {
  filterNameToCriteriaMap as filterNameToCriteriaMapForNationalIdentifiers,
  nationalIdentifiers,
} from '../Filters/NatioanalIdentifiersFilter';
import {dateRangeFilterCriteriaMap} from '../Filters/DateRangeFilter';
import {DISPLAY_DATE_FORMAT_MONTH_NAME, isoDateStringToMoment} from '../../utils/date';
import {FileTypes} from '../../types/EntityTypes';
import {
  FileSubsetSearchCriteria,
  MeetingFolderFilterCriteria,
  MeetingFolderSearchCriteria,
} from '../../stores/FileStore';
import intl from 'react-intl-universal';
import {RecordSubsetFilterCriteria, RecordSubsetSearchCriteria} from '../../stores/RecordStore';
import {FilterCriteria, SearchableCriteria, SearchableType} from '../../stores/SearchStore';
import './css/search-and-filter-results.scss';

function getSearchValue(searchTerm: string | undefined, criterion: SearchableCriteria) {
  if (criterion && searchTerm) {
    let criterionLabel = '';
    switch (criterion) {
      case FileSubsetSearchCriteria.fileId:
        criterionLabel = intl.get('plp.search.bar.section.buttons.criteria.case.file.file.id').d('Case number');
        break;
      case FileSubsetSearchCriteria.title:
      case MeetingFolderSearchCriteria.title:
      case RecordSubsetSearchCriteria.title:
        criterionLabel = intl.get('plp.search.bar.section.buttons.criteria.title').d('Title');
        break;
      case RecordSubsetSearchCriteria.recordId:
        criterionLabel = intl
          .get('plp.search.bar.section.buttons.criteria.record.subset.record.id')
          .d('Record number');
        break;
      case RecordSubsetSearchCriteria.correspondenceParty:
        criterionLabel = intl
          .get('plp.search.bar.section.buttons.criteria.record.subset.sender.recipient.group.recipient')
          .d('Sender/recipient/group recipient');
    }

    return (
      <React.Fragment key={`search-${searchTerm}-${criterionLabel}`}>
        <b>{`${criterionLabel}: `}</b>
        <span className="search-value">{searchTerm}</span>
      </React.Fragment>
    );
  }
}

function getNationalIdentifierFilterValues(searchType: SearchableType, filters: Map<FilterCriteria, string>) {
  if (searchType === FileTypes.FileSubset ) {
    const filtersWithValues = nationalIdentifiers.filter(filterName => filters.has(filterNameToCriteriaMapForNationalIdentifiers[filterName]));
    if (filtersWithValues.length > 0) {
      const prettyFilterValue = filtersWithValues.map(filterName => filters.get(filterNameToCriteriaMapForNationalIdentifiers[filterName])).join('/');
      const prettyFilterName = filtersWithValues.join('/').toUpperCase();
      return (
        <React.Fragment key={`filter-${prettyFilterName}-${prettyFilterValue}`}>
          <b>{`${prettyFilterName}: `}</b>
          <span className="search-value">{prettyFilterValue}</span>
        </React.Fragment>
      );
    }
  }
}

function getDateRangeFilterValue(searchType: SearchableType, filters: Map<FilterCriteria, string>) {
  const dateRangeFilterMap = dateRangeFilterCriteriaMap[searchType];
  const dateFrom = filters.get(dateRangeFilterMap['from']);
  const dateTo = filters.get(dateRangeFilterMap['to']);
  if (dateFrom || dateTo) {
    const format = 'D MMM YYYY';
    let dateRangeValues = [
      <b key="date-filter-label">
        {intl
          .get('plp.filter.date.range')
          .d('date range')
          .toLocaleLowerCase()}
      </b>,
    ];
    if (dateFrom) {
      dateRangeValues.push(
        <React.Fragment key={`date-filter-from-${dateFrom}`}>
          {' '}
          {intl.get('plp.filter.date.range.from').d('from')}{' '}
          <span className="search-value">
            {isoDateStringToMoment(dateFrom)?.format(DISPLAY_DATE_FORMAT_MONTH_NAME)}
          </span>
        </React.Fragment>,
      );
    }
    if (dateTo) {
      dateRangeValues.push(
        <React.Fragment key={`date-filter-to-${dateTo}`}>
          {' '}
          {intl.get('plp.filter.date.range.to').d('to')}{' '}
          <span className="search-value">{isoDateStringToMoment(dateTo)?.format(DISPLAY_DATE_FORMAT_MONTH_NAME)}</span>
        </React.Fragment>,
      );
    }
    return dateRangeValues;
  }
}

export function getSearchAndFilterValues(
  searchType: SearchableType,
  searchTerm: string | undefined,
  criterion: SearchableCriteria,
  filters: Map<FilterCriteria, string>,
) {
  const searchAndFilterValues = [];

  const searchValue = getSearchValue(searchTerm, criterion);
  if (searchValue) {
    searchAndFilterValues.push(searchValue);
  }

  if (filters.size > 0) {
    //National identifiers
    const gnrFilters = getNationalIdentifierFilterValues(searchType, filters);
    if (gnrFilters) searchAndFilterValues.push(gnrFilters);

    //Board
    if (filters.has(MeetingFolderFilterCriteria.board)) {
      const board = filters.get(MeetingFolderFilterCriteria.board);
      searchAndFilterValues.push(
        <React.Fragment key={`board-filter-${board}`}>
          <b>
            {`${intl
              .get('plp.filter.board')
              .d('Board')
              .toLocaleLowerCase()}: `}
          </b>
          <span className="search-value">{board}</span>
        </React.Fragment>,
      );
    }

    //Record type
    if (filters.has(RecordSubsetFilterCriteria.recordType)) {
      const recordType = filters.get(RecordSubsetFilterCriteria.recordType);
      searchAndFilterValues.push(
        <React.Fragment key={`record-filter-${recordType}`}>
          <b>
            {`${intl
              .get('plp.filter.record.type')
              .d('Record type')
              .toLocaleLowerCase()}: `}
          </b>
          <span className="search-value">{recordType}</span>
        </React.Fragment>,
      );
    }

    //Date range
    const dateRangeFilterValue = getDateRangeFilterValue(searchType, filters);
    if (dateRangeFilterValue) {
      searchAndFilterValues.push(dateRangeFilterValue);
    }
  }

  if (searchAndFilterValues.length) {
    return (
      <>
        {searchAndFilterValues.map((filterValue, index) => {
          if (index < searchAndFilterValues.length - 1) {
            return (
              <React.Fragment key={index}>
                {filterValue} {intl.get('plp.and')}{' '}
              </React.Fragment>
            );
          }
          return <React.Fragment key={index}>{filterValue}</React.Fragment>;
        })}
      </>
    );
  }
}
