import React, {useEffect} from 'react';
import intl from 'react-intl-universal';

export interface Option {
  label: string;
  value: string;
}

interface SelectBaseProps {
  name: string;
  open: boolean;
  close: () => void;
  options: Array<Option>;
  selected: string;
  onChange: (opt: Option) => void;
  closeOnTab: boolean;
  innerLabel?: string;
  onOptionFocus?: (opt: Option) => void;
  onOptionBlur?: (opt: Option) => void;
}

//SelectBase is meant to be a base component for single and multi select
const SelectBase: React.FC<SelectBaseProps> = (props: SelectBaseProps) => {
  useEffect(() => {
    if (props.open && props.options.length > 0) {
      if (!props.selected) {
        let firstValue = props.options[0].value;
        focusOption(firstValue);
      } else {
        focusOption(props.selected);
      }
    }
  }, [props.open]);

  const focusOption = (otpValue: string) => {
    let selectedHtmlEl = document.getElementById(`${toId(props.name)}-${toId(otpValue)}`);
    if (selectedHtmlEl) {
      selectedHtmlEl.focus();
    }
  };

  const toId = (text: string) => {
    return text.replace(' ', '').toLowerCase();
  };

  const onOptionKeyPress = (e: React.KeyboardEvent, opt: Option, index: number) => {
    switch (e.keyCode) {
      //Esc
      case 27:
        props.close();
        break;
      //End: move focus to the last element
      case 35:
        if (!props.options.length) break;
        e.preventDefault();
        let lastValue = props.options[props.options.length - 1].value;
        focusOption(lastValue);
        break;
      //Home: move focus to the first element
      case 36:
        if (!props.options.length) break;
        e.preventDefault();
        let firstValue = props.options[0].value;
        focusOption(firstValue);
        break;
      //Arrow right or up
      case 37:
      case 38:
        if (!props.options.length) break;
        e.preventDefault();
        let prevIndex;
        if (index === 0) {
          prevIndex = props.options.length - 1;
        } else {
          prevIndex = index - 1;
        }
        let prevValue = props.options[prevIndex].value;
        focusOption(prevValue);
        break;

      //Arrow right or down
      case 39:
      case 40:
        if (!props.options.length) break;
        e.preventDefault();
        let nextIndex;
        if (index === props.options.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex = index + 1;
        }
        let nextValue = props.options[nextIndex].value;
        focusOption(nextValue);
        break;
      //Tab
      case 9:
        if (props.closeOnTab) {
          props.close();
        } else {
          e.preventDefault();
        }
        break;
    }
  };

  return (
    <div className={`dropdown-pane ${props.open ? 'is-shown' : ''}`} tabIndex={-1}>
      {props.innerLabel && <div className="inner-label">{props.innerLabel}</div>}
      {props.options.map((opt: Option, index: number) => {
        const selected = opt.value === props.selected;
        const classes = `dropdown-pane-item ${selected ? `active` : ''}`;
        const id = `${toId(props.name)}-${toId(opt.value)}`;
        return (
          <button
            id={id}
            key={id}
            tabIndex={-1}
            className={classes}
            onClick={e => {
              e.preventDefault();
              props.onChange(opt);
            }}
            onKeyDown={e => {
              onOptionKeyPress(e, opt, index);
            }}
            aria-label={`${opt.label} - ${intl
              .get('plp.select.option.index', {index: index + 1, totalCount: props.options.length})
              .d(`option ${index + 1} of ${props.options.length}`)}  ${
              selected ? '- ' + intl.get('plp.select.option.selected').d('selected') : ''
            }`}
            onFocus={() => {
              if (props.onOptionFocus) {
                props.onOptionFocus(opt);
              }
            }}
            onBlur={() => {
              if (props.onOptionBlur) {
                props.onOptionBlur(opt);
              }
            }}
          >
            {opt.label}
          </button>
        );
      })}
    </div>
  );
};

export default SelectBase;
